import React, { Component } from "react";
import "./index.scss";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

export default class CustomSlider extends Component {
  constructor(props, children) {
    super(props);
    this.state = {
      distinctShowDateByFilmIdAndCinemaId: [],
      MovieDateBoxWidth: 0,
      arrowsNeeded: true,
      reachedScrollEnd: false,
      reachedScrollStart: true,
      arrowWidth: 47,
    };
  }

  componentDidUpdate() {
    this.setMovieDateBoxWidth();
  }

  componentDidMount() {
    this.setMovieDateBoxWidth();
  }

  setMovieDateBoxWidth = () => {
    var ele = document.getElementsByClassName("category-btn");
    var highestValue = 0;
    for (var i = 0, len = ele.length; i < len; i++) {
      var width = parseFloat(ele[i].offsetWidth);
      if (width > highestValue) {
        highestValue = width;
      }
    }
    if (this.state.MovieDateBoxWidth != highestValue) {
      this.setState(
        {
          MovieDateBoxWidth: highestValue,
        },
        () => {
          this.checkIfArrowsNeeded();
        }
      );
    }
  };

  checkIfArrowsNeeded = () => {
    var totalMovieDateBoxWidth = this.props.children.length * this.state.MovieDateBoxWidth;
    var showdateSliderSectionWidth = document.getElementById("showDateSliderParentDiv").getBoundingClientRect().width;
    if (showdateSliderSectionWidth > totalMovieDateBoxWidth) {
      this.setState({
        arrowsNeeded: false,
      });
    } else {
      console.log("inside else");
      if (window.innerWidth < 768) {
        this.setState({
          arrowsNeeded: false,
        });
      } else {
        this.setState({
          arrowsNeeded: true,
        });
      }
    }
  };

  arrowClick = (direction) => {
    var MovieDateBoxWidth = this.state.MovieDateBoxWidth;
    var divContent = document.getElementById("showdate-slider-section");
    var showdateSliderSectionDimensions = divContent.getBoundingClientRect();
    var screenPosition = document.getElementById("showdate-slider-section").scrollLeft;
    var scrollValue = showdateSliderSectionDimensions.width;
    scrollValue = Math.round(scrollValue / MovieDateBoxWidth);
    scrollValue = scrollValue * MovieDateBoxWidth;
    if (direction == "left") {
      divContent.scrollLeft = screenPosition - scrollValue < MovieDateBoxWidth ? 0 : screenPosition - scrollValue;
    } else if (direction == "right") {
      divContent.scrollLeft = screenPosition + scrollValue;
    }
  };

  detectScrolling = () => {
    var screenPositionLeft = document.getElementById("showdate-slider-section").scrollLeft;
    var screenPositionInnerWidth = document.getElementById("showdate-slider-section").offsetWidth;
    var screenPositionWidth = document.getElementById("showdate-slider-section").scrollWidth;
    console.log(screenPositionInnerWidth, "screenPositionInnerWidth");
    if (screenPositionLeft + screenPositionInnerWidth + 1 >= screenPositionWidth) {
      if (this.state.reachedScrollEnd == false) {
        this.setState({
          reachedScrollEnd: true,
        });
      }
    } else {
      if (this.state.reachedScrollEnd == true) {
        console.log("setting state false");
        this.setState({
          reachedScrollEnd: false,
        });
      }
    }

    if (screenPositionLeft <= 0 && this.state.reachedScrollStart == false) {
      this.setState({
        reachedScrollStart: true,
      });
    } else {
      if (this.state.reachedScrollStart) {
        this.setState({
          reachedScrollStart: false,
        });
      }
    }
  };

  render() {
    return (
      <div>
        <div className="showDateSliderParentDiv" id="showDateSliderParentDiv">
          {this.state.arrowsNeeded && (
            <button onClick={() => this.arrowClick("left")} className={`arrow-left ${this.state.reachedScrollStart ? "disabledArrow" : ""}`}>
              <KeyboardArrowLeft />
            </button>
          )}

          <div className="showdate-slider-section" id={"showdate-slider-section"} onScroll={this.detectScrolling}>
            {this.props.children}
          </div>

          {this.state.arrowsNeeded && (
            <button onClick={() => this.arrowClick("right")} className={`arrow-right ${this.state.reachedScrollEnd ? "disabledArrow" : ""}`}>
              <KeyboardArrowRight />
            </button>
          )}
        </div>
      </div>
    );
  }
}
