import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "./index.scss";
import { RadialProgress } from 'react-radial-progress-indicator';

var progressBarCounter = null;
export default class IdleStayModal extends Component {
  componentDidUpdate = () => {
    // console.log(progressBarCounter, 'progressBarCounter--didmount')
    if(progressBarCounter <= 1 && progressBarCounter != null){
      window.location.href = `/?cinemaid=${localStorage.getItem("cinemaid")}`;
    }
  }
  
  render() {
    return (
      <>
      <Modal 
        show={this.props.idleStayModalSH}
        dialogClassName="idle-stay-popup" 
        centered>
        <Modal.Body>
          <div className="content">
            <p>Redirecting to home page in</p>
            <div className="progress-sec">
              <RadialProgress
                // backgroundColour="#dff0d8"
                backgroundTransparent
                duration={10000}
                fontRatio={5}
                height={100}
                ringBgColour="#f1f1f1"
                ringFgColour="#ffc216"
                ringIntermediateColour="#f1f1f1"
                ringThickness={0.25}
                segmented
                // showIntermediateProgress
                startStep={10}
                step={0}
                steps={10}
                // text={function text(steps,percentage){return Math.floor(steps*percentage)}}
                text={function text(steps,percentage){
                  progressBarCounter = Math.floor(steps*percentage);
                  return Math.floor(steps*percentage)
                }}
                
                width={100}
              />
            </div>
            <div className="btn-wrap">
              <a 
                onClick={(e) => this.props.stayBtnClick(e)}
                href="javascript:;" className="btn btnPrimary" title="Stay on this page">Stay on this page</a>
            </div>            
          </div>
        </Modal.Body>
      </Modal>
      </>
    )
  }
}
