import React, { Component } from "react";
import "./index.scss";
import FNBComponent from "../../components/FnB";
import BookingFlow from "../../components/BookingFlow";
import Header from "../../components/Header";
import * as API from "../../configuration/apiconfig";
import queryString from "query-string";
import { showAlert } from "../../components/showAlert";
import infoIcon from "../../assets/images/info-icon.svg";
class FNB extends Component {
  constructor(props) {
    const parsedQueryString = queryString.parse(window.location.search);
    super(props);
    this.state = {
      showTimer: false,
      cinemaId: parsedQueryString.cinemaid,
      orderId: parsedQueryString.orderid,
      bookingId: parsedQueryString.bookingid,
      fnbData: [],
      sessionId: parsedQueryString.sessionid,
    };
  }

  componentDidMount() {
    window.winvar = this;
    this.getFnbData(this.state.cinemaId, this.state.sessionId);
    if (localStorage.getItem("orderType") === "ticketing") {
      //ticketing flow
      this.setState({
        showTimer: true,
      });
    } else {
      //fnb flow
      let orderDetails = JSON.parse(localStorage.getItem("orderId"));
      if (orderDetails) {
        if (orderDetails.expiry < Date.now()) {
          orderDetails = null;
        } else {
          this.setState({
            showTimer: true,
          });
        }
      }
    }
  }

  AbortTransactionResponse = (response) => {
    // This is dot net function
    console.log(response, 'Swipe card abort response--F&B page');
  }

  GiftCardSwipeResponse = (response) => {
    // This is dot net function
    console.log(response, 'GiftCardSwipeResponse--F&B page');
  }

  getFnbData = (cinemaId, sessionId) => {
    this.setState({
      dataLoading: true,
    });

    var setFnbURL = '';
    let orderType = localStorage.getItem('orderType');
    if(orderType == "ticketing"){
      if(cinemaId && sessionId){
        // Ticketing flow onload
        setFnbURL = `/cms/v2/fnbtabs?cinemaid=${cinemaId}&sessionid=${sessionId}`
      }else{
        // Ticketing flow > fnbtabs return empty response(onload)
        setFnbURL = `/cms/v2/fnbtabs?cinemaid=${cinemaId}`
      }
    }else{
      setFnbURL = `/cms/v2/fnbtabs?cinemaid=${cinemaId}`
    }

    API.callEndpoint("GET", "Bearer", `${setFnbURL}`)
      .then((res) => {
        this.setState({
          fnbData: res.data,
        }, () => {
          if(this.state.fnbData.length == 0 && orderType == "ticketing"){
            showAlert({
              html: `<p>Food and Drink orders made now are for immediate collection. If you would like your Food and Drink items closer to the time of your show, please skip this step and order your Food and Drink later.</p>`,
              iconHtml: `<img src=${infoIcon}>`,
              showCancelButton: false,
              cancelButtonText: "CANCEL",
              showConfirmButton: true,
              confirmButtonText: "OK",
              allowOutsideClick: false,
              allowEscapeKey: false,
              iconClasses: "borderless",
              popupClasses: "common-error-alert",
              onConfirm: () => {
                this.getFnbData(this.state.cinemaId, null);
              },
              onCancel: () => {},
            });
          }
        });
      })
      .catch((err) => {
        showAlert({
          html: `<p>Sorry<span>${err.error}</span></p>`,
          iconHtml: `<img src=${infoIcon}>`,
          showCancelButton: false,
          cancelButtonText: "CANCEL",
          showConfirmButton: true,
          confirmButtonText: "Try again",
          allowOutsideClick: false,
          allowEscapeKey: false,
          iconClasses: "borderless",
          popupClasses: "common-error-alert",
          onConfirm: () => {
            this.getFnbData(this.state.cinemaId, null);
          },
          onCancel: () => {},
        });
      })
      .finally(() => {
        this.setState({ dataLoading: false });
      });
  };
  render() {
    return (
      <div className="fnb-page">
        <Header logo={true} homeIcon={false} timer={this.state.showTimer} />
        <section className="booking-flow-sec">
          <BookingFlow currentStep="2" />
        </section>
        <FNBComponent showHeader={true} showFooter={true} showCategoriesSelection={true} updateOrder={false} horizontal={false} fnbData={this.state.fnbData}></FNBComponent>
      </div>
    );
  }
}

export default FNB;
