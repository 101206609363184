import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "./index.scss";
import keyboardIcon from "../../assets/images/bar-code-icon.svg"
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import voucher from "../../assets/images/voucher.svg"
import { keyboardLayout } from "../../utils/keyBoardLayout"
import spinnerLoader from "../../assets/images/spinner-loader.gif"

export default class GiftCardModal extends Component {
  render() {
    return (
      <>
      <Modal 
        show={this.props.parentState.giftCardModalSH} 
        dialogClassName="gift-card-popup" 
        centered>
        <Modal.Body>
          <div className={`close-btn ${this.props.parentState.giftcardClickhereDisabled ? '':'disabled'}`} onClick={() => this.props.scanModalCloseBtnClick(this.props.parentState.giftcardScanningSecSH ? 'manualEntry':'giftCardSwipe')}>
            <a href="javascript:;" title="Close">&nbsp;</a>
          </div>
          <div className="content">
            {
              this.props.parentState.giftcardScanningSecSH ?
              <>
                <h2>Enter your Gift Card Number</h2>
                <div className="form-blk">
                  <div className="fm-inner">
                    <div className="input-wrap">
                      <input 
                        ref={(input) => { this.textInput = input; }}
                        id="voucherInput"
                        type="text" 
                        placeholder="Gift Card Number" 
                        autoComplete="off" 
                        value={this.props.parentState.giftCardNumber}
                        readOnly
                      />
                    </div>
                    <div className="kb-wrap" onClick={this.props.giftCardKeyboardIcon}>
                      <img src={keyboardIcon} alt="Keyboard" />
                    </div>
                  </div>
                </div>
                <div className="scan-btn">
                  <a onClick={this.props.redeemBtnClick} href="javascript:;" className={`btn btnPrimary ${this.props.disableRedeemBtn() ? 'disabled':''}`} title="REDEEM">REDEEM</a>
                </div>
                {
                  this.props.parentState.giftcardErrorMsg != '' &&
                  <div className="error-blk">
                    <p>{this.props.parentState.giftcardErrorMsg}</p>
                  </div>
                }
                </>
                :
                <>
                  <img src={voucher} alt="Scan" />
                  <h1>Insert your Gift Card</h1>
                  <div className="loader-blk">
                    {
                      !this.props.parentState.giftcardClickhereDisabled &&
                      <div>
                        <img src={spinnerLoader} alt="Loader" />
                        <p>{this.props.parentState.giftcardScanningLoaderMsg}</p>
                      </div>
                    }
                    {/* <p className={`clickhere ${this.props.parentState.giftcardClickhereDisabled ? '':'disabled'}`}><a onClick={this.props.enterManuallyClick} href="javascript:;">Click here</a> to enter your Gift card number manually.</p> */}
                  </div>
                </>
            }
          </div>
        </Modal.Body>
      </Modal>
      {
        this.props.parentState.giftCardKeyboardSH ?
        (<Keyboard
          keyboardRef={r => (this.keyboard = r)}
          layoutName={this.props.parentState.keyBoardLayoutName}
          onChange={this.props.giftCardKeyBoardOnChange}
          onKeyPress={this.props.giftCardKeyBoardOnKeyPress}
          layout={keyboardLayout}
          display={{
            "{bksp}": "Backspace",
          }}
          disableRowButtonContainers={true}
          baseClass={"giftcard-keyboard"}
        />):(null)
      }
      </>
    )
  }
}
