import axios from "axios";
import { Buffer } from "buffer";
import { showAlert } from "../components/showAlert";
import AlertIcon from "../../src/assets/images/alert-icon.svg";
import { sessionExpiredRedirectInLoginUser } from '../utils/AlertHandling'
var accessTokenExpiry = 2000;
var methodType, authorisationType, URL, data;

export async function callEndpoint(methodType, authorisationType, URL, data) {
  methodType = methodType;
  authorisationType = authorisationType;
  URL = URL;
  data = data;
  if (authorisationType === "Bearer") {
    var accessToken = getCookie("accessToken");
    if (accessToken == null) {
      return new Promise((resolve, reject) => {
        getAccessToken().then((response) => {
          callEndpoint(methodType, authorisationType, URL, data)
            .then((response) => {
              resolve(response);
            })
            .catch(reject);
        });
      });
    } else {
      return new Promise((resolve, reject) => {
        // var dataversion = getCookie("ticketBookingLang");
        var dataversion = "en";
        if (dataversion == "en") {
          dataversion = "en-US";
        } else if (dataversion == "ar") {
          dataversion = "ar-SA";
        } else {
          dataversion = "en-US";
        }
        axios({
          url: process.env.REACT_APP_BASEURL + URL,
          method: methodType,
          headers: {
            Authorization: "Bearer " + getCookie("accessToken"),
            dataversion: dataversion,
            // // // // // appplatform: "WEBSITE",
            appplatform: "KIOSK",
            appversion: "1.0.0",
          },
          data: data,
        })
          .then((response) => {
            resolve(response);
          })
          .catch(async (ex) => {
            if (ex.response.data.message != undefined) {
              if (ex.response.data.message.toLowerCase() === "unauthorized") {
                if (getCookie("firstname") != null && getCookie("lastname") != null) {
                  sessionExpirySwal();
                } else {
                  await getRefreshToken().then(async (response) => {
                    await callEndpoint(methodType, authorisationType, URL, data)
                      .then((response) => {
                        resolve(response);
                      })
                      .catch(reject);
                  });
                }
              } else {
                reject({ error: ex.response.data.message });
              }
            } else {
              reject({ error: ex.response.data.message });
            }
          });
      });
    }
  } else if (authorisationType === "Basic") {
    var headerObject = new Object();
    headerObject.Authorization = "Basic " + new Buffer.from(process.env.REACT_APP_USERNAME + ":" + process.env.REACT_APP_PASSWORD).toString("base64");
    headerObject.appplatform = "KIOSK";
    headerObject.appversion = "1.0.0";
    return new Promise((resolve, reject) => {
      axios({
        url: process.env.REACT_APP_BASEURL + "" + URL,
        method: methodType,
        headers: headerObject,
        data: data,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((ex) => {
          reject({ error: ex.response.data.message });
        });
    });
  }
}

export async function getAccessToken(keepMeSignedIn = true, getUserInfo = true) {
  var data = new Object();
  data.accessTokenExpiry = accessTokenExpiry;
  data.getUserInfo = getUserInfo;
  data.keepMeSignedIn = true;
  return new Promise((resolve, reject) => {
    axios({
      // url: process.env.REACT_APP_BASEURL + "user/v1/token",
      url: process.env.REACT_APP_BASEURL + "/user/v2/token",
      method: "POST",
      headers: {
        Platform: "web/prod",
        Version: "1.0",
      },
      data: data,
      auth: {
        username: process.env.REACT_APP_USERNAME,
        password: process.env.REACT_APP_PASSWORD,
        // username: username,
        // password: password,
      },
    })
      .then((response) => {
        document.cookie = "accessToken=" + response.data.accessToken;
        document.cookie = "refreshToken=" + response.data.refreshToken;
        // document.cookie = "password=" + password;
        resolve(response);
      })
      .catch((ex) => {
        reject({ error: ex });
      });
  });
}

async function getRefreshToken() {
  var headerDetails = "Basic " + Buffer.from(process.env.REACT_APP_USERNAME + ":" + process.env.REACT_APP_PASSWORD).toString("base64");
  var data = new Object();
  data.refreshToken = getCookie("refreshToken");
  data.keepMeSignedIn = true;
  return new Promise(async (resolve, reject) => {
    axios({
      // url: process.env.REACT_APP_BASEURL + "user/v1/token/refresh",
      url: process.env.REACT_APP_BASEURL + "/user/v2/token/refresh",
      method: "POST",
      auth: {
        username: process.env.REACT_APP_USERNAME,
        password: process.env.REACT_APP_PASSWORD,
      },
      data: data,
    })
      .then((response) => {
        document.cookie = "accessToken=" + response.data.accessToken;
        document.cookie = "refreshToken=" + response.data.refreshToken;
        resolve(response);
      })
      .catch(async (ex) => {
        if (axios.isCancel(ex)) {
          reject({ Cancel: "" });
        } else if (ex.response.data.code != 200 || ex.response.data.message.toLowerCase() == "token invalid") {
          await getAccessToken().then((response) => {
            callEndpoint(methodType, authorisationType, URL, data)
              .then((response) => {
                resolve(response);
              })
              .catch(reject);
          });
        }
      });
  });
}

export function getValueFromCookie(cookieName) {
  return getCookie(cookieName);
}

export function getCookie(name) {
  var cookieArr = document.cookie.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}

export function deletCookie(name) {
  document.cookie = `${name}= ; expires= Thu Jan 1970 00:00:00 GMT`;
}


export async function sessionExpirySwal() {
  var getLoader = document.querySelector(".loader");
  if (getLoader && getLoader.classList.contains("showLoader")) {
    getLoader.classList.remove("showLoader");
    getLoader.classList.add("d-none");
  }
  showAlert({
    html: `<p>Session Expired<span>Click Ok to login</span></p>`,
    iconHtml: `<img src=${AlertIcon}>`,
    showCancelButton: false,
    cancelButtonText: "CANCEL",
    showConfirmButton: true,
    confirmButtonText: "OK",
    allowOutsideClick: false,
    allowEscapeKey: false,
    iconClasses: "borderless",
    popupClasses: "session-expired-popup",
    onConfirm: () => {
      const pathname = window.location.pathname;
      const search = window.location.search;
      var getParmUrl = pathname + search;
      // console.log(getParmUrl);
      localStorage.setItem("sessionExpiredRedirectUrl", getParmUrl);
      sessionExpiredRedirectInLoginUser('session expired', '/login');
    },
    onCancel: () => { },
  });
}
