export const monthNameArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
