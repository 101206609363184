import React, { Component } from "react";
import "./index.scss";
import * as API from "../../configuration/apiconfig";
import queryString from "query-string";
import { DateTimeFormatISO, calculateHoursAndMinutes, formatFilmShowDate, formatShowTime } from "../../utils/DateTimeFormat";
import "sweetalert2/src/sweetalert2.scss";
import Loader from "../loader";
import Header from "../../components/Header";
import GiftCardModal from "../../components/GiftCardModal";
import PurchaseFooter from "../../components/Footer";
import { showAlert } from "../../components/showAlert";
import infoIcon from "../../assets/images/info-icon.svg";
import BookingFlow from "../../components/BookingFlow";
import MovieInfo from "../../components/MovieInfo/MovieInfo";
import BookingSummary from "../../components/BookingSummary";
import SeatIcon from "../../assets/images/seat-icons/Seat.svg";
import IconCalendar from "../../assets/images/icons/iconCalendar.svg";
import IconClock1 from "../../assets/images/icons/iconClock1.svg";
import IconScreen from "../../assets/images/icons/iconScreen.svg";
import deleteIcon from "../../assets/images/delete-icon.svg";
import { orderExpiredRedirect } from "../../utils/AlertHandling";

var giftCardSuccess = {
  "code": "200",
  "message": "Success",
  "data": {
    "Response": {
      "AdditionalResponse": "eyJhZGRpdGlvbmFsRGF0YSI6eyJjYXJkQmluIjoiNjM3NzM4IiwiY2FyZFN1bW1hcnkiOiIzMjU5IiwiY2FyZFR5cGUiOiJsYW5kbWFyay1jaW5lbWFzIiwiZXhwaXJ5TW9udGgiOiIwMSIsImV4cGlyeVllYXIiOiIyMDUwIiwiZ2lmdGNhcmRJbmRpY2F0b3IiOiJmYWxzZSIsImlzbzg2MDFUeERhdGUiOiIyMDI0LTA4LTEzVDE2OjUzOjAyLjc2OVoiLCJtZXJjaGFudFJlZmVyZW5jZSI6IjA4MTMyMDI0MTAiLCJwYXltZW50TWV0aG9kIjoibGFuZG1hcmstY2luZW1hcyIsInBvc0Ftb3VudENhc2hiYWNrVmFsdWUiOiIwIiwicG9zQW1vdW50R3JhdHVpdHlWYWx1ZSI6IjAiLCJwb3NBdXRoQW1vdW50Q3VycmVuY3kiOiJDQUQiLCJwb3NBdXRoQW1vdW50VmFsdWUiOiIxMDAwIiwicG9zRW50cnlNb2RlIjoiTVNSIiwicG9zT3JpZ2luYWxBbW91bnRWYWx1ZSI6IjEwMDAiLCJwb3NhZGRpdGlvbmFsYW1vdW50cy5vcmlnaW5hbEFtb3VudEN1cnJlbmN5IjoiQ0FEIiwicG9zYWRkaXRpb25hbGFtb3VudHMub3JpZ2luYWxBbW91bnRWYWx1ZSI6IjEwMDAiLCJ0aWQiOiIwNjA5MjY3NyIsInRyYW5zYWN0aW9uVHlwZSI6IkdPT0RTX1NFUlZJQ0VTIiwidHhkYXRlIjoiMTMtMDgtMjAyNCIsInR4dGltZSI6IjEwOjUzOjAyIn0sIm1lc3NhZ2UiOiJDQVJEX0FDUV9DT01QTEVURUQiLCJzdG9yZSI6IlRlc3RQT1MifQ==",
      "Result": 0
    },
    "SaleData": {
      "SaleTransactionID": {
        "TransactionID": "0813202410",
        "TimeStamp": "2024-08-13T10:53:00Z"
      },
      "SaleToAcquirerData": "eyJhcHBsaWNhdGlvbkluZm8iOnsiYWR5ZW5MaWJyYXJ5Ijp7Im5hbWUiOiJhZHllbi1kb3RuZXQtYXBpLWxpYnJhcnkiLCJ2ZXJzaW9uIjoiMTguMC4wIn19fQ==",
      "TokenRequestedType": 0
    },
    "POIData": {
      "POITransactionID": {
        "TransactionID": "PYyz001723567982000",
        "TimeStamp": "2024-08-13T16:53:02.769Z"
      },
      "POIReconciliationID": "1000"
    },
    "PaymentInstrumentData": {
      "CardData": {
        "SensitiveCardData": {
          "TrackData": [
            {
              "TrackNumb": 2,
              "TrackFormat": 0,
              "TrackValue": "3B3633373733383935343531323538333235393D353030313F"
            }
          ],
          // "PAN": "637738954512583259",
          // "PAN": "612573230495698609",
          "PAN": "612573230495698123",  /// Wrong giftcard number - For testing only
          "ExpiryDate": "0150"
        },
        "PaymentBrand": "landmark-cinemas",
        "MaskedPAN": "637738 **** 3259"
      },
      "PaymentInstrumentType": 0
    }
  },
  "ExtendedErrorLog": "",
  
}

var giftCardFailure = {
  "code": "500",
  "message": "Failure",
  "data": {
    "Response": {
      "AdditionalResponse": "message=Repeated%20DB%20Message%3a%20ServiceID%20-%201311031781",
      "Result": 1,
      "ErrorCondition": 7
    },
    "SaleData": {
      "SaleTransactionID": {
        "TransactionID": "813202413",
        "TimeStamp": "2024-08-13T11:03:17Z"
      },
      "SaleToAcquirerData": "eyJhcHBsaWNhdGlvbkluZm8iOnsiYWR5ZW5MaWJyYXJ5Ijp7Im5hbWUiOiJhZHllbi1kb3RuZXQtYXBpLWxpYnJhcnkiLCJ2ZXJzaW9uIjoiMTguMC4wIn19fQ==",
      "TokenRequestedType": 0
    },
    "POIData": {
      "POITransactionID": {
        "TransactionID": "PYyz001723568682002",
        "TimeStamp": "2024-08-13T17:04:42.134Z"
      }
    }
  },
  "ExtendedErrorLog": "",
  
}

var error405 = {"code":"405","message":"Payment terminal device is out of communication","data":{},"ExtendedErrorLog":""}
class index extends Component {
  constructor(props) {
    const parsedQueryString = queryString.parse(window.location.search);
    super(props);
    this.state = {
      orderid: parsedQueryString.id,
      orderData: [],
      loader: false,
      giftCardModalSH: false,
      giftCardKeyboardSH: false,
      giftCardNumber: "",
      keyBoardLayoutName: "ip",
      movieInfoDetails: {},
      ticketsDataForTicketSummary: [],
      fnbDataForTicketSummary: [],
      giftcardScanningLoaderMsg: "Gift card swipe in progress",
      giftcardScanningSecSH: false,
      giftcardData: {},
      giftcardErrorMsg: '',
      giftcardSecDisabled: false,
      giftcardPaymentTypes: [],
      getEncryptedVal: '',
      orderType: localStorage.getItem("orderType"),
      footerPayBtnSH: true,
      creditDebitSecDisabled: false,
      getRemainingOrderValue: '',
      giftcardStaticData: parsedQueryString.mockstatus == 'success' 
        ? giftCardSuccess:
        parsedQueryString.mockstatus == 'failure' 
        ? giftCardFailure: 
        parsedQueryString.mockstatus == '405' 
        ? error405:
        null,
      giftcardClickhereDisabled: false
    };
  }

  async componentDidMount() {
    console.log(this.state.orderid);
    window.winvar = this;
    await this.getOrderDetails();
  }

  hideGCModalBookingTimerElapse = () => {
    this.setState({
      giftCardModalSH: false,
      giftCardKeyboardSH: false,
    })
  }

  getOrderDetails = async () => {
    this.setState({
      loader: true,
    });
    API.callEndpoint("GET", "Bearer", `/order/v2/orders?id=${this.state.orderid}`)
      .then((response) => {
        this.setState(
          {
            orderData: response.data[0],
            getEncryptedVal: response.data[0].additionalinfo.encryptedVal,
            loader: false,
          },
          () => {
            if (this.state.orderType === "ticketing") {
              this.setMovieInfoDetails();
              this.setTicketsDataForTicketSummary();
            }
            this.setFnbDataForTicketSummary();
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            loader: false,
          },
          () => {
            this.showErrorPopup(error.error);
          }
        );
      });
  };

  scanModalCloseBtnClick = (getFlow) => {
    this.setState({
      giftCardModalSH: false,
      giftCardNumber: "",
      giftCardKeyboardSH: false,
      keyBoardLayoutName: "ip",
      giftcardScanningSecSH: false
    }, () => {
      if(getFlow == 'giftCardSwipe'){
        this.props.timerDecrement();
      }
    });
  };

  AbortTransactionResponse = (response) => {
    // This is dot net function
    console.log(response, 'Swipe card abort response');
  }

  giftCardKeyboardIcon = () => {
    this.setState({
      giftcardScanningSecSH: false,
      giftCardKeyboardSH: false,
      giftCardNumber: '',
      giftcardClickhereDisabled: false
    }, () => {
      localStorage.setItem('giftCardSwipeInit', true);
      if(this.state.giftcardStaticData == null){
        var tempOrderData = this.state.orderData;
        window.callbackObj.callGiftCardSwipe(this.state.orderid, tempOrderData.remainingOrderValue, tempOrderData.paymentid);
        this.props.timerIncrement();
      }else{
        this.GiftCardSwipeResponse(this.state.giftcardStaticData);
        this.props.timerIncrement();
      }
    });
  };

  GiftCardSwipeResponse = (response) => {
    // This is dot net function
    console.log(response, 'GiftCardSwipeResponse response');
    console.log(response.code, response.message, '-----GiftCardSwipeResponse function response');
    var giftcardResponse = response;
    if(giftcardResponse){
      localStorage.removeItem('giftCardSwipeInit');
      if(giftcardResponse.code === '200' && Object.keys(giftcardResponse.data).length > 0){
        console.log(giftcardResponse.data.PaymentInstrumentData?.CardData?.SensitiveCardData?.PAN);
        if(giftcardResponse.data.PaymentInstrumentData?.CardData?.SensitiveCardData?.PAN != ''){
          var getGiftcardNumber = giftcardResponse.data.PaymentInstrumentData?.CardData?.SensitiveCardData?.PAN;  
          this.setState({
            giftCardNumber:getGiftcardNumber,
          }, () => {
            if(this.state.giftcardStaticData == null){
              window.callbackObj.callAbortTransaction(this.state.orderid, 'success', 'Done', 'Card swipe successful');
            }
            this.getGiftcardAPI();
          })
        }
      }else if(giftcardResponse.code === '500' && Object.keys(giftcardResponse.data).length > 0 || giftcardResponse.code === '405'){
        console.log(giftcardResponse.message);
        if(this.state.giftcardStaticData == null && giftcardResponse.code === '500'){
          window.callbackObj.callAbortTransaction(this.state.orderid, 'failure', 'Sorry', 'Please try again');
        }
        this.setState({
          giftcardClickhereDisabled: true,
          giftCardModalSH: false
        }, () => {
          showAlert({
            // html: `<p>${giftcardResponse.message}</p>`,
            html: `<p>${giftcardResponse.code === '500' && giftcardResponse.message.toLowerCase().includes('cancel') ? 'Please retry swiping your card':giftcardResponse.message}</p>`,
            iconHtml: `<img src=${infoIcon}>`,
            showCancelButton: false,
            cancelButtonText: "CANCEL",
            showConfirmButton: true,
            confirmButtonText: "OK",
            allowOutsideClick: false,
            allowEscapeKey: false,
            iconClasses: "borderless",
            popupClasses: "order-error-popup",
            onConfirm: () => {},
            onCancel: () => { },
          });
        })
      }
    }
  }

  giftCardKeyBoardOnChange = (input) => {
    this.setState({
      giftCardNumber: input,
      giftcardErrorMsg: ''
    });
    // console.log("Input changed", input);
  };

  giftCardKeyBoardOnKeyPress = (button) => {
    // console.log("Button pressed", button);
    if (button === "{shift}" || button === "{lock}") this.giftCardKeyBoardHandleShift();
  };

  giftCardKeyBoardHandleShift = () => {
    const layoutName = this.state.keyBoardLayoutName;
    this.setState({
      keyBoardLayoutName: layoutName === "default" ? "shift" : "default",
    });
  };

  disableRedeemBtn = () => {
    var disabled = true;
    if (this.state.giftCardNumber != "") {
      disabled = false;
    } else {
      disabled = true;
    }
    return disabled;
  };

  giftCardOnClick = () => {
    this.setState({
      giftCardModalSH: true,
      giftcardScanningSecSH: true,
      giftCardKeyboardSH: true,
      giftCardNumber: '',
      giftcardClickhereDisabled: true,
      giftcardErrorMsg: ''
    }, () => {
      this.props.timerDecrement();
    });
  };

  enterManuallyClick = () => {
    this.setState({
      giftcardScanningSecSH: true,
      giftCardKeyboardSH: true,
      giftcardErrorMsg: '',
      giftcardClickhereDisabled: true
    }, () => {
      this.props.timerDecrement();
    });
  }

  creditCardOnClick = () => {
    window.location.href = "/swipecard";
  };

  setMovieInfoDetails = () => {
    if (Object.keys(this.state.orderData).length > 0) {
      var orderDetails = this.state.orderData;
      var images = orderDetails.film.films_images;
      var experiences = orderDetails.session.sessions_experiences.map((item) => item.name).join(", ");
      var genres = [];
      orderDetails.film.films_genres.map((eachGenre) => {
        genres.push(eachGenre.description);
      });

      var rating = orderDetails.film.rating;
      var runtime = orderDetails.film.runtime;
      var screenName = orderDetails.session.cinemas_screens[0].name;
      var title = orderDetails.film.films_attributes[0].title;
      var subtitle = orderDetails.film.films_attributes[0].subtitle;
      var timing = formatFilmShowDate(orderDetails.session.showdate);
      var showTime = formatShowTime(orderDetails.session.showtime);
      this.setState({
        movieInfoDetails: {
          images,
          experiences,
          title,
          genres,
          rating,
          runtime,
          screenName,
          subtitle,
          timing,
          showTime,
        },
      });
    }
  };

  setTicketsDataForTicketSummary = () => {
    if (Object.keys(this.state.orderData).length > 0) {
      let tempOrderData = [];
      this.state.orderData?.order_grouping?.map((eachTicket) => {
        if (eachTicket.type.toLowerCase() == "ticket") {
          let eachTaxValue = eachTicket.taxValue / eachTicket.quantity;
          let tempTicketObject = {
            description: eachTicket.description,
            unitprice: eachTicket.unitprice - eachTaxValue,
            qty: eachTicket.quantity,
          };
          tempOrderData.push(tempTicketObject);
        }
      });
      this.setState({
        ticketsDataForTicketSummary: tempOrderData,
      });
    }
  };

  setFnbDataForTicketSummary = () => {
    if (Object.keys(this.state.orderData).length > 0) {
      var fnbDataForTicketSummary = [];
      this.state.orderData?.order_grouping?.map((eachItem) => {
        if (eachItem.type.toLowerCase() == "fnb") {
          var tempItemObject = {
            description: eachItem.description,
            qty: eachItem.quantity,
            unitprice: eachItem.unitprice - eachItem.taxValue / eachItem.quantity,
            modifiers: [],
            smartModifiers: [],
          };

          eachItem.additionalInfo.modifiers.map((eachModifier) => {
            var tempModifierObject = {
              description: eachModifier.description,
              qty: eachModifier.quantity,
              unitprice: eachModifier.pricebeforetax / eachModifier.quantity,
            };
            tempItemObject.modifiers.push(tempModifierObject);
            tempItemObject.unitprice = tempItemObject.unitprice - eachModifier.pricebeforetax;
          });
          fnbDataForTicketSummary.push(tempItemObject);
        }
      });
      this.setState({
        fnbDataForTicketSummary,
      });
    }
  };

  backBtnClick = () => {
    var tempGiftcardPaymentTypes = this.state.giftcardPaymentTypes;
    var getGiftcardObj = tempGiftcardPaymentTypes.find((x) => x.type.toLowerCase() === 'giftcard');
    if(tempGiftcardPaymentTypes.length > 0 && getGiftcardObj && Object.keys(getGiftcardObj).length > 0){
      var getIndex = tempGiftcardPaymentTypes.findIndex((x) => x.type.toLowerCase() === 'giftcard');
      this.giftcardDelete(getIndex, true);
    }else{
      var getorderIdFromLocalstorage = JSON.parse(localStorage.getItem("orderId"));
      window.location.href = `/order-review?orderid=${getorderIdFromLocalstorage.id}`;
    }
  };

  getGiftcardAPI = () => {
    this.setState({
      // loader: true,
      giftcardData: {},
      giftcardScanningLoaderMsg: 'Validating your gift card'
    }, () => {
      var data = {
        "giftcardno": this.state.giftCardNumber
      }
      API.callEndpoint("POST", "Bearer", `/order/v2/orders/${this.state.orderid}/giftcard`, data)
        .then((response) => {
          this.setState({
            giftcardData: response.data,
            orderData: response.data,
            loader: false
          }, () => {
            this.setTicketsDataForTicketSummary();
            this.setFnbDataForTicketSummary();
            if (response.data.orders_paymenttypes.length > 0 && response.data.orders_paymenttypes[0].status.toLowerCase() == 'success') {
              this.setState({
                giftcardPaymentTypes: response.data.orders_paymenttypes,
                getRemainingOrderValue: response.data.remainingOrderValue,
                giftCardModalSH: false,
                giftCardKeyboardSH: false,
                giftcardSecDisabled: true,
              }, () => {
                this.props.timerDecrement();
              })
            }
            // -----This is gift card condition check-----
            if(response.data.remainingOrderValue == 0){
              this.setState({
                footerPayBtnSH: false,
                creditDebitSecDisabled: true,
              })
            }
          })
        })
        .catch((ex) => {
          console.log(ex.error, 'giftcard error');
          this.setState({
            loader: false,
            giftcardErrorMsg: ex.error
          }, () => {
            if(!this.state.giftcardScanningSecSH){
              this.setState({
                giftCardModalSH: false
              }, () => {
                showAlert({
                  html: `<p>${this.state.giftcardErrorMsg}</p>`,
                  iconHtml: `<img src=${infoIcon}>`,
                  showCancelButton: false,
                  cancelButtonText: "CANCEL",
                  showConfirmButton: true,
                  confirmButtonText: "OK",
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  iconClasses: "borderless",
                  popupClasses: "order-error-popup",
                  onConfirm: () => {},
                  onCancel: () => { },
                });
              })
            }
          });
        });
    });
  }

  redeemBtnClick = () => {
    this.setState({
      loader:true
    }, () => {
      this.getGiftcardAPI();
    })
  }

  giftcardApplied = () => {
    var tempGiftcardPaymentTypes = this.state.giftcardPaymentTypes;
    var giftCardReturn = '';
    if (tempGiftcardPaymentTypes.length > 0) {
      giftCardReturn = tempGiftcardPaymentTypes.map((giftCardData, index) => {
        return (
          <section className="giftcard-details">
            <h4>{giftCardData.pgName} ({giftCardData.cardinformation.cardNumber}) <span>Gift card applied</span></h4>
            <a onClick={() => this.giftcardDelete(index, false)} href="javascript:;"><img src={deleteIcon} alt="delete" /></a>
          </section>
        )
      })
    }
    return giftCardReturn;
  }

  giftcardDelete = (getIndex, redirect) => {
    this.setState({
      loader: true
    }, () => {
      var tempGiftcardPaymentTypes = this.state.giftcardPaymentTypes;
      // console.log(tempGiftcardPaymentTypes[getIndex].cardinformation.cardNumber, 'cardNumber');
      var getGiftCardNumber = tempGiftcardPaymentTypes[getIndex].cardinformation.cardNumber;
      var data = {
        "giftcardno": getGiftCardNumber
      }
      API.callEndpoint("DELETE", "Bearer", `/order/v2/orders/${this.state.orderid}/giftcard`, data)
        .then((response) => {
          tempGiftcardPaymentTypes.splice(getIndex, 1);
          this.setState({
            giftcardSecDisabled: false,
            creditDebitSecDisabled: false,
            footerPayBtnSH: true,
            loader: false
          }, () => {
            if(redirect){
              var getorderIdFromLocalstorage = JSON.parse(localStorage.getItem("orderId"));
              window.location.href = `/order-review?orderid=${getorderIdFromLocalstorage.id}`;
            }
          });
        })
        .catch((ex) => {
          console.log(ex.error, 'giftcard error');
          this.setState({
            loader: false
          });
        });
    });
  }

  payBtnClick = () => {
    this.setState({
      loader: true
    }, () => {
      var tempGiftcardData = this.state.giftcardData;
      if(Object.keys(tempGiftcardData).length > 0 && tempGiftcardData.remainingOrderValue == 0) {
        var zeroOrderPayload = {
          "orderid": this.state.orderid,
          "request": {},
          "response": {},
          "status": "SUCCESS",
          "encryptedVal": this.state.getEncryptedVal
        }
        API.callEndpoint("POST", "Bearer", `/checkout/v2/checkout/kioskCommitOrder`, zeroOrderPayload)
        .then((response) => {
          console.log(response.data, 'commit order ---- response.data');
          var commitOrderResponse = response.data;
          this.setState({
            loader: false
          }, () => {
            window.location.href = `/confirmation?id=${this.state.orderid}&paymentid=${commitOrderResponse.paymentid}&status=${commitOrderResponse.status}`;
          })
        })
        .catch((ex) => {
          console.log(ex.error, 'commit order ---- giftcard error');
          this.setState({
            loader: false
          }, () => {
            this.showErrorPopup(ex.error);
          });
        });
      }
    })
  }

  showErrorPopup = (getErrorMsg) => {
    var errMsg = getErrorMsg.toLowerCase();
    showAlert({
      html: `<p>${getErrorMsg}</p>`,
      iconHtml: `<img src=${infoIcon}>`,
      showCancelButton: false,
      cancelButtonText: "CANCEL",
      showConfirmButton: true,
      confirmButtonText: "OK",
      allowOutsideClick: false,
      allowEscapeKey: false,
      iconClasses: "borderless",
      popupClasses: "order-error-popup",
      onConfirm: () => {
        var getMovieName = localStorage.getItem("selectedmoviename");
        var redirectURL = `/showtime?title=${getMovieName}`;
        orderExpiredRedirect(errMsg, redirectURL);
      },
      onCancel: () => { },
    });
  }

  render() {
    const getSeatsQuantity = (selectedSeats) => {
      selectedSeats = selectedSeats.filter((seat) => seat.type === "TICKET");
      let seatsQuantity = 0;
      selectedSeats.forEach((seat) => {
        seatsQuantity += seat.quantity;
      });
      seatsQuantity = seatsQuantity > 1 ? `${seatsQuantity} Seats` : `${seatsQuantity} Seat`;
      return seatsQuantity;
    };

    return (
      <>
        <Loader loader={this.state.loader} />
        <section className="payment-container">
          <Header logo={true} homeIcon={false} timer={true} hideGCModalBookingTimerElapse={this.hideGCModalBookingTimerElapse} />
          <section className="booking-flow-sec">
            <BookingFlow currentStep="3" />
          </section>

          {/* {Object.keys(this.state.orderData).length > 0 && (
            <section className="payment-desc">
              <div className="accordion" id="payment-accordion">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="payment-heading">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#payment-collaspse" aria-expanded="false" aria-controls="payment-collaspse">
                      <div className="movie-info-blk">
                        <MovieInfo filmsData={this.state.movieInfoDetails} selectedSeats={this.state.orderData.orders_items} isSeatLayoutPage={false} hideMovieBg={true}></MovieInfo>
                      </div>
                    </button>
                  </h2>
                  <div id="payment-collaspse" className="accordion-collapse collapse" aria-labelledby="payment-heading" data-bs-parent="#payment-accordion">
                    <div className="accordion-body">
                      <div className="movie-info-blk">
                        <MovieInfo filmsData={this.state.movieInfoDetails} selectedSeats={this.state.orderData.orders_items} isSeatLayoutPage={false} hideMovieBg={true}></MovieInfo>
                      </div>
                      <div className="bookingSummary">
                        <BookingSummary
                          ticketsDataForTicketSummary={this.state.ticketsDataForTicketSummary}
                          fnbDataForTicketSummary={this.state.fnbDataForTicketSummary}
                          incDecItem={false}
                          nextBtnClick={false}
                          nextBtnText={""}
                          bookingFee={this.state.orderData.bookingfeebeforetax}
                          tax={this.state.orderData.taxValue}
                        ></BookingSummary>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )} */}

          {Object.keys(this.state.orderData).length > 0 && (
            <section className="payment-desc paymentPageMovieInfo">
              <div className="accordion" id="payment-accordion">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="payment-heading">
                    {
                      this.state.orderType === "ticketing" ? (
                        <button className="accordion-button collapsed movie-details" type="button" data-bs-toggle="collapse" data-bs-target="#payment-collaspse1" aria-expanded="false" aria-controls="payment-collaspse">
                          <div className="movie-info-blk">
                            <section className="banner-wrap noAfter">
                              <div className="movie-detail-wrap">
                                <aside className="movie-detail">
                                  <h1 className="movie-title">{this.state.movieInfoDetails.title}</h1>
                                  <div className="movieSupportInfo">
                                    {this.state.movieInfoDetails.timing && (
                                      <span className="calender-blk">
                                        <img src={IconCalendar} alt="" />
                                        {this.state.movieInfoDetails.timing}
                                      </span>
                                    )}
                                    {this.state.movieInfoDetails.experiences && (
                                      <span className="screen-blk">
                                        <img src={IconScreen} alt="" />
                                        {this.state.movieInfoDetails.experiences}
                                      </span>
                                    )}
                                    {/* {this.state.movieInfoDetails.screenName && (
                                      <span className="screen-name">
                                        <img src={IconScreen} alt="" />
                                        {this.state.movieInfoDetails.screenName}
                                      </span>
                                    )} */}
                                    {this.state.movieInfoDetails.showTime && (
                                      <span className="movieShowtime">
                                        <img src={IconClock1} alt="" />
                                        {this.state.movieInfoDetails.showTime}
                                      </span>
                                    )}
                                    {this.state.orderData.orders_items.length > 0 && (
                                      <span className="seat-blk">
                                        <img src={SeatIcon} alt="Seat" />
                                        {getSeatsQuantity(this.state.orderData.orders_items)}

                                        <span className="ps-0">&nbsp;-&nbsp;
                                          {this.state.orderData.orders_items.map((item, index) => (
                                            item.type.toLowerCase() === "ticket" &&
                                            <>
                                              {item.seats}
                                              {index < this.state.orderData.orders_items.length - 1 ? ", " : ""}
                                            </>
                                          ))}
                                        </span>
                                      </span>
                                    )}
                                  </div>
                                </aside>
                              </div>
                            </section>
                          </div>
                        </button>
                      ) : (
                        <button className="accordion-button collapsed booking-summary-title" type="button" data-bs-toggle="collapse" data-bs-target="#payment-collaspse1" aria-expanded="false" aria-controls="payment-collaspse">
                          Booking Summary
                        </button>
                      )
                    }
                  </h2>
                  <div id="payment-collaspse1"
                    // className={`accordion-collapse collapse ${this.state.orderType == 'fnb' ? 'show':''}`}
                    className={`accordion-collapse collapse`}
                    aria-labelledby="payment-heading"
                    data-bs-parent="#payment-accordion"
                  >
                    <div className="accordion-body">
                      {
                        Object.keys(this.state.movieInfoDetails).length > 0 &&
                        <div className="movie-info-blk">
                          <section className="banner-wrap noAfter">
                            <div className="movie-detail-wrap">
                              <aside className="movie-detail">
                                <div className="movie-desc">
                                  <span className="ratingSpan">{this.state.movieInfoDetails.rating}</span>
                                  {this.state.movieInfoDetails.genres?.length > 0 && (
                                    <span>
                                      {this.state.movieInfoDetails.genres.map((actionData, index) => (
                                        <React.Fragment key={index}>
                                          {actionData}
                                          {this.state.movieInfoDetails.genres.length - 1 !== index && "/"}
                                        </React.Fragment>
                                      ))}
                                    </span>
                                  )}
                                  {this.state.movieInfoDetails.language && <span>Language - {this.state.movieInfoDetails.language}</span>}
                                  {this.state.movieInfoDetails.runtime && <span>{calculateHoursAndMinutes(this.state.movieInfoDetails.runtime)}</span>}
                                  {this.state.movieInfoDetails.subtitle && <span>Subtitle - {this.state.movieInfoDetails.subtitle}</span>}
                                </div>
                              </aside>
                            </div>
                          </section>
                        </div>
                      }
                      <div className="bookingSummary">
                        <BookingSummary
                          ticketsDataForTicketSummary={this.state.ticketsDataForTicketSummary}
                          fnbDataForTicketSummary={this.state.fnbDataForTicketSummary}
                          incDecItem={false}
                          nextBtnClick={false}
                          nextBtnText={""}
                          bookingFee={this.state.orderData.bookingfeebeforetax}
                          tax={this.state.orderData.taxValue}
                          giftcardPaymentTypes={this.state.giftcardPaymentTypes}
                          getRemainingOrderValue={this.state.getRemainingOrderValue}
                        ></BookingSummary>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          <section className="content-blk">
            {/* <section className="your-details-blk">
              <h3>
                Your Details <span>Your movie awaits, just a few details to record booking.</span>
              </h3>
              <section className="form-blk">
                <div class="input-wrap">
                  <input type="text" placeholder="NAME" autocomplete="off" />
                </div>
                <div class="input-wrap">
                  <input type="text" placeholder="EMAIL" autocomplete="off" />
                </div>
              </section>
              <section className="tandc-blk">
                <div className="form-groups">
                  <input id="promo" type="checkbox" />
                  <label htmlFor="promo">
                    I would like to receive information about special promotions. <i>&nbsp;</i>
                  </label>
                </div>
                <div className="form-groups">
                  <input id="tandc" type="checkbox" />
                  <label htmlFor="tandc">
                    I accept the{" "}
                    <a href="javascript:;" title="Terms & Conditions">
                      Terms & Conditions
                    </a>{" "}
                    of this purchase <i>&nbsp;</i>
                  </label>
                </div>
              </section>
            </section> */}

            <section className="choose-payment-sec">
              <h3>Choose your payment</h3>
              <div className="payment-list">
                <ul>
                  <li className={`gift-card ${this.state.giftcardSecDisabled ? 'disabled' : ''}`}>
                    <p onClick={this.giftCardOnClick}>
                      <span>Gift card</span>
                    </p>
                    {this.giftcardApplied()}
                  </li>
                  <li className={`credit-card ${this.state.creditDebitSecDisabled ? 'disabled':''}`}>
                    <p onClick={this.creditCardOnClick}>
                      <span>Credit / Debit cards</span>
                    </p>
                  </li>
                </ul>
              </div>
            </section>
          </section>
          <PurchaseFooter
            fnbDataForTicketSummary={[]}
            ticketsDataForTicketSummary={[]}
            removeItemFromCart={false}
            incDecItem={false}
            backBtnClick={this.backBtnClick}
            nextBtnClick={this.payBtnClick}
            nextBtnText="PAY"
            nextBtnDisable={this.state.footerPayBtnSH}
          ></PurchaseFooter>
        </section>
        <GiftCardModal
          parentState={this.state}
          scanModalCloseBtnClick={this.scanModalCloseBtnClick}
          giftCardKeyboardIcon={this.giftCardKeyboardIcon}
          giftCardKeyBoardOnChange={this.giftCardKeyBoardOnChange}
          giftCardKeyBoardOnKeyPress={this.giftCardKeyBoardOnKeyPress}
          disableRedeemBtn={this.disableRedeemBtn}
          enterManuallyClick={this.enterManuallyClick}
          redeemBtnClick={this.redeemBtnClick}
          timerIncrement={this.props.timerIncrement}
          timerDecrement={this.props.timerDecrement}
        />
      </>
    );
  }
}

export default index;
