import React from "react";
import { monthNameArray } from "../constants";

let dtt = new Date();
let gettHour = dtt.getHours();
let gettMin = dtt.getMinutes();
let gettCurdt = dtt.getDate();
export let Hr = gettHour > 9 ? "" + gettHour : "0" + gettHour;
export let Min = gettMin > 9 ? "" + gettMin : "0" + gettMin;
export let Dayy = dtt.toString().split(" ")[0];
export let Mont = dtt.toLocaleString("default", { month: "short" });
export let Dat = gettCurdt > 9 ? "" + gettCurdt : "0" + gettCurdt;

const Ordinal = (n) => {
  var s = ["th", "st", "nd", "rd"];
  var v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

const getTmeFrmt = (getTimeStamp, method) => {
  // Get Hour Min AM PM
  let getHour = getTimeStamp[0];
  let getMin = getTimeStamp[1];
  let Hour, Mins, AMPM, setHour, res;
  let cokiegetLang = "en";
  // console.log(cokiegetLang);
  if (parseInt(getHour) >= 12) {
    Hour = parseInt(getHour) > 9 ? "" + parseInt(getHour) : "0" + parseInt(getHour);
    Mins = getMin;

    if (cokiegetLang == "" || cokiegetLang == "en") {
      AMPM = "pm";
    } else {
      AMPM = "م";
    }
    if (parseInt(getHour) > 12) {
      setHour = parseInt(getHour) - 12;
      Hour = setHour > 9 ? "" + setHour : "0" + setHour;
    }
  } else {
    Hour = parseInt(getHour) > 9 ? "" + parseInt(getHour) : "0" + parseInt(getHour);
    Mins = getMin;
    if (cokiegetLang == "" || cokiegetLang == "en") {
      AMPM = "am";
    } else {
      AMPM = "ص";
    }
  }
  if (getHour == "00") {
    Hour = 12;
  }
  if (method == "default") {
    return (res = Hour + ":" + Mins + " " + AMPM);
  } else if (method == "NoMeridiem") {
    return (res = Hour + ":" + Mins);
  }
};
export const IsoToLocale = () => {
  let timeZoneOffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  let localTimeZone = new Date(Date.now() - timeZoneOffset).toISOString().slice(0, -1);
  return localTimeZone;
};
export const DateTimeFormatISO = (datestamp, method) => {
  let cokiegetLang = "en";
  // Splitting Date and Time
  let splitDt = datestamp.split("T");

  // Original Date format as per Datestamp
  let orgDtfrmt = splitDt[0];

  // Original Time format as per Datestamp
  let orgTmefrmt = splitDt[1];

  // console.log(splitDt[0]);
  // Getting Date and Replacing - to /
  let getDate = splitDt[0].replace(/-/g, "/");

  let dt = new Date(getDate);
  let result;
  let monthNames, dayNames;
  if (cokiegetLang == "" || cokiegetLang == "en") {
    monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  } else {
    monthNames = ["كانون الثاني", "شباط", "آذار", "نيسان", "أيار", "حزيران", "تموز", "آب", "أيلول", "تشرين الأول", "تشرين الثاني", "كانون الأول"];
    dayNames = ["يَوم الأحَد", "يَوم الإثنين", "يَوم الثلاثاء", "يَوم الأربعاء", "يَوم الخميس", "يَوم الجمعة", "يَوم السبت"];
  }

  // Splitting Dateformat as Date Month year
  let specificdt = getDate.split("/");

  // Splitting Timeformat as Hour Mins (Seconds,AMPM)
  let getTimeStamp = splitDt[1].split(":");

  switch (method) {
    case "Year":
      result = specificdt[0];
      //console.log(result);
      return result;
      break;
    case "YearLastTwoDigit":
      result = specificdt[0].toString().substr(-2);
      //console.log(result);
      return result;
      break;
    case "Month":
      result = specificdt[1];
      //console.log(result);
      return result;
      break;
    case "MonthFullName":
      result = monthNames[dt.getMonth()];
      //console.log(result);
      return result;
      break;
    case "MonthHalfName":
      if (cokiegetLang == "" || cokiegetLang == "en") {
        result = dt.toLocaleString("en-us", { month: "short" });
      }
      if (cokiegetLang == "" || cokiegetLang == "ar") {
        result = dt.toLocaleString("ar-us", { month: "short" });
      }
      //console.log(result);
      return result;
      break;
    case "Date":
      result = specificdt[2];
      //console.log(result);
      return result;
      break;
    case "DateOrdinal":
      let getDtord = specificdt[2];
      let setdtord;
      let getindexof = getDtord.indexOf("0");
      if (getindexof == 0) {
        setdtord = getDtord.replace("0", "");
        // console.log(setdtord);
      } else {
        setdtord = getDtord;
      }
      result = Ordinal(setdtord);
      //console.log(result);
      return result;
      break;
    case "DayFullName":
      result = dayNames[dt.getDay()];
      //console.log(result);
      return result;
      break;
    case "DayHalfName":
      // result = dt.toString().split(' ')[0];
      if (cokiegetLang == "" || cokiegetLang == "en") {
        result = dt.toString().split(" ")[0];
      }
      if (cokiegetLang == "" || cokiegetLang == "ar") {
        result = dt.toLocaleString("ar-SA", { weekday: "short" });
      }
      //console.log(result);
      return result;
      break;
    case "TimeDefault":
      result = getTmeFrmt(getTimeStamp, "default");
      //console.log(result);
      return result;
      break;
    case "TimeNoMeridiem":
      result = getTmeFrmt(getTimeStamp, "NoMeridiem");
      //console.log(result);
      return result;
      break;
    case "ShowDate":
      let setDate = getDate.split("/");
      result = setDate[2] + "/" + setDate[1] + "/" + setDate[0];
      //console.log(result);
      return result;
      break;
    default:
      result = orgDtfrmt;
      //console.log(result);
      return result;
  }
};

export const millisToHoursandMinutesAndSeconds = (millis) => {
  let hours = millis / (1000 * 60 * 60);
  let absoluteHours = Math.floor(hours);
  let h = absoluteHours > 9 ? absoluteHours : "0" + absoluteHours;

  //Get remainder from hours and convert to minutes
  let minutes = (hours - absoluteHours) * 60;
  let absoluteMinutes = Math.floor(minutes);
  let m = absoluteMinutes > 9 ? absoluteMinutes : "0" + absoluteMinutes;

  //Get remainder from minutes and convert to seconds
  let seconds = (minutes - absoluteMinutes) * 60;
  let absoluteSeconds = Math.floor(seconds);
  let s = absoluteSeconds > 9 ? absoluteSeconds : "0" + absoluteSeconds;

  return h + ":" + m + ":" + s;
};

export const calculateHoursAndMinutes = (min) => {
  const hours = Math.floor(min / 60);
  const minutes = min % 60;
  return hours + " Hrs " + minutes + " mins  ";
};

export const formatFilmShowDate = (showDate) => {
  const actualShowDate = showDate.split("T")[0].split("-");
  const date = actualShowDate[2] < 10 ? actualShowDate[2].replace("0", "") : actualShowDate[2];
  const month = actualShowDate[1] < 10 ? actualShowDate[1].replace("0", "") : actualShowDate[1];
  const monthName = monthNameArray[month - 1];
  const year = actualShowDate[0];

  return date + " " + monthName + " " + year;
};

export const formatShowTime = (time) => {
  // console.log(time);
  var timeArray = time.split("T")[1].split(":");
  var hours = timeArray[0] < 12 ? timeArray[0] : timeArray[0] == 12 || timeArray[0] == 0 ? 12 : timeArray[0] > 12 ? timeArray[0] - 12 : " ";

  var minutes = timeArray[1];
  var meridian = timeArray[0] >= 12 ? "PM" : "AM";
  return hours + ":" + minutes + " " + meridian;
};

export const formatCurrentTime = (time) => {
  var time = time.toString();
  var timeArray = time.split(" ")[4];
  var hours = timeArray.split(":")[0];
  var minutes = timeArray.split(":")[1];
  var meridian = hours >= 12 ? "PM" : "AM";

  switch (hours) {
    case 12:
    case "00":
      hours = 12;
      break;
    default:
      hours = hours > 12 ? hours - 12 : hours;
      break;
  }
  return hours + ":" + minutes + " " + meridian;
};
