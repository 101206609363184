import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../../styles/common.scss";
import "./index.scss";
import InfluxMovies from "../../assets/images/movies/Influx-movies-logo.svg";
import * as API from "../../configuration/apiconfig";
import Loader from "../loader";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import IconAllExperiences from "../../assets/images/icons/iconAllExperiences.svg";
import { calculateHoursAndMinutes, formatCurrentTime } from "../../utils/DateTimeFormat";
import clockIcon from "../../assets/images/clockIcon_black.svg";
import resetIcon from "../../assets/images/resetIcon.svg";
import closeIcon from "../../assets/images/closeIcon.svg";
import alertIcon from "../../assets/images/Alert.svg";
import { filterPackImageBasedOnImageCode, filterImageBasedOnImageCode } from "../../utils/FilterImages";
import Slider from "react-slick";
import Modal from "react-bootstrap/Modal";
import Header from "../../components/Header";
var setTime;
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      allFilms: [],
      nowShowingData: [],
      movies: [],
      currentDate: new Date(),
      heroBannerImages: [],
      showExperienceModal: false,
      experienceModalWidth: 0,
      experienceModalWidth: 0,
      allExperiences: [],
      selectedExperiences: [],
      clickedExperiences: [],
    };
  }

  componentDidMount = () => {
    this.getFilms();
    this.getAllExperiences();
    var heroBannerSource = process.env.REACT_APP_MOVIES_PAGE_HEROBANNER_SOURCE;
    if (heroBannerSource.includes("https") || heroBannerSource.includes("http")) {
      this.setState({
        heroBannerImages: heroBannerSource.split(","),
      });
    } else {
      API.callEndpoint("GET", "Bearer", "/cms/v2/packs/" + heroBannerSource).then((response) => {
        var heroBannerImages = filterPackImageBasedOnImageCode(response, process.env.REACT_APP_MOVIES_PAGE_HEROBANNER_IMAGECODE);
        this.setState({ heroBannerImages }, () => {});
      });
    }
    localStorage.removeItem("sesstionid");
    localStorage.removeItem("selectedmoviename");
    localStorage.removeItem("sessionExpiredRedirectUrl");
    localStorage.removeItem("endTimeInSecs");
    localStorage.removeItem("BookingCountdown");
    var self = this;
    setTime = setInterval(() => {
      if (self.state.currentDate != new Date()) {
        self.setState({
          currentDate: new Date(),
        });
      }
    }, 2000);
  };

  componentDidUpdate = () => {
    var modalBody = document.getElementById("modalBody");
    if (modalBody != null) {
      if (modalBody.offsetWidth != this.state.experienceModalWidth) {
        this.setState({
          experienceModalWidth: modalBody.offsetWidth,
        });
      }
    }
  };

  componentWillUnmount() {
    clearInterval(setTime);
  }

  getFilms = async () => {
    this.setState({
      loader: true,
    });
    const films = await API.callEndpoint("GET", "Bearer", "/cms/v2/films");
    // console.log(films, 'films');
    this.setState(
      {
        allFilms: films.data,
      },
      () => {
        this.getNowshowing();
      }
    );
  };

  getNowshowing = () => {
    this.setState(
      {
        loader: true,
      },
      () => {
        var getCinemaIdFromLocalstorage = localStorage.getItem("cinemaid");
        var nowShowingURL = "";
        if (getCinemaIdFromLocalstorage) {
          nowShowingURL = `/cms/v2/packs/nowshowing?cinemaid=${getCinemaIdFromLocalstorage}`;
        } else {
          nowShowingURL = `/cms/v2/packs/nowshowing`;
        }
        API.callEndpoint("GET", "Bearer", nowShowingURL).then((response) => {
          this.setState(
            {
              nowShowingData: response.data[0],
              loader: false,
            },
            () => {
              this.showMovies();
            }
          );
        });
      }
    );
  };

  showMovies = () => {
    var tempAllFilms = this.state.allFilms;
    var tempNowShowingData = this.state.nowShowingData;
    if (tempNowShowingData !== undefined && Object.keys(tempNowShowingData).length > 0 && tempNowShowingData.packs_films_results.length > 0) {
      var totalMovies = tempAllFilms.filter((c) => tempNowShowingData.packs_films_results.find((s) => s.filmid == c.id));

      this.setState({
        movies: totalMovies,
      });
    }
  };

  moviesRender = () => {
    var tempNowShowingData = this.state.nowShowingData;
    var tempMovies = JSON.parse(JSON.stringify(this.state.movies));
    if (this.state.selectedExperiences.length > 0) {
      var moviesAfterExpFilter = [];
      tempMovies.forEach((movie) => {
        const intersection = movie.experiences.filter((element) => this.state.selectedExperiences.includes(element));
        if (intersection.length != 0) {
          moviesAfterExpFilter.push(movie);
        }
      });
      tempMovies = moviesAfterExpFilter;
    }

    var renderElem = "";
    {
      tempMovies.length > 0
        ? (renderElem = tempMovies.map((filmsData, index) => {
            return (
              <div key={index} className="movies-list col-3">
                <a onClick={() => this.selectMovieClick(filmsData)} title={filmsData.title}>
                  {filmsData.images.length > 0
                    ? filmsData.images
                        .filter((img) => img.code == "x250y350")
                        .map(({ imageurl }) => {
                          return (
                            <div className="img-blk">
                              <img src={imageurl ? imageurl : ""} className="img-fluid" alt={filmsData.title} />
                            </div>
                          );
                        })
                    : null}
                  <aside className="cont-sec">
                    <h4>{filmsData.title}</h4>
                    <p>
                      {filmsData.rating && <span>{filmsData.rating}</span>}
                      {filmsData.rating && filmsData.runtime && <>&nbsp;-&nbsp;</>}
                      {filmsData.runtime && <span>{calculateHoursAndMinutes(filmsData.runtime)}</span>}
                      {filmsData.runtime && filmsData.genres.length > 0 && <>&nbsp;-&nbsp;</>}
                      {filmsData.genres.map((genresData, index) => {
                        return (
                          <span>
                            {genresData} {filmsData.genres.length - 1 == index ? "" : "/"}{" "}
                          </span>
                        );
                      })}
                    </p>
                  </aside>
                  <div className="mov-det">
                    {filmsData.emblems.length > 0 ? (
                      <aside className="ad-book">
                        <span>{filmsData.emblems[0]}</span>
                      </aside>
                    ) : null}
                  </div>
                </a>
              </div>
            );
          }))
        : (renderElem =
            Object.keys(tempNowShowingData).length > 0 && tempNowShowingData.packs_films_results.length == 0 ? (
              <div className="error-blk">
                <p>Currently there are no movies to be played.</p>
              </div>
            ) : (
              <SkeletonTheme baseColor="#d6d6d6" highlightColor="#ccc">
                <div className="movie-skeletons">
                  <Skeleton count={12} height="400px" width="230px" />
                </div>
              </SkeletonTheme>
            ));
    }

    if (this.state.selectedExperiences.length > 0 && tempMovies.length == 0) {
      renderElem = (
        <div className="no-movies-found">
          <img src={alertIcon}></img>
          <p>No movies found for selected {this.state.selectedExperiences.length > 1 ? "experiences" : "experience"}</p>
        </div>
      );
    }

    return renderElem;
  };

  toggleExperienceModal = (visibility) => {
    if (visibility) {
      this.setState({
        clickedExperiences: [...this.state.selectedExperiences],
      });
    }
    this.setState({ showExperienceModal: visibility });
  };

  getAllExperiences = () => {
    API.callEndpoint("GET", "Bearer", "/cms/v2/experiences").then((response) => {
      console.log(response.data);
      this.setState({
        allExperiences: response.data,
      });
    });
  };

  experienceClick = (experience) => {
    var clickedExperiences = JSON.parse(JSON.stringify(this.state.clickedExperiences));
    var checkIfExperienceExists = clickedExperiences.includes(experience.id);
    if (!checkIfExperienceExists) {
      clickedExperiences.push(experience.id);
    } else {
      clickedExperiences.splice(clickedExperiences.indexOf(experience.id), 1);
    }
    this.setState({ clickedExperiences });
  };

  clearSelectedExperiences = () => {
    this.setState({
      clickedExperiences: [],
    });
  };

  selectMovieClick = (getMovie) => {
    console.log(getMovie, "getMovie");
    localStorage.setItem("selectedmoviename", getMovie.urlSafeName);
    window.location.href = `/showtime?title=${getMovie.urlSafeName}`;
  };

  applyExperiences = () => {
    this.setState(
      {
        selectedExperiences: [...this.state.clickedExperiences],
      },
      () => {
        this.toggleExperienceModal(false);
        this.clearSelectedExperiences();
      }
    );
  };

  render() {
    var { currentDate, heroBannerImages, allExperiences, selectedExperiences, clickedExperiences } = this.state;
    var settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      fade: true,
      autoplaySpeed: 5000,
      className: "slickSlider heroBanner",
      appendDots: (dots) => <ul>{dots}</ul>,
      customPaging: (i) => <div className="customDots"></div>,
    };
    return (
      <>
        <Loader loader={this.state.loader} />
        <section
          // className="movie-container"
          className={`movie-container ${this.state.heroBannerImages.length == 0 ? "noHeroBannerBlock" : ""}`}
        >
          <Header logo={false} homeIcon={true} timer={false} />
          <div className="movie-banner">
            {heroBannerImages.length > 0 && (
              <Slider {...settings}>
                {heroBannerImages.map((image, imageIndex) => {
                  return <img src={image} alt="Muvi banner" style={{ height: 540 }} />;
                })}
              </Slider>
            )}
          </div>
          <div className="op-sec">
            <aside className="timer">
              <img src={clockIcon} alt="Timer" />
              <p>{formatCurrentTime(currentDate)}</p>
            </aside>
            <aside className="rt-sec">
              {/* <a href="javascript:;" className="filterButton fbPrimary" title="Next 10 SHOWS">
                <img src={IconNext10Shows} alt="Next 10 SHOWS" />
                Next 10 shows
              </a> */}
              <a href="javascript:;" className="filterButton fbSecondary" title="ALL EXPERIENCES" onClick={() => this.toggleExperienceModal(true)}>
                <img src={IconAllExperiences} alt="ALL EXPERIENCES" />
                All Experiences
                {selectedExperiences.length > 0 && <p className="filterCount">{selectedExperiences.length}</p>}
              </a>
            </aside>
          </div>
          <div className="movies-sec">
            <div className="container-fluid">
              <div className="row">{this.moviesRender()}</div>
            </div>
          </div>
          <div className="movies-footer">
          <a target="_blank" title="Powered by influx">
              <span>Powered by</span>
              <img src={InfluxMovies} alt="Influx" />
            </a>
          </div>
        </section>
        <Modal show={this.state.showExperienceModal} onHide={() => this.toggleExperienceModal(false)} centered id="experienceModal">
          <Modal.Header>
            <button className="closButton" onClick={() => this.toggleExperienceModal(false)}>
              <img src={closeIcon} />
            </button>
            <Modal.Title className="heading">Filter by Experience</Modal.Title>
          </Modal.Header>
          <Modal.Body id="modalBody">
            {allExperiences.length > 0 &&
              allExperiences.map((experience) => {
                return (
                  <div className={`experienceDiv ${clickedExperiences.includes(experience.id) ? "active" : ""}`} onClick={() => this.experienceClick(experience)}>
                    {/* <img src={filterImageBasedOnImageCode(experience.images, "x200y50").length > 0 ? filterImageBasedOnImageCode(experience.images, "x200y50")[0] : ""}></img> */}
                    <span>{experience.name}</span>
                  </div>
                );
              })}
          </Modal.Body>
          <Modal.Footer>
            <div className="applyResetDiv" style={{ width: this.state.experienceModalWidth }}>
              <button className="btn btn-primary resetButton" onClick={this.clearSelectedExperiences}>
                <img src={resetIcon} /> RESET
              </button>
              <button className="btn btn-primary applyButton" onClick={() => this.applyExperiences()}>
                APPLY
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default index;
