import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "./index.scss";
import handScan from "../../assets/images/hand-scan.svg"
import keyboardIcon from "../../assets/images/kayboard-icon.svg"
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { BrowserRouter as Router, Link } from "react-router-dom";
import spinnerLoader from "../../assets/images/spinner-loader.gif"
import { keyboardLayout } from "../../utils/keyBoardLayout"

export default class BarCodeModal extends Component {
  componentDidUpdate = () => {
    if(this.props.parentState.manualBarCodeBtnClick){
      var getInputElem = document.getElementById('memberInput');
      if(getInputElem){
        document.getElementById("memberInput").focus();
      }
    }
  }
  render() {
    return (
      <>
      <Modal 
        show={this.props.parentState.barcodeModalSH} 
        dialogClassName="barcode-popup" 
        centered>
        <Modal.Body>
          <div className="close-btn" onClick={() => this.props.closeIconClick(this.props.parentState.scanBarCodeBtnClick ? 'scanBarcodeClose':'')}>
            <a href="javascript:;" title="Close">&nbsp;</a>
          </div>
          <div className="content">
            {
              this.props.parentState.scanBarCodeBtnClick &&
              <>
                <img src={handScan} alt="Scan" />
                <h1>Scan your Barcode</h1>
              </>
            }
            {
              !this.props.parentState.scanBarCodeBtnClick ?
                <>
                  <h2>Enter your Member Card Number</h2>
                  <div className="manual-scanner-sec">
                    <div className="form-blk">
                      <div className="fm-inner">
                        <div className="input-wrap">
                          <input 
                            ref={(input) => { this.textInput = input; }}
                            id="memberInput"
                            type="text" 
                            placeholder="Member Card Number"
                            autoComplete="off" 
                            value={this.props.parentState.memberCardNumber}
                            readOnly
                          />
                        </div>
                        {
                          this.props.keyBoardIcon &&
                          <div className="kb-wrap" onClick={this.props.clickKeyboardIcon}>
                            <img src={keyboardIcon} alt="Keyboard" />
                          </div>
                        }
                      </div>
                    </div>
                    <div className="scan-btn">
                      <a onClick={this.props.userLogin} href="javascript:;" className={`btn btnPrimary ${this.props.disableSubmitBtn() ? 'disabled':''}`} title="SUBMIT">SUBMIT</a>
                    </div>
                  </div>
                  {
                    this.props.parentState.loginErrorMsg != '' &&
                    <div className="error-blk">
                      <p>{this.props.parentState.loginErrorMsg}</p>
                    </div>
                  }
                </>
                :
                <>
                  <div className="loader-blk">
                    <img src={spinnerLoader} alt="Loader" />
                    <p>{this.props.parentState.scanBarcodeLoaderMsg}</p>
                  </div>
                </>
              }
          </div>
        </Modal.Body>
      </Modal>
      {
        this.props.parentState.keyboardInputClickManually ?
        (<Keyboard
          keyboardRef={r => (this.keyboard = r)}
          layoutName={this.props.parentState.layoutName}
          onChange={this.props.keyBoardOnChange}
          onKeyPress={this.props.keyBoardOnKeyPress}
          layout={keyboardLayout}
          display={{
            "{bksp}": "Backspace",
          }}
          disableRowButtonContainers={true}
          baseClass={"login-barcodescan-keyboard"}
        />):(null)
      }
      </>
    )
  }
}
