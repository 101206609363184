import React, { Component } from "react";
import "./index.scss";
import CustomBanner from "../../components/CustomBanner"
import * as API from "../../configuration/apiconfig";
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryData: [],
      getUserDetails: {
        firstName: API.getValueFromCookie("firstname"),
        lastName: API.getValueFromCookie("lastname")
      },
    };
  }

  proceedBtnClick = () => {
    var getSessionIdFromLocalstorage = localStorage.getItem('sesstionid');
    let isFnbFlow = localStorage.getItem('orderType') == "fnb";
    let cinemaId = localStorage.getItem('cinemaid');
    if (isFnbFlow) {
      window.location.href = ` fnb?cinemaid=${cinemaId}`
    } else {
      window.location.href = `/seatlayout?sessionid=${getSessionIdFromLocalstorage}`;
    }
  }

  thisIsNotMeBtn = () => {
    document.cookie = "accessToken=;max-age=0";
    document.cookie = "refreshToken=;max-age=0";
    document.cookie = "firstname=;max-age=0";
    document.cookie = "lastname=;max-age=0";
    window.location.href = `/login`;
  }

  guestUser = () => {
    document.cookie = "accessToken=;max-age=0";
    document.cookie = "refreshToken=;max-age=0";
    document.cookie = "firstname=;max-age=0";
    document.cookie = "lastname=;max-age=0";
    var getSessionIdFromLocalstorage = localStorage.getItem('sesstionid');
    window.location.href = `/seatlayout?sessionid=${getSessionIdFromLocalstorage}`;
  }

  yourdetailsBackBtn = () => {
    document.cookie = "accessToken=;max-age=0";
    document.cookie = "refreshToken=;max-age=0";
    document.cookie = "firstname=;max-age=0";
    document.cookie = "lastname=;max-age=0";
    window.location.href = `/login`;
  }

  render() {
    let { getUserDetails } = this.state;
    return (
      <section className="yourdetails-page">
        <section className="yr-details-wrapper">
          <CustomBanner
            title={"Welcome Movie Buff!"}
            subtitle={"Get more of what you love – Free movies and concession items, invitations to exclusive screenings, special events and more."}
            onBackBtnClick={() => this.yourdetailsBackBtn()}
            renderAsHtml={false}
          />
          <section className="details-blk">
            <h1>Your Details</h1>
            <div className="form-blk">
              <div className="form-grp fname">
                <div className="fm-inner">
                  <input
                    value={`${getUserDetails.firstName} ${getUserDetails.lastName}`}
                    type="text"
                    autoComplete="off"
                    readOnly
                  />
                </div>
              </div>
              {/* <div className="form-grp email">
                <div className="fm-inner">
                  <input type="text" placeholder="Loyalty number" autoComplete="off" />
                </div>
              </div> */}
            </div>
            <div className="btnsWrap flexColumn btn-sec">
              <div className="scan-btn">
                <a onClick={this.proceedBtnClick} href="javascript:;" className="btn btnPrimary" title="YES, PROCEED!">YES, PROCEED!</a>
              </div>
              <div className="scan-btn">
                <a onClick={this.thisIsNotMeBtn} href="javascript::" className="btn btnSecondary" title="NO, THIS IS NOT ME">NO, THIS IS NOT ME</a>
                <p>(Enter Landmark Member details manually)</p>
              </div>
            </div>
            <div className="procced-text">
              <p><span>OR</span> <a onClick={this.guestUser} href="javascript:;" title="PROCEED AS A GUEST">PROCEED AS A GUEST</a></p>
            </div>
          </section>
        </section>
      </section>
    );
  }
}

export default index;
