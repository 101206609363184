import React, { Component } from "react";
// import loaderImg from "../../assets/images/loader.svg";
import { ReactComponent as LoaderImage } from "../../assets/images/loader-ripple.svg";
import "./index.scss";
export class Loader extends Component {
  render() {
    return (
      <div className={`loader ${this.props.loader ? "showLoader" : "d-none"}`}>
        <LoaderImage></LoaderImage>
      </div>
    );
  }
}

export default Loader;
