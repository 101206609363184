import queryString from "query-string";
import React, { Component } from "react";
import "sweetalert2/src/sweetalert2.scss";
import Alert from "../../assets/images/alert-icon.png";
import HomeFooter from "../../assets/images/home-footer.svg";
import MovieInfo from "../../components/MovieInfo/MovieInfo";
import BookingSummary from "../../components/BookingSummary";
import * as API from "../../configuration/apiconfig";
import Header from "../../components/Header";
import {
  calculateHoursAndMinutes,
  formatFilmShowDate,
  formatShowTime,
} from "../../utils/DateTimeFormat";
import "./index.scss";
import { showAlert } from "../../components/showAlert";
import AlertIcon from "../../assets/images/alert-icon.svg";

class index extends Component {
  constructor(props) {
    super(props);
    let pathName = typeof window !== "undefined" ? window.location.search : "";
    const parsedQueryString = queryString.parse(pathName);
    this.state = {
      status: parsedQueryString.status,
      idFromQueryParam: parsedQueryString.id,
      paymentidFromQueryParam: parsedQueryString.paymentid,
      bookingSummary: {},
      filmsData: {
        images: [],
        title: "",
        subtitle: "",
        language: "",
        genres: "",
        rating: "",
        runtime: "",
        screenName: "",
        experiences: "",
        timing: "",
        seats: [],
        showtime: "",
      },
      showdate: "",
      formattedShowtime: "",
      cinemaName: "",
      bookingid: "",
      ticketsSummary: [],
      ticketsDataForTicketSummary: [],
      fnbDataForTicketSummary: [],
      orderType: localStorage.getItem('orderType'),
      pickupNumber: '',
      paymentsTypes: []
    };
  }

  componentDidMount() {
    window.winvar = this;
    this.handleOrderConfirmation();
    localStorage.removeItem("endTimeInSecs");
    localStorage.removeItem("BookingCountdown");
    localStorage.setItem("orderFlowCompleted", "true");
    localStorage.getItem("ageRestrictionValidation") != null && localStorage.removeItem("ageRestrictionValidation");
    this.props.timerInConfirmationPageDecrement();
  }

  handleOrderConfirmation = async () => {
    var orderUrl = "";
    if (this.state.status === "success") {
      orderUrl = `/order/v2/bookings?id=${this.state.idFromQueryParam}&paymentid=${this.state.paymentidFromQueryParam}&status=${this.state.status}`;
    } else {
      orderUrl = `/order/v2/orders?id=${this.state.idFromQueryParam}`;
    }

    const orderRes = await API.callEndpoint("GET", "Bearer", orderUrl)
      .then(
        (response) => {
          return response.data[0];
        }
      ).catch((error) => {
        console.log(error, "error");
        showAlert({
          html: `<p>${error.error}</p>`,
          iconHtml: `<img src=${AlertIcon}>`,
          showConfirmButton: true,
          confirmButtonText: "OK",
          allowEscapeKey: false,
          iconClasses: "borderless",
          onConfirm: () => {
            window.location.href = `/?cinemaid=${localStorage.getItem("cinemaid")}`;
          }
        })
      })
      ;
    console.log(orderRes, "order response");
    if (!orderRes) return;
    this.setState(
      {
        bookingSummary: orderRes,
        filmsData: this.state.orderType == 'ticketing' && orderRes.film && Object.keys(orderRes.film).length > 0 ? {
          title: orderRes.film.films_attributes[0].title,
          subtitle: orderRes.film.films_attributes[0].subtitle,
          language: orderRes.film.films_attributes[0].language,
          genres: orderRes.film.films_genres.flatMap(({ description }) =>
            description.trim() !== "" ? [description.trim()] : []
          ),
          rating: orderRes.film.rating,
          runtime: orderRes.film.runtime,
          screenName: orderRes.session.cinemas_screens[0].name,
          images: orderRes.film.films_images,
          experiences: orderRes.session.sessions_experiences.map((item) => item.name).join(", "),
          seats: orderRes.order_grouping.flatMap(({ seats }) =>
            seats.split(", ")
          ),
          timing: formatFilmShowDate(orderRes.showdate),
          showTime: formatShowTime(orderRes.showtime),
        }:{},
        formattedShowtime: this.state.orderType == 'ticketing' && orderRes.showtime != null && formatShowTime(orderRes.showtime),
        cinemaName: orderRes.cinema.cinemas_attributes[0].name,
        bookingid: orderRes.bookingid,
        pickupNumber: orderRes.pickupNumber
      },
      () => {
        if (this.state.status == "success") {
          this.ticketsSummary();
          this.setTicketsDataForTicketSummary();
          this.setFnbDataForTicketSummary();
          this.setGiftcardForTicketSummary();
        }
      }
    );
  };

  ticketsSummary = async () => {
    let ticketDetails = [];
    let subTotal = 0;
    let taxValue = 0;

    this.state.bookingSummary.order_grouping?.map((ticket) => {
      ticketDetails.push({
        description: ticket.description,
        quantity: ticket.quantity,
        valueBeforeTax: ticket.pricebeforetax,
      });
      subTotal = subTotal + ticket.pricebeforetax;
    });

    const tcktSummaryObj = {
      ticketDetails: ticketDetails,
      // subTotal: subTotal + bookingFeeBeforeTax,
      subTotal: this.state.bookingSummary.valuebeforetax,
      taxValue: this.state.bookingSummary.taxValue,
      grandTotal: this.state.bookingSummary.remainingOrderValue,
      bookingFee: this.state.bookingSummary.bookingfeebeforetax,
    };

    this.setState({ ticketsSummary: [tcktSummaryObj] }, () => {
      console.log(this.state.ticketsSummary);
      this.printTicketsBtnClick(null);
    });
  };

  setTicketsDataForTicketSummary = () => {
    let tempOrderData = [];
    this.state.bookingSummary.order_grouping.map((eachTicket) => {
      if (eachTicket.type.toLowerCase() == "ticket") {
        let eachTaxValue = eachTicket.taxValue / eachTicket.quantity;
        let tempTicketObject = {
          description: eachTicket.description,
          unitprice: eachTicket.unitprice - eachTaxValue,
          qty: eachTicket.quantity,
        };
        tempOrderData.push(tempTicketObject);
      }
    });
    this.setState({
      ticketsDataForTicketSummary: tempOrderData,
    });
  };

  setFnbDataForTicketSummary = () => {
    var fnbDataForTicketSummary = [];
    this.state.bookingSummary.order_grouping.map((eachItem) => {
      if (eachItem.type.toLowerCase() == "fnb") {
        var tempItemObject = {
          description: eachItem.description,
          qty: eachItem.quantity,
          unitprice: eachItem.unitprice - eachItem.taxValue / eachItem.quantity,
          modifiers: [],
          smartModifiers: [],
        };

        eachItem.additionalInfo.modifiers.map((eachModifier) => {
          var tempModifierObject = {
            description: eachModifier.description,
            qty: eachModifier.quantity,
            unitprice: eachModifier.pricebeforetax / eachModifier.quantity,
          };
          tempItemObject.modifiers.push(tempModifierObject);
          tempItemObject.unitprice = tempItemObject.unitprice - eachModifier.pricebeforetax;
        });
        fnbDataForTicketSummary.push(tempItemObject);
      }
    });
    this.setState({
      fnbDataForTicketSummary,
    });
  };

  nextBtnClick = () => {
    console.log("nextbtn click");
  };

  setGiftcardForTicketSummary = () => {
    var tempBookingSummary = this.state.bookingSummary;
    if(Object.keys(tempBookingSummary).length > 0 && tempBookingSummary.bookings_paymenttypes.length > 0){
      this.setState({
        paymentsTypes: tempBookingSummary.bookings_paymenttypes
      })
    }
  }

  calculateTotal = () => {
    var totalTicketPricing = 0,
      totalFnbPricing = 0,
      grandTotal = 0,
      bookingFee = this.state.bookingSummary.bookingfeebeforetax == false ? 0 : this.state.bookingSummary.bookingfeebeforetax,
      tax = this.state.bookingSummary.taxValue ? this.state.bookingSummary.taxValue : 0;
      
    this.state.ticketsDataForTicketSummary.length > 0 &&
      this.state.ticketsDataForTicketSummary.map((eachTicket) => {
        totalTicketPricing += eachTicket.qty * eachTicket.unitprice.toFixed(2);
      });
    grandTotal += totalTicketPricing;
    this.state.fnbDataForTicketSummary.length > 0 &&
      this.state.fnbDataForTicketSummary.map((eachFnb) => {
        var unitprice = eachFnb.unitprice;
        eachFnb.modifiers.length > 0 &&
          eachFnb.modifiers.map((modifier) => {
            var totalModifiePrice = 0;
            totalModifiePrice += modifier.unitprice * modifier.qty;
            unitprice += totalModifiePrice;
          });
        totalFnbPricing += eachFnb.qty * unitprice.toFixed(2);
      });
    grandTotal += totalFnbPricing;
    grandTotal += bookingFee;
    grandTotal += tax;
    return grandTotal.toFixed(2);
  };

  
  setAllPayloads = (getMethods) => {
    var orderDetails = this.state.bookingSummary;
    if(getMethods == 'getdate'){
      // "07/14/2024"
      var getDate = orderDetails.session.showdate;
      var dateSplit = getDate.split("T")[0].split("-");
      var setFormat = dateSplit[1]+'/'+dateSplit[2]+'/'+dateSplit[0];
      return setFormat;
    }else if(getMethods == 'getseats'){
      var setSeatsArray = [];
      if(Object.keys(orderDetails).length > 0){
        orderDetails.order_grouping.map((seatsData) => {
          if(seatsData.type.toLowerCase() == 'ticket'){
            setSeatsArray.push(seatsData.seats);
          }
        })
        var getSeats = setSeatsArray.join(', ');
        return getSeats;
      }
    }else if(getMethods == 'gettickets'){
      // "General X 2~20.00",
      var ticketsData = this.state.ticketsDataForTicketSummary;
      var setTicketArray = [];
      if(ticketsData.length > 0){
        ticketsData.map((tickets) => {
          var setFormat = tickets.description +' X '+ tickets.qty +'~'+ tickets.unitprice.toFixed(2);
          setTicketArray.push(setFormat);
        })
        return setTicketArray;
      }
    }else if(getMethods == 'getfnb'){
      //   "Custom Pizza medium x 1 | -Light Sauce, Extra Cheese, Extra Sausage ~ 16.50",
      //   "Large Popcorn Cheese x 1 ~ 7.50",
      var fnbDataState = this.state.fnbDataForTicketSummary;
      var setFnbsArray = [];
      if(fnbDataState.length > 0){
        fnbDataState.map((fnbData) => {
          var modifierArray = [];
          var setFormat = fnbData.description +' X '+ fnbData.qty;
          var totalUnitprice = fnbData.unitprice;
          var totalModifiePrice = 0;
          if(fnbData.modifiers.length > 0){
            fnbData.modifiers.map((modData) => {
              var modifierFormat = modData.description +'('+ modData.qty +')';
              modifierArray.push(modifierFormat);
              totalModifiePrice += modData.unitprice * modData.qty;
            })
            totalUnitprice += totalModifiePrice;
            // console.log(totalUnitprice, 'totalUnitprice');
            setFormat += ' | '+ modifierArray.join(', ');
            // console.log(modifierArray, 'modifierArray');
          }
          setFormat += ' ~ '+ totalUnitprice.toFixed(2);
          setFnbsArray.push(setFormat);
        })
        return setFnbsArray;
      }
    }else if(getMethods == 'paymetTypes'){
      // "Gift Card Used | 4123 44XX XXXX XX23 ~20.00",
      var setPaymetTypesArray = [];
      var setFormat = '';
      if(Object.keys(orderDetails).length > 0){
        orderDetails.bookings_paymenttypes.map((paymentTypesData) => {
          setFormat = paymentTypesData.pgName +' | ' 
          if(Object.keys(paymentTypesData.cardinformation).length > 0 && paymentTypesData.cardinformation.cardNumber && paymentTypesData.cardinformation.cardNumber != ''){
            setFormat += paymentTypesData.cardinformation.cardNumber +' ~'
          }
          setFormat += paymentTypesData.amount.toFixed(2);
          setPaymetTypesArray.push(setFormat);
        })
      }
      return setPaymetTypesArray;
    }
  }
  
  printTicketPayload = () => {
    if(Object.keys(this.state.bookingSummary).length > 0 && this.state.ticketsSummary.length > 0){
      var orderDetails = this.state.bookingSummary;
      var printdetails = {
        orderid: orderDetails.id,
        cinemaname: orderDetails.cinema && orderDetails.cinema.cinemas_attributes && orderDetails.cinema.cinemas_attributes.length > 0 ? orderDetails.cinema.cinemas_attributes[0].name:'',
        moviename: orderDetails.film && orderDetails.film.films_attributes && orderDetails.film.films_attributes.length > 0 ? orderDetails.film.films_attributes[0].title:'',
        screen: orderDetails.session && orderDetails.session.cinemas_screens && orderDetails.session.cinemas_screens.length > 0 ? orderDetails.session.cinemas_screens[0].name:'',
        showdate: orderDetails.session && orderDetails.session.showdate ? this.setAllPayloads('getdate'):'',
        showtime: orderDetails.session && orderDetails.session.showtime ? formatShowTime(orderDetails.session.showtime):'',
        allseats: this.setAllPayloads('getseats'),
        currency: "$",
        groupingtickets: this.state.ticketsDataForTicketSummary.length > 0 ? this.setAllPayloads('gettickets'):[],
        fnborderno: orderDetails.pickupNumber ? orderDetails.pickupNumber:'',
        fnbitems: this.state.fnbDataForTicketSummary.length > 0 ? this.setAllPayloads('getfnb'):[],
        subtotal: this.state.ticketsSummary[0].subTotal,
        taxheading: "",
        totaltax: this.state.ticketsSummary[0].taxValue,
        grandtotal: this.calculateTotal(),
        paymentmethods: this.setAllPayloads('paymetTypes'),
        transactionno: orderDetails.ticketingTransactionId,
        qrcodeurl: orderDetails.qrcodeurl,
        rating: orderDetails.film && orderDetails.film.rating != null ? orderDetails.film.rating:''
      };
      console.log('Print Details Object', printdetails);
      return JSON.stringify(printdetails);
    }
  }

  printTicketsBtnClick = (btn) => {
    var getFlows = '';
    if(this.state.ticketsDataForTicketSummary.length > 0 && this.state.fnbDataForTicketSummary.length == 0){
      getFlows = 'TICKETS_ONLY';
    }else if(this.state.ticketsDataForTicketSummary.length == 0 && this.state.fnbDataForTicketSummary.length > 0){
      getFlows = 'FNB_ONLY';
    }else if(this.state.ticketsDataForTicketSummary.length > 0 && this.state.fnbDataForTicketSummary.length > 0){
      getFlows = 'TICKETS_WITH_FNB';
    }
    console.log('callPrint > printtype', getFlows);
    console.log('callPrint > orderdetails', this.printTicketPayload());
    window.callbackObj.callPrint(this.printTicketPayload(), getFlows);

    if(btn == 'printBtn'){
      var getPrintBtnElem = document.getElementById('print-btn');
      if(getPrintBtnElem){
        getPrintBtnElem.style.display = 'none';
      }
    }
  }

  render() {
    return (
      <section className={`confirmation-summary ${this.state.orderType == 'fnb' ? 'confirmation-fnb-flow':''}`}>
        <section
          className={`${this.state.status === "success"
            ? "success-container"
            : "failure-container"
            }`}
        >
          <Header logo={true} homeIcon={true} timer={false} />
          <MovieInfo
            filmsData={this.state.filmsData}
            orderStatus={this.state.status}
            isConfirmationPageBanner={true}
            hideMovieBg={this.state.orderType == 'ticketing' ? false:true}
          />
          <section
            className={`${this.state.status === "success" ? "success-wrap" : "failure-wrap"
              }`}
          >
            {this.state.status === "success" && (
              <div className="booking-wrap">
                {
                  this.state.orderType == 'ticketing' ?
                  <div className="booking-id">
                    Booking ID:<span>{this.state.bookingid}</span>
                  </div>
                  :
                  <>
                    <div className="booking-id">
                      Pickup No: <span>#{this.state.pickupNumber}</span>
                    </div>
                    <div className="food-counter"><p>Please view the display at the food counter</p></div>
                  </>
                }
                
              </div>
            )}
            {
              (this.state.filmsData && Object.keys(this.state.filmsData).length > 0 && this.state.filmsData.seats.length > 0) && (
                <MovieInfo
                  filmsData={this.state.filmsData}
                  selectedSeats={this.state.bookingSummary?.orders_items?.length > 0 ? this.state.bookingSummary.orders_items : this.state.bookingSummary.order_grouping}
                  isSeatLayoutPage={true}
                  hideMovieBg={true}
                  isConfirmationPageBanner={false}
                />
              )
            }
            {this.state.status === "failure" && (
              <div className="transaction-fail">
                <img src={Alert} alt="Alert Icon" />
                <p>
                  Your transaction could not be completed with details provided.
                </p>
                <p>Please approach a member of our staff at the box office.</p>
              </div>
            )}
            {this.state.status === "success" && (
              <div className="bookingSummary">
                <p className="bookingSummaryHeading">Booking Summary</p>
                <BookingSummary
                  ticketsDataForTicketSummary={this.state.ticketsDataForTicketSummary}
                  fnbDataForTicketSummary={this.state.fnbDataForTicketSummary}
                  incDecItem={false}
                  nextBtnClick={this.nextBtnClick}
                  nextBtnText={""}
                  bookingFee={this.state.bookingSummary.bookingfeebeforetax}
                  tax={this.state.bookingSummary.taxValue}
                  paymentsTypes={this.state.paymentsTypes}
                ></BookingSummary>
              </div>
            )}
            
            {/* P1 - Remove the Reprint option */}
            {/*{
            this.state.status === "success" && (
              <div className="print-blk" id="print-btn">
                <a onClick={() => this.printTicketsBtnClick('printBtn')} href="javascript:;" className="btn btnPrimary" title="PRINT TICKETS">PRINT TICKETS</a>
              </div>)
            } */}

            <div className="home-wrap">
              <a href={`/?cinemaid=${localStorage.getItem("cinemaid")}`} className="home-link-wrap">
                <img src={HomeFooter} alt="Home Logo" className="home-logo" />
                <span className="home-link">GO HOME</span>
              </a>
            </div>
          </section>
        </section>
      </section>
    );
  }
}

export default index;
