import { useState, useEffect } from "react";
// import "./index.scss"
// import config from "../../configuration/config";
// import { console.log } from "../Notifications";
import "../styles/common.scss"
import Skeleton from "react-loading-skeleton";
import infoIcon from "../assets/images/info-icon.svg";
import { showAlert } from "./showAlert";
import * as API from "../configuration/apiconfig";
import { orderExpiredRedirect } from '../utils/AlertHandling';
import { Modal } from "react-bootstrap";
import { RadialProgress } from 'react-radial-progress-indicator';

const defaultRemainingtime = {
    minutes: "__",
    seconds: "__"
};

const bookingCountdownTimer = process.env.REACT_APP_TIMERCOUNTER || 420;
// const bookingCountdownTimer = 30; // for testing



const CountdownTimer = ({ orderId, orderData, hideGCModalBookingTimerElapse }) => {
    const [remainingTime, setRemainingTime] = useState(defaultRemainingtime);
    const [bookingTimerModalSH, setBookingTimerModalSH] = useState(false);
    
    
    useEffect(() => {
        checkBookingCountdownTimer();
        window.addEventListener("beforeunload", resetMultiCount);
        return () => {
            window.removeEventListener("beforeunload", resetMultiCount);
        };
    }, [])

    const resetMultiCount = () => {
        localStorage.getItem("clearMultiCount") !== null && localStorage.removeItem("clearMultiCount");
    };

    const checkBookingCountdownTimer = () => {
        var counter = "";
        counter = localStorage.getItem("BookingCountdown");

        if (counter == null) {
            var timerInSeconds = parseInt(bookingCountdownTimer);
            setBookingCountdownTimer(timerInSeconds);
            var endTimeInSecs = new Date().getTime() / 1000;
            localStorage.setItem("endTimeInSecs", endTimeInSecs + timerInSeconds);
            console.log("new end time set", endTimeInSecs);
        } else {
            getBookingCountdownTimer();
        }
    };

    const setBookingCountdownTimer = (timerSeconds) => {
        var counter = timerSeconds;

        if (localStorage.getItem("clearMultiCount") === null) {
            localStorage.setItem("clearMultiCount", "true");
        }

        var interval = setInterval(function () {
            if (counter < 0) {
                counter = 0;
            }
            if (counter == 0) {
                clearInterval(interval);
                setBookingTimerModalSH(true);
                hideGCModalBookingTimerElapse && hideGCModalBookingTimerElapse();
            }

            if (localStorage.getItem("orderId") === null) {
                clearInterval(interval);
                localStorage.getItem("endTimeInSecs") !== null && localStorage.removeItem("endTimeInSecs");
                localStorage.getItem("BookingCountdown") !== null && localStorage.removeItem("BookingCountdown");
                localStorage.getItem("clearMultiCount") !== null && localStorage.removeItem("clearMultiCount");
                localStorage.getItem("userMMR") !== null && localStorage.removeItem("userMMR");
            }

            var endTimeInSecs = localStorage.getItem("endTimeInSecs");
            var currTimeInSecs = new Date().getTime() / 1000;
            if (endTimeInSecs && currTimeInSecs) {
                var counterlocal = eval(endTimeInSecs).toFixed(2) - eval(currTimeInSecs).toFixed(2);
                counter = eval(counterlocal).toFixed(2);
                var minutes = parseInt(eval(counter).toFixed(0) / 60) % 60;
                var seconds = parseInt(eval(counter).toFixed(0)) % 60;
            }

            var timerMins = minutes > 0 ? (minutes < 10 ? "0" + minutes : minutes) : "00";
            var timerSecs = seconds > 0 ? (seconds < 10 ? "0" + seconds : seconds) : "00";

            setRemainingTime({
                minutes: timerMins,
                seconds: timerSecs
            })

            if (localStorage.getItem("orderId") !== null) {
                if (counter >= 0) localStorage.setItem("BookingCountdown", counter);
            }
            counter--;
        }, 1000);
    };

    const getBookingCountdownTimer = () => {
        var counter = parseInt(localStorage.getItem("BookingTimer"));

        if (localStorage.getItem("clearMultiCount") === null) {
            localStorage.setItem("clearMultiCount", "true");
        } else {
            localStorage.setItem("clearMultiCount", "false");
        }

        let clearTime = localStorage.getItem("clearMultiCount");

        var interval = setInterval(function () {
            if (counter < 0) {
                counter = 0;
            }
            if (counter == 0) {
                clearInterval(interval);
                if (clearTime == "true") {
                    setBookingTimerModalSH(true);
                    hideGCModalBookingTimerElapse && hideGCModalBookingTimerElapse();
                }
            }

            if (localStorage.getItem("orderId") === null) {
                clearInterval(interval);
                localStorage.getItem("endTimeInSecs") !== null && localStorage.removeItem("endTimeInSecs");
                localStorage.getItem("BookingCountdown") !== null && localStorage.removeItem("BookingCountdown");
                localStorage.getItem("clearMultiCount") !== null && localStorage.removeItem("clearMultiCount");
                localStorage.getItem("userMMR") !== null && localStorage.removeItem("userMMR");
            }

            var endTimeInSecs = localStorage.getItem("endTimeInSecs");
            var currTimeInSecs = new Date().getTime() / 1000;
            if (endTimeInSecs && currTimeInSecs) {
                var counterlocal = eval(endTimeInSecs).toFixed(2) - eval(currTimeInSecs).toFixed(2);
                counter = eval(counterlocal).toFixed(2);
                var minutes = parseInt(eval(counter).toFixed(0) / 60) % 60;
                var seconds = parseInt(eval(counter).toFixed(0)) % 60;
            }

            var timerMins = minutes > 0 ? (minutes < 10 ? "0" + minutes : minutes) : "00";
            var timerSecs = seconds > 0 ? (seconds < 10 ? "0" + seconds : seconds) : "00";

            setRemainingTime({
                minutes: timerMins,
                seconds: timerSecs
            })

            if (localStorage.getItem("orderId") !== null) {
                if (counter >= 0) localStorage.setItem("BookingCountdown", counter);
            }
            counter--;
        }, 1000);
    };

    const bookingTimerOkBtnOnclick = () => {
        setBookingTimerModalSH(false);
        deleteOrder();
    }

    const deleteOrder = async () => {
        var orderDetails = JSON.parse(localStorage.getItem("orderId"));
        console.log(orderDetails, "orderDetails");
        if (orderDetails?.id) {
            await API.callEndpoint("DELETE", "Bearer", `/order/v2/orders/${orderDetails.id}`)
                .then((response) => {
                    var getMovieName = localStorage.getItem("selectedmoviename");
                    // // // // // var redirectURL = `/showtime?title=${getMovieName}`;
                    let orderType = localStorage.getItem('orderType');
                    var redirectURL = (orderType == "ticketing") ? `/showtime?title=${getMovieName}` : `/login`;
                    orderExpiredRedirect("session expired", redirectURL);
                })
                .catch((ex) => {
                    console.log(ex.error);
                }).finally(() => {
                    localStorage.getItem("endTimeInSecs") !== null && localStorage.removeItem("endTimeInSecs");
                    localStorage.getItem("BookingCountdown") !== null && localStorage.removeItem("BookingCountdown");
                    localStorage.getItem("clearMultiCount") !== null && localStorage.removeItem("clearMultiCount");
                    localStorage.getItem("selectedSeats") !== null && localStorage.removeItem("selectedSeats");
                    localStorage.getItem("orderId") !== null && localStorage.removeItem("orderId");
                });
        } else {
            localStorage.getItem("endTimeInSecs") !== null && localStorage.removeItem("endTimeInSecs");
            localStorage.getItem("BookingCountdown") !== null && localStorage.removeItem("BookingCountdown");
            localStorage.getItem("clearMultiCount") !== null && localStorage.removeItem("clearMultiCount");
            localStorage.getItem("selectedSeats") !== null && localStorage.removeItem("selectedSeats");
            localStorage.getItem("orderId") !== null && localStorage.removeItem("orderId");
            var getMovieName = localStorage.getItem("selectedmoviename");
            // // // // // var redirectURL = `/showtime?title=${getMovieName}`;
            let orderType = localStorage.getItem('orderType');
            var redirectURL = (orderType == "ticketing") ? `/showtime?title=${getMovieName}` : `/login`;
            orderExpiredRedirect("session expired", redirectURL);
        }
    }

    const getProgressBarCounter = (getCounter) => {
        if(getCounter <= 1){
            localStorage.getItem("endTimeInSecs") !== null && localStorage.removeItem("endTimeInSecs");
            localStorage.getItem("BookingCountdown") !== null && localStorage.removeItem("BookingCountdown");
            localStorage.getItem("clearMultiCount") !== null && localStorage.removeItem("clearMultiCount");
            localStorage.getItem("selectedSeats") !== null && localStorage.removeItem("selectedSeats");
            var getMovieName = localStorage.getItem("selectedmoviename");
            localStorage.getItem("cartData") != null && localStorage.removeItem("cartData");
            document.cookie = "refreshToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            document.cookie = "accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            document.cookie = "firstname=;max-age=0";
            document.cookie = "lastname=;max-age=0";
            let orderType = localStorage.getItem('orderType');
            var redirectURL = (orderType == "ticketing") ? `/showtime?title=${getMovieName}` : `/login`;
            window.location.href = redirectURL;
        }
    }

    return (
        <>
            <div className="countdown-timer">
                {/* {orderData ? (
                    <p>Session expires in: <span>{remainingTime.minutes} min {remainingTime.seconds} sec</span></p>
                ) : (
                    <Skeleton width={300} height={30} />
                )} */}
                <aside className="timer-blk" id="timer-sec">
                    <span className="time-txt">Time left</span>
                    <aside id="timer-counter" className="timer-count">{remainingTime.minutes}:{remainingTime.seconds}</aside>
                </aside>
            </div>

            {/* Booking timer > progressbar > modal */}
            <Modal show={bookingTimerModalSH} dialogClassName="booking-timer-popup" centered>
                <Modal.Body>
                    <div className="content">
                        <img src={infoIcon} alt="Info" />
                        <p>Your booking session was timed out. Please start again.</p>
                    </div>
                    <div className="progress-sec">
                        <h2>You are automatically redirecting to showtimes page in</h2>
                        <RadialProgress
                            backgroundTransparent
                            duration={10000}
                            fontRatio={5}
                            height={50}
                            width={50}
                            ringBgColour="#f1f1f1"
                            ringFgColour="#ffc216"
                            ringIntermediateColour="#f1f1f1"
                            ringThickness={0.3}
                            segmented
                            // showIntermediateProgress
                            startStep={10}
                            step={0}
                            steps={10}
                            text={function text(steps,percentage){
                                getProgressBarCounter(Math.floor(steps*percentage));
                                return Math.floor(steps*percentage)
                            }}
                        />
                    </div>
                    <div className="ok-btn">
                        <a onClick={bookingTimerOkBtnOnclick} href="javascript:;" className="btn btnPrimary" title="OK">OK</a>
                    </div>
                </Modal.Body>
            </Modal>
            {/* Booking timer > progressbar > modal */}
        </>
    )
}

export default CountdownTimer;