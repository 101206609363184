import React from 'react'
import Modal from 'react-bootstrap/Modal';
import "./index.scss"

const CustomModal = ({ show, handleClose, customClasses, icon, title, descriptionsList, showConfirmBtn, confirmBtnText, showCancelBtn, cancelBtnText, onConfirm, onCancel, iconWidth, iconHeight }) => {

    const renderButtons = () => {
        // console.log(showConfirmBtn, showCancelBtn);
        if (showConfirmBtn === true && showCancelBtn === true) {
            // console.log("show both buttons");
            return (
                <div className="btnsWrap flexRow">
                    <button onClick={onCancel} className='btn btnSecondary'>{cancelBtnText}</button>
                    <button onClick={onConfirm} className='btn btnPrimary'>{confirmBtnText}</button>
                </div>
            )
        } else if (showConfirmBtn) {
            return (
                <button onClick={onConfirm} className='btn btnPrimary'>{confirmBtnText}</button>
            )
        } else if (showCancelBtn) {
            return (
                <button onClick={onCancel} className='btn btnSecondary'>{cancelBtnText}</button>
            )
        }
    }

    return (
        <Modal
            size="lg"
            centered
            show={show}
            onHide={handleClose}
            className={`custom-modal ${customClasses}`}
        >
            <Modal.Body>
                <button className='btn-close' onClick={handleClose}></button>
                {icon &&
                    <span className="icon-container" style={{ width: iconWidth, height: iconHeight }}>
                        {icon}
                    </span>
                }
                <h4 className='title'>{title}</h4>
                {
                    descriptionsList && descriptionsList.length > 0 &&
                    descriptionsList.map((description, index) => {
                        return (
                            <p key={index} className='description'>{description}</p>
                        )
                    })
                }
                {/* <p className='description'>{description}</p> */}
                {renderButtons()}
            </Modal.Body>
        </Modal>
    )
}

export default CustomModal