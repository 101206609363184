import { Component } from "react";
import "./index.scss";
import { BrowserRouter as Router, Routes, Link, Route } from "react-router-dom";
import siteLogo from "../../assets/images/logo.svg";
import IconHome from "../../assets/images/icons/iconHome.svg";
import CountdownTimer from "../CustomCounter";

class Header extends Component {
  render() {
    var getCinemaIdFromLocalstorage = localStorage.getItem('cinemaid');
    return (
      <>
        <header className="innerPageHeader">
          {
            this.props.logo &&
            <aside className="siteLogo">
              <a href="javascript:;">
                <img src={siteLogo} alt="Landmark Cinemas" className="img-fluid" />
              </a>
            </aside>
          }
          {
            this.props.homeIcon &&
            <aside className="rightColumn">
              <Link to={`/?cinemaid=${getCinemaIdFromLocalstorage}`} className="homeButton" title="Go Home">
                <img src={IconHome} alt="IconHome" className="img-fluid" />
                <span>Go Home</span>
              </Link>
            </aside>
          }
          {
            this.props.timer &&
            <CountdownTimer 
              hideGCModalBookingTimerElapse={this.props.hideGCModalBookingTimerElapse}
            />
            // <aside className="timer-blk" id="timer-sec">
            //   <span className="time-txt">Time left</span>
            //   <aside id="timer-counter" className="timer-count">__:__</aside>
            // </aside>
          }
        </header>
      </>
    );
  }
}

export default Header;
