import { Component } from "react";
import "./TicketSummary.scss";

class TicketSummary extends Component {
  render() {
    const { ticketDetails, subTotal, bookingFee, taxValue, grandTotal } =
      this.props.ticketsSummary[0] || {};
    const { hasVoucher } = this.props;
    const ticketSummaryClass =
      window.location.pathname === "/payment"
        ? "payment-ticket-summary"
        : window.location.pathname === "/confirmation"
        ? "success-ticket-summary"
        : "";
    const isPaymentPage = window.location.pathname === "/payment";
    const isSeatPage = window.location.pathname === "/seatlayout";

    return (
      <>
        {this.props.ticketsSummary.length > 0 ? (
          <section className={`ticket-summary ${ticketSummaryClass}`}>
            <h3 className="summary-title">Ticket Summary</h3>
            <h4 className="ticket-type">Family Tickets</h4>
            <div className="ticket-data-blk">
              {ticketDetails.length > 0
                ? ticketDetails.map((item) => {
                    return (
                      <div className="tick-info">
                        <span className="seat-type">{item.description}</span>
                        <span className="seat-count">
                          <span className="count">60 (SAR)</span>
                          <span className="multiply">x</span>
                          <span className="ticket-qty">{item.qty}</span>
                        </span>
                        <span className="price-txt">
                          {item.valueBeforeTax.toFixed(2)}
                        </span>
                      </div>
                    );
                  })
                : ""}
            </div>
            {/* <div className="ticket-data-blk">
        <div className="tick-info">
          <span className="seat-type">Family Adult - Std</span>
          <span className="seat-count">
            <span className="count">50 (SAR)</span>
            <span className="multiply">x</span>
            <span className="ticket-qty">2</span>
          </span>
          <span className="price-txt">100.00</span>
        </div>
        <div className="tick-info">
          <span className="seat-type">Family Adult - Premium</span>
          <span className="seat-count">
            <span className="count">06 (SAR)</span>
            <span className="multiply">x</span>
            <span className="ticket-qty">2</span>
          </span>
          <span className="price-txt">120.00</span>
        </div>
      </div> */}
            {/* <div className="has-3d-blk">
              <div className="tick-info tickt-type-wrap">
                <span className="seat-type">3D Glass</span>
                {isPaymentPage ? (
                  <span className="seat-count">
                    <span className="count">3 (SAR)</span>
                    <span className="multiply">x</span>
                    <span className="ticket-qty">2</span>
                  </span>
                ) : (
                  <span className="seat-count">
                    <img src={Minus} alt="Minus" className="counter-img" />
                    <span className="count-change">2</span>
                    <img src={Plus} alt="Add" className="counter-img" />
                  </span>
                )}

                <span className="price-txt">6.00</span>
              </div>
            </div> */}
            
              {/* <div className="tick-info buy-collct-msg">
                <span className="seat-type">
                  (Buy now and collect at the door)
                </span>
                <span className="seat-count">SAR 3.00</span>
                <span className="price-txt"></span>
              </div> */}
            {hasVoucher && (
              <div className="voucher-blk">
                <div className="info-dec">
                  <span className="info-txt">
                    Voucher <span className="vat-per">12001325434</span>
                  </span>
                  <span className="price-txt">-20.00</span>
                </div>
                <div className="info-dec">
                  <span className="info-txt">
                    Voucher <span className="vat-per">54618711130</span>
                  </span>
                  <span className="price-txt">-40.00</span>
                </div>
              </div>
            )}
            <div className="payment-blk">
              {hasVoucher && (
                <div className="info-dec voucher-amount">
                  <span className="info-txt font-bold">Voucher Discount</span>
                  <span className="price-txt">22.00</span>
                </div>
              )}
              <div className="info-dec">
                <span className="info-txt font-bold">subTotal</span>
                <span className="price-txt">{subTotal.toFixed(2)}</span>
              </div>
              <div className="info-dec">
                <span className="info-txt font-bold">Tax</span>
                <span className="price-txt">{taxValue.toFixed(2)}</span>
              </div>

              {!isSeatPage && (
                <div className="info-dec vat">
                  <span className="info-txt font-bold">
                    VAT <span className="vat-per">(10%)</span>
                  </span>
                  <span className="price-txt">00.00</span>
                </div>
              )}
              {!isSeatPage && (
                <div className="info-dec bookingFee">
                  <span className="info-txt font-bold">
                    Booking Fee <span>(SAR)</span>
                  </span>
                  <span className="price-txt">{bookingFee}</span>
                </div>
              )}
            </div>
            {!isPaymentPage && (
              <div className="total-info-dec total-amout-blk">
                <span className="info-txt font-bold">
                  Total Cost <span>(SAR)</span>
                </span>
                <span className="price-txt">{grandTotal.toFixed(2)}</span>
              </div>
            )}
          </section>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default TicketSummary;
