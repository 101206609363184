export const craeteFnBPayloadForOrdersEndpoint = (cart) => {
  const getModifiers = (modifierGroups) => {
    let modifiers = [];
    modifierGroups.forEach((modGrp) => {
      modGrp.modifier_items.forEach((modItem) => {
        if (modItem.selectedQty > 0) {
          modifiers.push({
            id: modItem.id,
            quantity: modItem.selectedQty,
            tabid: modGrp.tabid,
            discountItem: modItem.discountItem
          });
        }
      });
    });
    return modifiers;
  };

  let fnbOrderData = [];
  cart.map((eachFnb) => {
    var eachFnbObject;
    if (eachFnb.fnbs_alternateitems.length == 0) {
      eachFnbObject = {
        inSeatDelivery: eachFnb.inSeatDelivery,
        offerItems: false,
        id: eachFnb.id,
        quantity: eachFnb.selectedQty,
        tabId: eachFnb.tabId,
        fnbid: eachFnb.fnbid,
        modifiers: getModifiers(eachFnb.modifierGroups),
        smartModifiers: eachFnb.smartModifiers,
        promotionid: null,
        specialinstructions: "",
        variablepriceincents: null,
        type: "concession",
        suggestion: {},
        discountItem: eachFnb.discountItem,
        disableOfferItems: true,
      };
      fnbOrderData.push(eachFnbObject);
    } else {
      eachFnb.fnbs_alternateitems.map((eachAlternateItem) => {
        if (eachAlternateItem.selectedQty != 0) {
          var tempEachFnbObject = {
            inSeatDelivery: eachAlternateItem.inSeatDelivery,
            offerItems: false,
            id: eachAlternateItem.id,
            quantity: eachFnb.selectedQty,
            tabId: eachAlternateItem.tabId,
            fnbid: eachAlternateItem.fnbid,
            modifiers: getModifiers(eachAlternateItem.modifierGroups),
            smartModifiers: eachAlternateItem.smartModifiers,
            promotionid: null,
            specialinstructions: "",
            variablepriceincents: null,
            type: "concession",
            suggestion: {},
            discountItem: eachAlternateItem.discountItem,
            disableOfferItems: true,
          };
          fnbOrderData.push(tempEachFnbObject);
        }
      });
    }
  });
  return fnbOrderData;
};
