import React from "react";
import "./index.scss";
import { ReactComponent as SelectSeatsIcon } from "../../assets/images/seat-icons/seat-type-icon.svg";
import { ReactComponent as FnbGreyIcon } from "../../assets/images/fnb-grey-icon.svg";
import { ReactComponent as FnbIcon } from "../../assets/images/fnb-icon.svg";
import { ReactComponent as PaymentGreyIcon } from "../../assets/images/payment-grey-icon.svg";
import { ReactComponent as PaymentIcon } from "../../assets/images/payment-icon.svg";

const BookingFlow = ({ currentStep }) => {
  const steps = [
    {
      id: 1,
      name: "Select Seats",
      icon: <SelectSeatsIcon />,
      activeIcon: <SelectSeatsIcon />,
    },
    {
      id: 2,
      name: "Food and Drinks",
      icon: <FnbGreyIcon />,
      activeIcon: <FnbIcon />,
    },
    {
      id: 3,
      name: "Make Payment",
      icon: <PaymentGreyIcon />,
      activeIcon: <PaymentIcon />,
    },
  ];

  return (
    <div className="booking-flow">
      {steps.map((step, index) => {
        return (
          <div key={index} className={`step ${currentStep >= step.id ? "active" : ""}`}>
            <span className="name">
              <span className="step-number">{step.id}</span>
              {step.name}
            </span>
            <span className="icon">{currentStep >= step.id ? step.activeIcon : step.icon}</span>
          </div>
        );
      })}
    </div>
  );
};

export default BookingFlow;
