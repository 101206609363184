import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import "./index.scss";
import { ReactComponent as AgeRestrictionIcon } from "../Images/ageRestrictionIcon.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { keyboardLayout } from "../../../utils/keyBoardLayout"

export default class AgeRestrictionModal extends Component {
  constructor() {
    super();
    this.state = {
      month: "",
      date: "",
      year: "",
      okButtonDisable: true,
      layoutName: "ip",
      inputName: "month",
      keyboardMaxLength: 2
    };
  }

  updateValue = (value, field) => {
    if (field == "month" && value > 12) {
      value = "12";
    }
    if (field == "date" && value > 31) {
      value = "31";
    }
    if (field == "year" && value > new Date().getFullYear()) {
      value = "" + new Date().getFullYear() + "";
    }
    var reg = /^\d+$/; //regex to allow only numbers
    if (reg.test(value) || value.trim().length == 0) {
      this.setState(
        {
          [field]: value,
        },
        () => {
          if (this.state.month.length >= 1 && this.state.date.length >= 1 && this.state.year.length >= 4) {
            var birthDate = this.state.year + "/" + this.state.month + "/" + this.state.date;
            var age = this.getAge(birthDate);
            this.setState({
              okButtonDisable: age >= process.env.REACT_APP_LEGAL_DRINKING_AGE ? false : true,
            });
          } else {
            this.setState({
              okButtonDisable: true,
            });
          }
        }
      );
    }
  };

  getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  closeAgeRestrictionPopup = (clearSelectedItem) => {
    this.props.closeAgeRestrictionPopup(clearSelectedItem, this.state.okButtonDisable);
    this.setState({
      month: "",
      date: "",
      year: "",
      okButtonDisable: true,
      inputName: "month",
    });
  };

  closeBtnAgeRestrictionPopup = () => {
    this.props.closeBtnAgeRestrictionPopup();
    this.setState({
      month: "",
      date: "",
      year: "",
      okButtonDisable: true,
      inputName: "month",
    });
  }

  ageRestrcitionContent = () => {
    return (
      <>
        <span className="modal-close-button">
          <span>
            <AiOutlineClose
              className="modalCloseButton"
              onClick={() => {
                this.closeBtnAgeRestrictionPopup();
              }}
            />
          </span>
        </span>
        <div className="contentDiv">
          <div className="headerDiv">
            <AgeRestrictionIcon className="ageRestrictionIcon"></AgeRestrictionIcon>
            <p className="heading">You must be of Legal Drinking Age</p>
          </div>
          <div className="dobDiv">
            <p className="heading">Enter Your Date of Birth</p>
            <div className="dobFields">
              <div className="dobFieldDiv">
                <p className="inputFieldlabel">Month</p>
                <input 
                  onFocus={() => this.inputOnFocus("month")}
                  id="month" 
                  type="text" 
                  className={`form-control ${this.state.inputName == 'month' ? 'onfocus':''}`} 
                  placeholder="MM" 
                  maxLength="2"
                  value={this.state.month} 
                />
              </div>
              <div className="dobFieldDiv">
                <p className="inputFieldlabel">Date</p>
                <input 
                  onFocus={() => this.inputOnFocus("date")}
                  id="date"
                  type="text"
                  className={`form-control ${this.state.inputName == 'date' ? 'onfocus':''}`}
                  placeholder="DD" 
                  maxLength="2"
                  value={this.state.date}
                />
              </div>
              <div className="dobFieldDiv">
                <p className="inputFieldlabel">Year</p>
                <input 
                  onFocus={() => this.inputOnFocus("year")}
                  id="year"
                  type="text"
                  className={`form-control ${this.state.inputName == 'year' ? 'onfocus':''}`}
                  placeholder="YYYY" 
                  maxLength="4"
                  value={this.state.year} 
                />
                </div>
            </div>
          </div>
          <div className="footerDiv">
            <p className="heading">You will need to produce a valid Government-issued identification card at the time of pick-up of the items, to support the date of birth entered.</p>
          </div>
        </div>
        <button type="button" className={`okButton ${this.state.okButtonDisable ? "disabled" : ""}`} onClick={() => this.closeAgeRestrictionPopup(false)}>
          OK
        </button>
      </>
    );
  };

  keyBoardOnChange = (input) => {
    // console.log("Input changed", input);
    var getInputName = this.state.inputName;
    if(getInputName == 'month'){
      this.updateValue(input, "month");
    }else if(getInputName == 'date'){
      this.updateValue(input, "date");
    }else if(getInputName == 'year'){
      this.updateValue(input, "year");
    }
  };

  
  onChangeAll = (inputs) => {
    console.log("Inputs changed", inputs);
    var getMonth = inputs.month;
    var getDate = inputs.date;
    var getYear = inputs.year;

    // Next inputs field moved set condition
    if(getMonth && getMonth.length == 2 && this.state.inputName == 'month'){
      this.setState({
        inputName: 'date',
        keyboardMaxLength: 2
      }, () => {
        setTimeout(() => {
          document.getElementById('date').focus();
          var dateLength = this.state.date.length;
          dateLength > 0 && document.getElementById('date').setSelectionRange(dateLength, dateLength)
        }, 100);
      })
    }
    if(getDate && getDate.length == 2 && this.state.inputName == 'date'){
      this.setState({
        inputName: 'year',
        keyboardMaxLength: 4
      }, () => {
        setTimeout(() => {
          document.getElementById('year').focus();
          var yearLength = this.state.year.length;
          yearLength > 0 && document.getElementById('year').setSelectionRange(yearLength, yearLength)
        }, 100);
      })
    }

    setTimeout(() => {
      if((getMonth && getMonth.length == 2) && (getDate && getDate.length == 2) && (getYear && getYear.length == 4)){
        var disableElem = document.querySelectorAll('.age-restriction-keyboard .hg-rows .hg-row .hg-standardBtn');
        disableElem.forEach((items) => {
          items.classList.add('disabled');
        })
      }else{
        var disableElem = document.querySelectorAll('.age-restriction-keyboard .hg-rows .hg-row .hg-standardBtn');
        disableElem.forEach((items) => {
          items.classList.remove('disabled');
        })
      }  
    }, 0);
    

    // SetState functioanlity > month, date, year
    if(getMonth != undefined){
      this.setState({
        month: getMonth
      })
    }
    if(getDate != undefined){
      this.setState({
        date: getDate
      })
    }
    if(getYear != undefined){
      this.setState({
        year: getYear
      })
    }
  }

  inputOnFocus = (inputField) => {
    setTimeout(() => {
      if((this.state.month.length == 2) && (this.state.date.length == 2) && (this.state.year.length == 4)){
        var disableElem = document.querySelectorAll('.age-restriction-keyboard .hg-rows .hg-row .hg-standardBtn');
        disableElem.forEach((items) => {
          items.classList.add('disabled');
        })
      }else{
        var disableElem = document.querySelectorAll('.age-restriction-keyboard .hg-rows .hg-row .hg-standardBtn');
        disableElem.forEach((items) => {
          items.classList.remove('disabled');
        })
      }    
    }, 0);

    this.setState({
      inputName: inputField,
    }, () => {
      if(inputField == 'month' || inputField == 'date'){
        this.setState({
          keyboardMaxLength: 2
        })
      }
      if(inputField == 'year'){
        this.setState({
          keyboardMaxLength: 4
        })
      }
    })
  }
  

  render() {
    const { showAgeRestrictionModal } = this.props;
    return (
      <>
        {this.props.windowWidth < 768 ? (
          <Offcanvas show={showAgeRestrictionModal} onHide={() => this.closeAgeRestrictionPopup(true)} className="ageRestrictionPopup" placement={"bottom"}>
            <Offcanvas.Body>{this.ageRestrcitionContent()}</Offcanvas.Body>
          </Offcanvas>
        ) : (
          <Modal show={showAgeRestrictionModal} dialogClassName="ageRestrictionPopup" centered>
            <Modal.Body>{this.ageRestrcitionContent()}</Modal.Body>
          </Modal>
        )}
        {
          showAgeRestrictionModal && 
          <Keyboard
            keyboardRef={r => (this.keyboard = r)}
            layoutName={this.state.layoutName}
            onChangeAll={this.onChangeAll}
            inputName={this.state.inputName}
            onChange={this.keyBoardOnChange}
            layout={keyboardLayout}
            display={{
              "{bksp}": "Backspace",
            }}
            disableRowButtonContainers={true}
            baseClass={"age-restriction-keyboard"}
            maxLength={this.state.keyboardMaxLength}
          />
          
        }
        
      </>
      
    );
  }
}
