import React, { Component } from "react";
import { filterImageBasedOnImageCode } from "../../../utils/FilterImages";
import "./index.scss";

export default class FnbItemCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: props.item,
      itemIndex: props.itemIndex,
      tabId: props.tabId,
    };
  }

  getLowestPriceFromAlternateItems = (alternateItems) => {
    var allPricesArray = [];
    alternateItems.map((alternateItem) => {
      var altItemPrice = alternateItem.strikeValueBeforeTax == null ? alternateItem.valuebeforetax : alternateItem.strikeValueBeforeTax;
      allPricesArray.push(altItemPrice);
    });
    const min = Math.min(...allPricesArray);
    return min.toFixed(2);
  };

  render() {
    let { item, itemIndex, tabId } = this.state;
    return (
      <div key={itemIndex} className={`fnb-item-card ${item.active && item.show ? "" : "unavailableItem"}`} style={{ order: this.props.sort }}>
        <div className="item-image-container">
          <img src={filterImageBasedOnImageCode(item.fnbs_images, "x325y185")} alt={item.name} className="item-image" />
        </div>
        <div className="item-details">
          <div>
            <h3>{item.itemName}</h3>
            {item.itemDetails.length != 0 ? <p className="itemDetails">{item.itemDetails}</p> : <></>}
          </div>

          <div className="item-price-cart">
            <div>
              {item.fnbs_alternateitems.length != 0 ? (
                this.getLowestPriceFromAlternateItems(item.fnbs_alternateitems) != "0.00" ? (
                  <>
                    <div className="item-price strikePrice noLinethrough">
                      <span className="currency">Starts from</span>
                    </div>
                    <div className="item-price">
                      <span className="currency">$</span>
                      {this.getLowestPriceFromAlternateItems(item.fnbs_alternateitems)}
                    </div>
                  </>
                ) : (
                  <></>
                )
              ) : item.strikeValueBeforeTax == null ? (
                item.valuebeforetax == 0 || item.valuebeforetax == "0" ? (
                  <></>
                ) : (
                  <div className="item-price">
                    <span className="currency">$</span>
                    {item.valuebeforetax.toFixed(2)}
                  </div>
                )
              ) : (
                <>
                  <div className="item-price strikePrice">
                    <span className="currency">$</span>
                    {item.valuebeforetax.toFixed(2)}
                  </div>
                  <div className="item-price">
                    <span className="currency">$</span>
                    {item.strikeValueBeforeTax.toFixed(2)}
                  </div>
                </>
              )}
            </div>

            {item.active && item.show ? (
              <button className="add-to-cart" onClick={() => this.props.addToCartButtonClick(itemIndex, tabId)}>
                + ADD
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  }
}
