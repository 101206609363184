import React, { Component } from "react";
import lanmarkImg from "../../assets/images/landmark-extra-icon.svg";
import barCode from "../../assets/images/bar-code-icon.svg";
import "./index.scss";
import { showAlert } from "../../components/showAlert";
import infoIcon from "../../assets/images/info-icon.svg";
import axios from "axios";
import Loader from "../../pages/loader";
import BarCodeModal from "../../components/BarcodeModal";
import * as API from "../../configuration/apiconfig";
export class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      barcodeModalSH: false,
      layoutName: "ip",
      memberCardNumber: "",
      scanBarCodeBtnClick: false,
      manualBarCodeBtnClick: false,
      keyboardInputClickManually: false,
      loginErrorMsg: "",
      scanBarcodeLoaderMsg: "Barcode scanning in progress",
    };
  }

  componentDidMount = () => {
    window.winvar = this;
    localStorage.removeItem("endTimeInSecs");
    localStorage.removeItem("BookingCountdown");
    
    
    let orderType = localStorage.getItem('orderType');
    if (localStorage.getItem("orderId") != null && orderType == 'fnb' && localStorage.getItem("giftCardSwipeInit") != null) {
      var orderDetails = JSON.parse(localStorage.getItem("orderId"));
      if(orderDetails?.id){
        localStorage.removeItem("giftCardSwipeInit");
        window.callbackObj.callAbortTransaction(orderDetails.id, 'failure', 'Sorry', 'Swipe cancelled');
      }
      
      this.deleteOrder();
      localStorage.removeItem("orderId");
    }
  };

  AbortTransactionResponse = (response) => {
    // This is dot net function
    console.log(response, 'Swipe card abort response--login page');
  }

  GiftCardSwipeResponse = (response) => {
    // This is dot net function
    console.log(response, 'GiftCardSwipeResponse--login page');
  }

  deleteOrder = () => {
    var orderDetails = JSON.parse(localStorage.getItem("orderId"));
    if (orderDetails?.id) {
      API.callEndpoint("DELETE", "Bearer", `/order/v2/orders/${orderDetails.id}`)
      .then((response) => {
        console.log(response);
      })
      .catch((ex) => {
        console.log(ex.error);
      });
    }
  }

  closeIconClick = (getFlow) => {
    this.setState({
      barcodeModalSH: false,
      memberCardNumber: "",
      scanBarCodeBtnClick: false,
      manualBarCodeBtnClick: false,
      keyboardInputClickManually: false,
    }, () => {
      if(getFlow == 'scanBarcodeClose'){
        this.props.timerDecrement();
      }
    });
  };

  keyBoardOnChange = (input) => {
    this.setState({
      memberCardNumber: input,
      loginErrorMsg: "",
    });
    // console.log("Input changed", input);
  };

  keyBoardOnKeyPress = (button) => {
    // console.log("Button pressed", button);
    if (button === "{shift}" || button === "{lock}") this.keyBoardHandleShift();
  };

  keyBoardHandleShift = () => {
    const layoutName = this.state.layoutName;
    this.setState({
      layoutName: layoutName === "default" ? "shift" : "default",
    });
  };

  clickKeyboardIcon = () => {
    this.setState(
      {
        // keyboardInputClickManually: true
        keyboardInputClickManually: !this.state.keyboardInputClickManually ? true : false,
      },
      () => {
        var getInputElem = document.getElementById("memberInput");
        if (getInputElem) {
          document.getElementById("memberInput").focus();
        }
      }
    );
  };

  disableSubmitBtn = () => {
    var disabled = true;
    if (this.state.memberCardNumber != "") {
      disabled = false;
    } else {
      disabled = true;
    }
    return disabled;
  };

  guestUser = async () => {
    // When session expiry in login user > delete order
    if(localStorage.getItem("sessionExpiredRedirectUrl") != null && localStorage.getItem("orderId") != null) {
      this.setState({
        loader: true
      })
      var orderDetails = JSON.parse(localStorage.getItem("orderId"));
      await API.callEndpoint("DELETE", "Bearer", `/order/v2/orders/${orderDetails.id}`)
      .then((response) => {
        localStorage.removeItem("selectedSeats");
        localStorage.removeItem("orderId");
        localStorage.removeItem("cartData");
        localStorage.removeItem("sessionExpiredRedirectUrl");
        this.setState({loader: false})
      })
      .catch((err) => {
        console.log(err.error, 'delete error');
        this.setState({loader: false})
      });
    }
    
    const isFnbFlow = localStorage.getItem("orderType") === "fnb";
    const cinemaId = localStorage.getItem("cinemaid");
    if (isFnbFlow) {
      window.location.href = `fnb?cinemaid=${cinemaId}`;
    } else {
      var getSessionIdFromLocalstorage = localStorage.getItem("sesstionid");
      window.location.href = `/seatlayout?sessionid=${getSessionIdFromLocalstorage}`;
    }
  };

  errorHandleAlert = (getErrorMsg) => {
    showAlert({
      html: `<aside class="error-msg"><p>${getErrorMsg} <span>Please try again by scanning your card or manually enter your extra members number</span></p></aside>`,
      iconHtml: `<img src=${infoIcon}>`,
      showCancelButton: false,
      cancelButtonText: "CANCEL",
      showConfirmButton: true,
      confirmButtonText: "OK",
      allowOutsideClick: false,
      allowEscapeKey: false,
      iconClasses: "borderless",
      popupClasses: "login-error-popup",
      onConfirm: () => { },
      onCancel: () => { },
    });
  };

  loginAPI = () => {
    this.setState(
      {
        loginErrorMsg: "",
      },
      () => {
        var data = new Object();
        data.cardNumber = this.state.memberCardNumber;
        data.getUserInfo = true;
        axios({
          url: process.env.REACT_APP_BASEURL + "/user/v2/card/login",
          method: "POST",
          data: data,
          auth: {
            username: this.state.memberCardNumber,
          },
        })
          .then((response) => {
            console.log("response.data");
            console.log(JSON.stringify(response.data));
            document.cookie = "accessToken=" + response.data.accessToken;
            document.cookie = "refreshToken=" + response.data.refreshToken;
            document.cookie = "firstname=" + response.data.firstname;
            document.cookie = "lastname=" + response.data.lastname;
            this.setState({ loader: false });
            var getSessionExpiredRedirectUrl = localStorage.getItem("sessionExpiredRedirectUrl");
            if (this.state.scanBarCodeBtnClick && getSessionExpiredRedirectUrl == null) {
              let orderType = localStorage.getItem('orderType');
              if(orderType == "ticketing"){
                // console.log('ticketing flow');
                var getSessionIdFromLocalstorage = localStorage.getItem("sesstionid");
                window.location.href = `/seatlayout?sessionid=${getSessionIdFromLocalstorage}`;
              }else{
                // console.log('fnb flow');
                var getCinemaIdFromLocalstorage = localStorage.getItem("cinemaid");
                window.location.href = `/fnb?cinemaid=${getCinemaIdFromLocalstorage}`;
              }
            } else if (this.state.manualBarCodeBtnClick && getSessionExpiredRedirectUrl == null) {
              window.location.href = "/yourdetails";
            }else if(getSessionExpiredRedirectUrl != null && this.state.scanBarCodeBtnClick || this.state.manualBarCodeBtnClick){
              // login user -- Session expired time
              console.log('Login expiry flow');
              window.location.href = getSessionExpiredRedirectUrl;
            }
          })
          .catch((error) => {
            // console.log(error.response.data);
            this.setState({
              loader: false,
            }, () => {
              if (this.state.scanBarCodeBtnClick) {
                this.setState({
                  barcodeModalSH: false,
                  memberCardNumber: ""
                }, () => {
                  this.errorHandleAlert(error.response.data.message);
                })
              } else if (this.state.manualBarCodeBtnClick) {
                this.setState({
                  loginErrorMsg: error.response.data.message
                });
              }
            });
          });
      }
    );
  };

  userLogin = () => {
    this.setState(
      {
        loader: true,
      },
      () => {
        this.loginAPI();
      }
    );
  };

  GetCardInfo = (scanData) => {
    console.log(scanData, "scanData----(.net function)");
    if (scanData) {
      this.setState(
        {
          memberCardNumber: scanData,
          scanBarcodeLoaderMsg: "Fetching Member Info",
        },
        () => {
          window.callbackObj.callDisableBarcodeScan();
          this.loginAPI();
        }
      );
    }
  };

  scanBarCodeClick = (buttonWrap) => {
    if (buttonWrap == "scanbarcode") {
      window.callbackObj.callEnableBarcodeScan("loyalty");
      this.props.timerIncrement();
      this.setState({
        scanBarCodeBtnClick: true,
        manualBarCodeBtnClick: false,
      });
    } else if (buttonWrap == "manualenterbarcode") {
      this.setState({
        manualBarCodeBtnClick: true,
        scanBarCodeBtnClick: false,
        keyboardInputClickManually: true,
      });
    }
    this.setState({
      barcodeModalSH: true,
      loginErrorMsg: "",
    });
  };

  render() {
    return (
      <>
        <Loader loader={this.state.loader} />
        <div className="loginComponent">
          {/* <p className="scanYourBarcode">
            Scan your <img src={lanmarkImg} alt="Landmark" /> barcode!
          </p> */}
          <div className="scan-btn paddingTop">
            <a onClick={() => this.scanBarCodeClick("scanbarcode")} className="btn btnPrimary" href="javascript:;" title="CLICK TO SCAN BARCODE">
              CLICK TO SCAN BARCODE
              <img src={barCode} alt="Bar code" />
            </a>
          </div>
          <p className="or-para">OR</p>
          <div className="scan-btn">
            <p>Having trouble scanning a QR code on your device?</p>
            <a onClick={() => this.scanBarCodeClick("manualenterbarcode")} className="btn btnPrimary" href="javascript:;" title="ENTER YOUR DETAILS MANUALLY">
              ENTER YOUR DETAILS MANUALLY
            </a>
          </div>
          <div className="procced-text">
            <p>
              <span>OR</span>{" "}
              <a onClick={this.guestUser} href="javascript:;" title="PROCEED AS A GUEST">
                PROCEED AS A GUEST
              </a>
            </p>
          </div>
        </div>
        <BarCodeModal
          parentState={this.state}
          closeIconClick={this.closeIconClick}
          keyBoardOnChange={this.keyBoardOnChange}
          keyBoardOnKeyPress={this.keyBoardOnKeyPress}
          keyBoardHandleShift={this.keyBoardHandleShift}
          clickKeyboardIcon={this.clickKeyboardIcon}
          disableSubmitBtn={this.disableSubmitBtn}
          userLogin={this.userLogin}
          keyBoardIcon={false}
        />
      </>
    );
  }
}

export default index;
