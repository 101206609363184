import { Component } from "react";
import SwipeCard from "../../assets/images/swipe-card.svg";
import "./index.scss";
import { showAlert } from "../../components/showAlert";
import infoIcon from "../../assets/images/info-icon.svg";
import * as API from "../../configuration/apiconfig";
import { orderExpiredRedirect } from "../../utils/AlertHandling";
import Loader from "../loader";
import queryString from "query-string";

var paymentSuccess = {
  "code": "200",
  "message": "OK",
  "data": {
    "MessageHeader": {
      "MessageCategory": "Payment",
      "MessageClass": "Service",
      "MessageType": "Response",
      "POIID": "P400Plus-806092677",
      "ProtocolVersion": "3.0",
      "SaleID": "POSSysID01",
      "ServiceID": "25102516"
    },
    "PaymentResponse": {
      "POIData": {
        "POIReconciliationID": "1000",
        "POITransactionID": {
          "TimeStamp": "2024-07-25T16:25:22.848Z",
          "TransactionID": "PYyz001721924722002.R6JWH57FFD6R9BV5"
        }
      },
      "PaymentReceipt": [
        {
          "DocumentQualifier": "CashierReceipt",
          "OutputContent": {
            "OutputFormat": "Text",
            "OutputText": [
              {
                "CharacterStyle": "Bold",
                "EndOfLineFlag": true,
                "Text": "key=header1"
              },
              {
                "CharacterStyle": "Bold",
                "EndOfLineFlag": true,
                "Text": "key=header2"
              },
              {
                "CharacterStyle": "Bold",
                "EndOfLineFlag": true,
                "Text": "name=MERCHANT%20COPY&key=merchantTitle"
              },
              {
                "EndOfLineFlag": true,
                "Text": "key=filler"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Date&value=2024-07-25&key=txdate"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Time&value=10%3a25%3a22&key=txtime"
              },
              {
                "EndOfLineFlag": true,
                "Text": "key=filler"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Card&value=%2a%2a%2a%2a0011&key=pan"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=PAN%20seq.&value=01&key=panSeq"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Pref.%20name&value=VISA%20CREDIT&key=preferredName"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Card%20type&value=visadebit&key=cardType"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Payment%20method&value=visa&key=paymentMethod"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Payment%20variant&value=visadebit&key=paymentMethodVariant"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Entry%20mode&value=Contactless%20chip&key=posEntryMode"
              },
              {
                "EndOfLineFlag": true,
                "Text": "key=filler"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=AID&value=A0000000031010&key=aid"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=MID&value=50&key=mid"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=TID&value=P400Plus-806092677&key=tid"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=PTID&value=06092677&key=ptid"
              },
              {
                "EndOfLineFlag": true,
                "Text": "key=filler"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Auth.%20code&value=123456&key=authCode"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Tender&value=PYyz001721924722002&key=txRef"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Reference&value=7252024955&key=mref"
              },
              {
                "EndOfLineFlag": true,
                "Text": "key=filler"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Type&value=GOODS_SERVICES&key=txtype"
              },
              {
                "CharacterStyle": "Bold",
                "EndOfLineFlag": true,
                "Text": "name=TOTAL&value=CAD%2010.00&key=totalAmount"
              },
              {
                "EndOfLineFlag": true,
                "Text": "key=filler"
              },
              {
                "CharacterStyle": "Bold",
                "EndOfLineFlag": true,
                "Text": "name=APPROVED&key=approved"
              },
              {
                "EndOfLineFlag": true,
                "Text": "key=filler"
              }
            ]
          },
          "RequiredSignatureFlag": false
        },
        {
          "DocumentQualifier": "CustomerReceipt",
          "OutputContent": {
            "OutputFormat": "Text",
            "OutputText": [
              {
                "CharacterStyle": "Bold",
                "EndOfLineFlag": true,
                "Text": "key=header1"
              },
              {
                "CharacterStyle": "Bold",
                "EndOfLineFlag": true,
                "Text": "key=header2"
              },
              {
                "CharacterStyle": "Bold",
                "EndOfLineFlag": true,
                "Text": "name=CARDHOLDER%20COPY&key=cardholderHeader"
              },
              {
                "EndOfLineFlag": true,
                "Text": "key=filler"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Date&value=2024-07-25&key=txdate"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Time&value=10%3a25%3a22&key=txtime"
              },
              {
                "EndOfLineFlag": true,
                "Text": "key=filler"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Card&value=%2a%2a%2a%2a0011&key=pan"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=PAN%20seq.&value=01&key=panSeq"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Pref.%20name&value=VISA%20CREDIT&key=preferredName"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Card%20type&value=visadebit&key=cardType"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Payment%20method&value=visa&key=paymentMethod"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Payment%20variant&value=visadebit&key=paymentMethodVariant"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Entry%20mode&value=Contactless%20chip&key=posEntryMode"
              },
              {
                "EndOfLineFlag": true,
                "Text": "key=filler"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=AID&value=A0000000031010&key=aid"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=MID&value=50&key=mid"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=TID&value=P400Plus-806092677&key=tid"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=PTID&value=06092677&key=ptid"
              },
              {
                "EndOfLineFlag": true,
                "Text": "key=filler"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Auth.%20code&value=123456&key=authCode"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Tender&value=PYyz001721924722002&key=txRef"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Reference&value=7252024955&key=mref"
              },
              {
                "EndOfLineFlag": true,
                "Text": "key=filler"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Type&value=GOODS_SERVICES&key=txtype"
              },
              {
                "CharacterStyle": "Bold",
                "EndOfLineFlag": true,
                "Text": "name=TOTAL&value=CAD%2010.00&key=totalAmount"
              },
              {
                "EndOfLineFlag": true,
                "Text": "key=filler"
              },
              {
                "CharacterStyle": "Bold",
                "EndOfLineFlag": true,
                "Text": "name=APPROVED&key=approved"
              },
              {
                "EndOfLineFlag": true,
                "Text": "key=filler"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Retain%20for%20your%20records&key=retain"
              },
              {
                "EndOfLineFlag": true,
                "Text": "name=Thank%20you&key=thanks"
              }
            ]
          },
          "RequiredSignatureFlag": false
        }
      ],
      "PaymentResult": {
        "AmountsResp": {
          "AuthorizedAmount": 10,
          "Currency": "CAD"
        },
        "CustomerLanguage": "en",
        "OnlineFlag": true,
        "PaymentAcquirerData": {
          "AcquirerPOIID": "P400Plus-806092677",
          "AcquirerTransactionID": {
            "TimeStamp": "2024-07-25T16:25:22.848Z",
            "TransactionID": "R6JWH57FFD6R9BV5"
          },
          "ApprovalCode": "123456",
          "MerchantID": "LanCinCanLimParPOS"
        },
        "PaymentInstrumentData": {
          "CardData": {
            "EntryMode": [
              "Contactless"
            ],
            "MaskedPan": "476173 **** 0011",
            "PaymentBrand": "visa",
            "SensitiveCardData": {
              "CardSeqNumb": "01",
              "ExpiryDate": "1224"
            }
          },
          "PaymentInstrumentType": "Card"
        }
      },
      "Response": {
        "AdditionalResponse": "eyJhZGRpdGlvbmFsRGF0YSI6eyJBSUQiOiJBMDAwMDAwMDAzMTAxMCIsImFjcXVpcmVyUmVzcG9uc2VDb2RlIjoiQVBQUk9WRUQiLCJhcHBsaWNhdGlvbkxhYmVsIjoiVklTQSBDUkVESVQiLCJhcHBsaWNhdGlvblByZWZlcnJlZE5hbWUiOiJWSVNBIENSRURJVCIsImJhY2tlbmRHaWZ0Y2FyZEluZGljYXRvciI6ImZhbHNlIiwiY2FyZEJpbiI6IjQ3NjE3MyIsImNhcmRIb2xkZXJWZXJpZmljYXRpb25NZXRob2RSZXN1bHRzIjoiM0YwMDAwIiwiY2FyZElzc3VlTnVtYmVyIjoiMSIsImNhcmRTY2hlbWUiOiJ2aXNhZGViaXQiLCJjYXJkU3VtbWFyeSI6IjAwMTEiLCJjYXJkVHlwZSI6InZpc2EiLCJleHBpcnlNb250aCI6IjEyIiwiZXhwaXJ5WWVhciI6IjIwMjQiLCJmdW5kaW5nU291cmNlIjoiQ1JFRElUIiwiZ2lmdGNhcmRJbmRpY2F0b3IiOiJmYWxzZSIsImlzbzg2MDFUeERhdGUiOiIyMDI0LTA3LTI1VDE2OjI1OjIyLjg0OFoiLCJtZXJjaGFudFJlZmVyZW5jZSI6IjcyNTIwMjQ5NTUiLCJtaWQiOiI1MCIsIm9mZmxpbmUiOiJmYWxzZSIsInBheW1lbnRNZXRob2QiOiJ2aXNhIiwicGF5bWVudE1ldGhvZFZhcmlhbnQiOiJ2aXNhZGViaXQiLCJwb3NBbW91bnRDYXNoYmFja1ZhbHVlIjoiMCIsInBvc0Ftb3VudEdyYXR1aXR5VmFsdWUiOiIwIiwicG9zQXV0aEFtb3VudEN1cnJlbmN5IjoiQ0FEIiwicG9zQXV0aEFtb3VudFZhbHVlIjoiMTAwMCIsInBvc0VudHJ5TW9kZSI6IkNMRVNTX0NISVAiLCJwb3NPcmlnaW5hbEFtb3VudFZhbHVlIjoiMTAwMCIsInBvc2FkZGl0aW9uYWxhbW91bnRzLm9yaWdpbmFsQW1vdW50Q3VycmVuY3kiOiJDQUQiLCJwb3NhZGRpdGlvbmFsYW1vdW50cy5vcmlnaW5hbEFtb3VudFZhbHVlIjoiMTAwMCIsInBzcFJlZmVyZW5jZSI6IlI2SldINTdGRkQ2UjlCVjUiLCJzaG9wcGVyQ291bnRyeSI6IkNBIiwidGMiOiIxNjFCNjRDMUI3MDJEMjA3IiwidGlkIjoiMDYwOTI2NzciLCJ0cmFuc2FjdGlvbkxhbmd1YWdlIjoiZW4iLCJ0cmFuc2FjdGlvblJlZmVyZW5jZU51bWJlciI6IlI2SldINTdGRkQ2UjlCVjUiLCJ0cmFuc2FjdGlvblR5cGUiOiJHT09EU19TRVJWSUNFUyIsInR4ZGF0ZSI6IjI1LTA3LTIwMjQiLCJ0eHRpbWUiOiIxMDoyNToyMiJ9LCJzdG9yZSI6IlRlc3RQT1MifQ==",
        "Result": "Success"
      },
      "SaleData": {
        "SaleTransactionID": {
          "TimeStamp": "2024-07-25T10:25:16.000Z",
          "TransactionID": "7252024955"
        }
      }
    }
  },
  "ExtendedErrorLog": ""
}

var paymentFailure = {
  "code": "500",
  "message": "Cancel",
  "data": {
    "MessageHeader": {
      "MessageCategory": "Payment",
      "MessageClass": "Service",
      "MessageType": "Response",
      "POIID": "P400Plus-806092677",
      "ProtocolVersion": "3.0",
      "SaleID": "POSSysID01",
      "ServiceID": "25103103"
    },
    "PaymentResponse": {
      "POIData": {
        "POIReconciliationID": "1000",
        "POITransactionID": {
          "TimeStamp": "2024-07-25T16:31:07.436Z",
          "TransactionID": "PYyz001721925067004"
        }
      },
      "PaymentResult": {
        "PaymentAcquirerData": {
          "AcquirerPOIID": "P400Plus-806092677",
          "MerchantID": "LanCinCanLimParPOS"
        },
        "PaymentInstrumentData": {
          "CardData": {
            
          },
          "PaymentInstrumentType": "Card"
        }
      },
      "Response": {
        "AdditionalResponse": "eyJhZGRpdGlvbmFsRGF0YSI6eyJnaWZ0Y2FyZEluZGljYXRvciI6ImZhbHNlIiwiaXNvODYwMVR4RGF0ZSI6IjIwMjQtMDctMjVUMTY6MzE6MDcuNDM2WiIsIm1lcmNoYW50UmVmZXJlbmNlIjoiNzI1MjAyNDk1NCIsInBvc0Ftb3VudENhc2hiYWNrVmFsdWUiOiIwIiwicG9zQW1vdW50R3JhdHVpdHlWYWx1ZSI6IjAiLCJwb3NBdXRoQW1vdW50Q3VycmVuY3kiOiJDQUQiLCJwb3NBdXRoQW1vdW50VmFsdWUiOiIxMDAwIiwicG9zT3JpZ2luYWxBbW91bnRWYWx1ZSI6IjEwMDAiLCJwb3NhZGRpdGlvbmFsYW1vdW50cy5vcmlnaW5hbEFtb3VudEN1cnJlbmN5IjoiQ0FEIiwicG9zYWRkaXRpb25hbGFtb3VudHMub3JpZ2luYWxBbW91bnRWYWx1ZSI6IjEwMDAiLCJ0aWQiOiIwNjA5MjY3NyIsInRyYW5zYWN0aW9uVHlwZSI6IkdPT0RTX1NFUlZJQ0VTIiwidHhkYXRlIjoiMjUtMDctMjAyNCIsInR4dGltZSI6IjEwOjMxOjA3In0sIm1lc3NhZ2UiOiIxMDggU2hvcHBlciBjYW5jZWxsZWQgdHgiLCJyZWZ1c2FsUmVhc29uIjoiMTA4IFNob3BwZXIgY2FuY2VsbGVkIHR4Iiwic3RvcmUiOiJUZXN0UE9TIn0=",
        "ErrorCondition": "Cancel",
        "Result": "Failure"
      },
      "SaleData": {
        "SaleTransactionID": {
          "TimeStamp": "2024-07-25T10:31:03.000Z",
          "TransactionID": "7252024954"
        }
      }
    }
  },
  "ExtendedErrorLog": ""
}
var paymentError = {"code":"500","message":"Error","data":{},"ExtendedErrorLog":""}
var error405 = {"code":"405","message":"Payment terminal device is out of communication","data":{},"ExtendedErrorLog":""}
class index extends Component {
  constructor(props) {
    const parsedQueryString = queryString.parse(window.location.search);
    console.log(parsedQueryString.mockstatus);
    super(props);
    this.state = {
      loader: false,
      orderData: [],
      getEncryptedVal: '',
      processingContent: '',
      paymentResponseStaticData: 
        parsedQueryString.mockstatus == 'success' 
        ? paymentSuccess:
        parsedQueryString.mockstatus == 'failure'
        ? paymentFailure:
        parsedQueryString.mockstatus == 'error' 
        ? paymentError:
        parsedQueryString.mockstatus == '405' 
        ? error405:
        null
    }
  }

  componentDidMount = () => {
    window.winvar = this;
    this.getOrderDetails();
  }

  getOrderDetails = async () => {
    var getOrderId = JSON.parse(localStorage.getItem("orderId"));
    if(getOrderId){
      API.callEndpoint("GET", "Bearer", `/order/v2/orders?id=${getOrderId.id}`)
      .then((response) => {
        this.setState({
          orderData: response.data[0],
          getEncryptedVal: response.data[0].additionalinfo.encryptedVal,
          processingContent: 'Waiting for you to swipe card'
        }, () => {
          if(this.state.paymentResponseStaticData == null){
            console.log("Order Value: ", response.data[0].value);
            console.log("remainingOrderValue: ", response.data[0].remainingOrderValue);
            // window.callbackObj.callPayment(response.data[0].id, response.data[0].value, response.data[0].paymentid);
            window.callbackObj.callPayment(response.data[0].id, response.data[0].remainingOrderValue, response.data[0].paymentid); // Replaced response.data[0].value with response.data[0].remainingOrderValue to handle Split Payment.
          }else{
            // -----------------Only for testing----------------------
            var staticPaymentResponse = this.state.paymentResponseStaticData;
            this.PaymentResponse(staticPaymentResponse);
            // -----------------Only for testing----------------------
          }
        });
      })
      .catch((error) => {
        this.showErrorPopup(error.error, 'getOrderRedirect');
      });
    }
  };

  PaymentResponse = (response) => {
    // This function has provided .net team
    console.log(response.code, response.message, '-----PaymentResponse function response');
    let paymentResponse = response;
    if((paymentResponse && Object.keys(paymentResponse.data).length > 0) && (paymentResponse.code === "200" || paymentResponse.code === "500")){
      var tempOrdeData = this.state.orderData;
      var zeroOrderPayload = {
        "orderid": tempOrdeData.id,
        "request": {},
        "response": response.data,
        "status": paymentResponse.code === "200" ? 'SUCCESS':'FAILURE',
        "encryptedVal": this.state.getEncryptedVal
      }
      API.callEndpoint("POST", "Bearer", `/checkout/v2/checkout/kioskCommitOrder`, zeroOrderPayload)
      .then((response) => {
        var commitOrderResponse = response.data;
        this.setState({
          processingContent: 'Processing payment'
        }, () => {
          window.location.href = `/confirmation?id=${tempOrdeData.id}&paymentid=${commitOrderResponse.paymentid}&status=${commitOrderResponse.status}`;
        })
      })
      .catch((ex) => {
        this.showErrorPopup(ex.error, null);
      });
    }else if((paymentResponse && Object.keys(paymentResponse.data).length == 0) && (paymentResponse.code === "500" || paymentResponse.code === "405")){
      this.setState({
        processingContent: ''
      }, () => {
        showAlert({
          html: `<p>${paymentResponse.message}</p>`,
          iconHtml: `<img src=${infoIcon}>`,
          showCancelButton: false,
          cancelButtonText: "CANCEL",
          showConfirmButton: true,
          confirmButtonText: "OK",
          allowOutsideClick: false,
          allowEscapeKey: false,
          iconClasses: "borderless",
          popupClasses: "order-error-popup",
          onConfirm: () => {
            var tempOrdeData = this.state.orderData;
            window.location.href = `/confirmation?id=${tempOrdeData.id}&paymentid=${tempOrdeData.paymentid}&status=failure`;
          },
          onCancel: () => { },
        });
      })
    }
  }
  
  showErrorPopup = (getErrorMsg, getEndpointState) => {
    showAlert({
      html: `<p>${getErrorMsg}</p>`,
      iconHtml: `<img src=${infoIcon}>`,
      showCancelButton: false,
      cancelButtonText: "CANCEL",
      showConfirmButton: true,
      confirmButtonText: "OK",
      allowOutsideClick: false,
      allowEscapeKey: false,
      iconClasses: "borderless",
      popupClasses: "order-error-popup",
      onConfirm: () => {
        if(getEndpointState == 'getOrderRedirect' || getEndpointState == 'deleteOrderRedirect'){
          var errMsg = getErrorMsg.toLowerCase();
          var getMovieName = localStorage.getItem("selectedmoviename");
          var redirectURL = `/showtime?title=${getMovieName}`;
          orderExpiredRedirect(errMsg, redirectURL);
        }
      },
      onCancel: () => { },
    });
  }

  
  cancelOrderBtnClick = () => {
    showAlert({
      html: `<p>Order cancellation alert!<span>Do you want to continue?</span></p>`,
      iconHtml: `<img src=${infoIcon}>`,
      showCancelButton: true,
      cancelButtonText: "CANCEL",
      showConfirmButton: true,
      confirmButtonText: "OK",
      allowOutsideClick: false,
      allowEscapeKey: false,
      iconClasses: "borderless",
      popupClasses: "order-error-popup",
      reverseButtons: true,
      onConfirm: () => {
        this.getDeleteOrderAPI();
      },
      onCancel: () => { },
    });
  }
  
  getDeleteOrderAPI = () => {
    this.setState({
      loader: true
    }, async () => {
      var orderDetails = JSON.parse(localStorage.getItem("orderId"));
      console.log(orderDetails, "orderDetails");
      if(orderDetails?.id){
        await API.callEndpoint("DELETE", "Bearer", `/order/v2/orders/${orderDetails.id}`)
        .then((response) => {
          window.location.href = `/?cinemaid=${localStorage.getItem("cinemaid")}`;
        })
        .catch((ex) => {
          console.log(ex.error);
          this.showErrorPopup(ex.error, 'deleteOrderRedirect');
        });
      }
    })
  }
  
  render() {
    return (
      <>
        <Loader loader={this.state.loader} />
        <div className="swipe-card-container">
          <div className="swipe-inner">
            <div className="swipe-card">
              <img src={SwipeCard} alt="swipecard" className="swipe-card-img" />
              <p>
                Swipe your card & press pay and follow the instructions to complete your transaction
                <span>{this.state.processingContent}</span>
              </p>
            </div>
            {/* <div className="cancel-btn">
              <a onClick={this.cancelOrderBtnClick} className="btn btnSecondary" href="javascript:;" title="CANCEL ORDER">CANCEL ORDER</a>
            </div> */}
          </div>
        </div>
      </>
    );
  }
}

export default index;
