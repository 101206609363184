import React, { Component } from "react";
import "./index.scss";
import { FaPlus, FaMinus } from "react-icons/fa";
const setPaymentPages = window.location.pathname === "/payment";
const setPages = window.location.pathname === "/confirmation";
class BookingSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      ticketSummarySectionHeight: 0,
      openTicketSummary: false,
      maxHeightForTicketSummary: "auto",
    };
    this.ticketSummarySectionRef = React.createRef();
    this.purchaseFooterRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ticketsDataForTicketSummary !== this.props.ticketsDataForTicketSummary || prevProps.fnbDataForTicketSummary !== this.props.fnbDataForTicketSummary) {
      this.setState({ cart: this.props.cart });
    }
  }

  calculateTotal = () => {
    var totalTicketPricing = 0,
      totalFnbPricing = 0,
      grandTotal = 0,
      bookingFee = this.props.bookingFee == false ? 0 : this.props.bookingFee,
      tax = this.props.tax == false ? 0 : this.props.tax;
    this.props.ticketsDataForTicketSummary.length > 0 &&
      this.props.ticketsDataForTicketSummary.map((eachTicket) => {
        totalTicketPricing += eachTicket.qty * eachTicket.unitprice.toFixed(2);
      });

    grandTotal += totalTicketPricing;
    this.props.fnbDataForTicketSummary.length > 0 &&
      this.props.fnbDataForTicketSummary.map((eachFnb) => {
        var unitprice = eachFnb.unitprice;
        eachFnb.modifiers.length > 0 &&
          eachFnb.modifiers.map((modifier) => {
            var totalModifiePrice = 0;
            totalModifiePrice += modifier.unitprice * modifier.qty;
            unitprice += totalModifiePrice;
          });
        totalFnbPricing += eachFnb.qty * unitprice.toFixed(2);
      });
    grandTotal += totalFnbPricing;

    grandTotal += bookingFee;
    grandTotal += tax;

    return grandTotal.toFixed(2);
  };

  populateTicketsForTicketSummary = () => {
    var returnTicketsDiv = [];
    this.props.ticketsDataForTicketSummary.length > 0 &&
      this.props.ticketsDataForTicketSummary.map((eachTicket) => {
        returnTicketsDiv.push(
          <div className="eachTicketDiv">
            <span className="name">{eachTicket.description}</span>
            <span className="eachQty">
              ${eachTicket.unitprice.toFixed(2)} x {eachTicket.qty}
            </span>
            <div className="priceDiv">
              <p className="price">${parseFloat(eachTicket.unitprice * eachTicket.qty).toFixed(2)}</p>
            </div>
          </div>
        );
      });

    return returnTicketsDiv;
  };

  populateFnBForTicketSummary = () => {
    var returnFnBDiv = [];
    this.props.fnbDataForTicketSummary.length > 0 &&
      this.props.fnbDataForTicketSummary.map((eachFnb, eachFnbIndex) => {
        var unitprice = eachFnb.unitprice;
        var totalModifiePrice = 0;
        var modifers = "";
        var smartModifiers = "";
        eachFnb.modifiers.length > 0 &&
          eachFnb.modifiers.map((modifier) => {
            totalModifiePrice += modifier.unitprice * modifier.qty;
            modifers += modifier.description + " x " + modifier.qty + ", ";
          });
        unitprice += totalModifiePrice;
        eachFnb.smartModifiers.length > 0 &&
          eachFnb.smartModifiers.map((smartModifer) => {
            smartModifiers += smartModifer.description + ", ";
          });

        returnFnBDiv.push(
          <div className="eachTicketDiv">
            <div className="name">
              <span>{eachFnb.description}</span>
              {modifers.length == 0 && smartModifiers.length == 0 ? (
                <></>
              ) : (
                <span className="modifiers">
                  {`${modifers.length != 0 ? modifers.slice(0, -2) : ""}`}
                  {`${modifers.length != 0 && smartModifiers.length != 0 ? ", " : ""}`} {`${smartModifiers.length != 0 ? smartModifiers.slice(0, -2) : ""}`}
                </span>
              )}
            </div>

            <span className="eachQty">
              {!this.props.incDecItem ? (
                <>
                  ${unitprice.toFixed(2)} x {eachFnb.qty}
                </>
              ) : (
                <div className="incDecDivForItem">
                  <button type="button" class="btn btn-outline-secondary decButton" onClick={() => this.props.incDecItem("subtract", eachFnbIndex)}>
                    <FaMinus></FaMinus>
                  </button>
                  <button type="button" class="btn btn-outline-secondary itemQty">
                    {eachFnb.qty}
                  </button>
                  <button type="button" class="btn btn-outline-secondary incButton" onClick={() => this.props.incDecItem("add", eachFnbIndex)}>
                    <FaPlus></FaPlus>
                  </button>
                </div>
              )}
            </span>
            <div className="priceDiv">
              {eachFnb.originalPrice && <p className="originalPrice eachQty">${parseFloat(eachFnb.originalPrice * eachFnb.qty).toFixed(2)}</p>}
              <p>${parseFloat(unitprice * eachFnb.qty).toFixed(2)}</p>
            </div>
          </div>
        );
      });
    return returnFnBDiv;
  };

  render() {
    const { fnbDataForTicketSummary, ticketsDataForTicketSummary, giftcardPaymentTypes, getRemainingOrderValue, paymentsTypes } = this.props;
    return (
      <>
        {ticketsDataForTicketSummary.length > 0 && (
          <>
            <div className="separator"></div>
            <p className="heading">Ticket(s)</p>
            <div>{this.populateTicketsForTicketSummary()}</div>
          </>
        )}
        {fnbDataForTicketSummary.length > 0 ? (
          <>
            <div className="separator"></div>
            <p className="heading fnb">Food and Drink(s)</p>
            <div>{this.populateFnBForTicketSummary()}</div>
          </>
        ) : (
          <></>
        )}
        {!this.props.bookingFee && !this.props.tax ? <></> : <div className="separator"></div>}
        {!this.props.bookingFee ? (
          <></>
        ) : (
          <div className="eachTicketDiv">
            <span className="name preCost">
              Booking Fee <span className="secondaryFontRegular"></span>
            </span>
            <p className="eachQty"></p>
            <div className="priceDiv">
              <p className="price preCost">${this.props.bookingFee.toFixed(2)}</p>
            </div>
          </div>
        )}
        {!this.props.tax ? (
          <></>
        ) : (
          <div className="eachTicketDiv">
            <span className="name preCost">
              Tax <span className="secondaryFontRegular"></span>
            </span>
            <p className="eachQty"></p>
            <div className="priceDiv">
              <p className="price preCost">${this.props.tax.toFixed(2)}</p>
            </div>
          </div>
        )}

        <div className="separator"></div>
        <div className="eachTicketDiv">
          <span className="name totalCost">Total Cost</span>
          <p className="eachQty"></p>
          <div className="priceDiv">
            <p className="price totalCost">${this.calculateTotal()}</p>
          </div>
        </div>

        {
          setPaymentPages && giftcardPaymentTypes.length > 0 &&
          giftcardPaymentTypes.map((giftcardData) => {
            if(giftcardData.type.toLowerCase() === 'giftcard'){
            return(
              <>
                <div className="eachTicketDiv giftcard-payment giftcard">
                  <span className="name preCost">
                    {giftcardData.pgName} ({Object.keys(giftcardData.cardinformation).length > 0 ? giftcardData.cardinformation.cardNumber:''})
                  </span>
                  <div className="priceDiv">
                    <p className="price preCost">&minus; ${Object.keys(giftcardData.cardinformation).length > 0 ? giftcardData.cardinformation.amountToDeduct.toFixed(2):''}</p>
                  </div>
                </div>
                <div className="separator"></div>
                <div className="eachTicketDiv giftcard-payment">
                  <span className="name totalCost">To Pay</span>
                  <div className="priceDiv">
                    <p className="price totalCost">${getRemainingOrderValue.toFixed(2)}</p>
                  </div>
                </div>
              </>)
            }
          })
        }

        {paymentsTypes && paymentsTypes.length > 0 && setPages ?
          <div className="payments-types">
            <div className="separator"></div>
            <div className="heading">Payment Summary</div>
            {
              paymentsTypes.map((paymentsData) => {
                return(
                  <div className="eachTicketDiv">
                    <span className="name preCost">
                      {paymentsData.pgName} 
                      {
                        paymentsData.cardinformation && Object.keys(paymentsData.cardinformation).length > 0 && paymentsData.cardinformation.cardNumber && 
                        <strong>({paymentsData.cardinformation.cardNumber})</strong>
                      }
                    </span>
                    <div className="priceDiv">
                      <p className="price preCost">${paymentsData.amount && paymentsData.amount.toFixed(2)}</p>
                    </div>
                  </div>
                )
              })
            }
          </div>
          :
          <></>
        }

        {this.props.nextBtnText.length == 0 ? (
          <></>
        ) : (
          <div className="w-100 text-center">
            <button type="button" className="continueButton" onClick={() => this.props.nextBtnClick()}>
              {this.props.nextBtnText}
            </button>
          </div>
        )}
      </>
    );
  }
}

export default BookingSummary;
