export const orderExpiredRedirect = (errorMsg, redirectURL) => {
  // // // // // console.log("orderExpiredRedirect() > errorMsg >>>>> ", errorMsg)
  if (errorMsg.includes("expire") || errorMsg.includes("no order found")) {
    localStorage.removeItem("selectedSeats");
    localStorage.removeItem("orderId");
    localStorage.removeItem("cartData");
    document.cookie = "refreshToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    document.cookie = "accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    document.cookie = "firstname=;max-age=0";
    document.cookie = "lastname=;max-age=0";
    if(redirectURL)
      window.location.href = redirectURL;
  }
};


export const sessionExpiredRedirectInLoginUser = (errorMsg, redirectURL) => {
  if(errorMsg.includes("expire")){
    document.cookie = "refreshToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    document.cookie = "accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    document.cookie = "firstname=;max-age=0";
    document.cookie = "lastname=;max-age=0";
    if(redirectURL)
      window.location.href = redirectURL;
  }
};
