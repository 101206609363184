import React, {Component} from "react";
import "./index.scss";
import PurchaseFooter from "../Footer";
import FnbCategoriesHeader from "./FnbCategoriesHeader";
import FnbItemCard from "./FnbItemCard";
import AgeRestriction from "./AgeRestriction";
import Modifiers from "./Modifiers/index";
import queryString from "query-string";
import Loader from "../../pages/loader";
import * as API from "../../configuration/apiconfig";
import Skeleton from "react-loading-skeleton";
import incrementIcon from "./Images/increment-icon.svg";
import decrementIcon from "./Images/decrement-icon.svg";
import {showAlert} from "../../components/showAlert";
import infoIcon from "../../assets/images/info-icon.svg";

import {orderExpiredRedirect} from "../../utils/AlertHandling";
import {craeteFnBPayloadForOrdersEndpoint} from "../../utils/PopulateFnbObject.js";
import Offcanvas from "react-bootstrap/Offcanvas";
import {BsCartCheckFill} from "react-icons/bs";
class FNBComponent extends Component {
  constructor(props) {
    const parsedQueryString = queryString.parse(window.location.search);
    super(props);
    this.state = {
      fnbData: [],
      ticketsDataForTicketSummary: [],
      fnbDataForTicketSummary: [],
      activeCategoryIndex: 0,
      fnbActiveItemIndex: "-",
      showModifierModal: false,
      showAgeRestrictionModal: false,
      selectedItem: {},
      cart: [],
      windowWidth: 0,
      ageVerified: false,
      sessionId: parsedQueryString.sessionid,
      cinemaId: parsedQueryString.cinemaid,
      orderId: parsedQueryString.orderid,
      bookingId: parsedQueryString.bookingid,
      orderType: localStorage.getItem("orderType"),
      orderData: [],
      dataLoading: true,
      alcoholItemsInCartCount: 0,
      alcoholItemsLimitPerOrder: process.env.REACT_APP_ALCOHOL_ITEMS_LIMIT_PER_ORDER ? process.env.REACT_APP_ALCOHOL_ITEMS_LIMIT_PER_ORDER : 5,
      isLoading: false,
      itemsAddedToCart: [],
    };
  }

  componentDidMount() {
    let getCartData = JSON.parse(localStorage.getItem("cartData"));

    console.log(getCartData, "getCartData");
    if (getCartData) {
      if (getCartData.expiry < Date.now()) {
        localStorage.removeItem("selectedSeats");
        localStorage.removeItem("cartData");
        localStorage.removeItem("orderId");
        getCartData = null;
      } else {
        this.setState(
          {
            cart: getCartData.cart,
          },
          () => {
            this.populateFnbDataForPurchaseFooter(false);
          }
        );
      }
    }

    if (this.state.orderType === "ticketing") {
      this.getOrderData();
    }

    let width = window.innerWidth;
    this.setState({
      windowWidth: width,
    });

    let FnbHeader = document.getElementById("fnb-categories-header");
    let ItemsList = document.querySelector(".fnb-items-container");
    if (!FnbHeader || !ItemsList) return;
    let FnbHeaderTop = FnbHeader.offsetTop;
    window.addEventListener("scroll", () => {
      if (window.scrollY > FnbHeaderTop) {
        FnbHeader.classList.add("is-fixed");
        // // // // // ItemsList.style.paddingTop = `${FnbHeader.offsetHeight + 10}px`;
        ItemsList.classList.add("fnbCategoriesHeaderFixed");
      } else {
        FnbHeader.classList.remove("is-fixed");
        // // // // // ItemsList.style.paddingTop = 0;
        ItemsList.classList.remove("fnbCategoriesHeaderFixed");
      }
    });

    this.setState({
      fnbData: this.props.fnbData,
      dataLoading: false,
    });
  }

  componentDidUpdate() {
    if (this.props.fnbData.length != 0 && this.state.fnbData.length == 0) {
      this.setState({
        fnbData: this.props.fnbData,
        dataLoading: false,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", () => {});
  }

  getOrderData = () => {
    const {orderId} = this.state;
    API.callEndpoint("GET", "Bearer", `/order/v2/orders?id=${orderId}`)
      .then((res) => {
        console.log(res, "order data");
        let tempOrderData = [];
        res.data[0].order_grouping.map((eachTicket) => {
          if (eachTicket.type.toLowerCase() == "ticket") {
            let eachTaxValue = eachTicket.taxValue / eachTicket.quantity;
            let tempTicketObject = {
              description: eachTicket.description,
              unitprice: eachTicket.unitprice - eachTaxValue,
              qty: eachTicket.quantity,
            };
            tempOrderData.push(tempTicketObject);
          }
        });
        this.setState({
          ticketsDataForTicketSummary: tempOrderData,
          orderData: res.data,
        });
      })
      .catch((err) => {
        var errMsg = err.error.toLowerCase();
        showAlert({
          html: `<p>Sorry<span>${err.error}</span></p>`,
          iconHtml: `<img src=${infoIcon}>`,
          showCancelButton: false,
          cancelButtonText: "CANCEL",
          showConfirmButton: true,
          confirmButtonText: errMsg.includes("expire") || errMsg.includes("no order found") ? "OK" : "Try again",
          allowOutsideClick: false,
          allowEscapeKey: false,
          iconClasses: "borderless",
          popupClasses: "common-error-alert",
          onConfirm: () => {
            var getMovieName = localStorage.getItem("selectedmoviename");
            var redirectURL = `/showtime?title=${getMovieName}`;
            orderExpiredRedirect(errMsg, redirectURL);
          },
          onCancel: () => {},
        });
      });
  };

  handleActiveCategory = (index) => {
    this.setState(
      {
        itemsAddedToCart: [],
      },
      () => {
        this.setState({
          activeCategoryIndex: index,
          fnbActiveItemIndex: "-",
        });
      }
    );
  };

  addToCartButtonClick = (index, tabId) => {
    var activeCategoryIndex = this.state.fnbData.findIndex((x) => x.id === tabId);
    if (this.checkAlcoholItemsLimit(this.state.alcoholItemsInCartCount)) return;
    this.setState(
      {
        fnbActiveItemIndex: index,
        activeCategoryIndex,
      },
      () => {
        var {activeCategoryIndex, fnbActiveItemIndex, cart} = this.state;
        var ageRestrictionFlag =
          process.env.REACT_APP_AGE_RESTRICTION == "true" && localStorage.getItem("ageRestrictionValidation") == null ? this.state.fnbData[activeCategoryIndex].ageRestriction : false;
        ageRestrictionFlag = this.state.ageVerified ? false : ageRestrictionFlag;
        console.log("ageRestrictionFlag >>>>> ", ageRestrictionFlag);
        if (
          this.state.fnbData[activeCategoryIndex].fnbtabs_items[fnbActiveItemIndex].modifierGroups.length == 0 &&
          this.state.fnbData[activeCategoryIndex].fnbtabs_items[fnbActiveItemIndex].smartModifiers.length == 0 &&
          this.state.fnbData[activeCategoryIndex].fnbtabs_items[fnbActiveItemIndex].fnbs_alternateitems.length == 0 &&
          !ageRestrictionFlag
        ) {
          var itemUniqueIdentifier = this.state.fnbData[activeCategoryIndex].fnbtabs_items[fnbActiveItemIndex].id;
          var existingItemIndex = this.state.cart.findIndex((x) => x.itemUniqueIdentifier == itemUniqueIdentifier);
          var existingCartData = JSON.parse(JSON.stringify(this.state.cart));
          var cart = [];
          if (existingItemIndex == -1) {
            cart = [
              ...existingCartData,
              {
                ...this.state.fnbData[activeCategoryIndex].fnbtabs_items[fnbActiveItemIndex],
                selectedQty: 1,
                itemUniqueIdentifier: itemUniqueIdentifier,
                ageRestrictionStatus: this.state.fnbData[activeCategoryIndex].ageRestriction,
                tabId: tabId,
              },
            ];
          } else {
            existingCartData[existingItemIndex].selectedQty = existingCartData[existingItemIndex].selectedQty + 1;
            cart = existingCartData;
          }
          this.setState(
            {
              cart: cart,
            },
            () => {
              this.showItemsAddedToCartMsg(itemUniqueIdentifier, this.state.fnbData[activeCategoryIndex].fnbtabs_items[fnbActiveItemIndex].itemName);
              this.populateFnbDataForPurchaseFooter(true);
            }
          );
        } else {
          this.setState(
            {
              showModifierModal: process.env.REACT_APP_AGE_RESTRICTION == "true" ? (ageRestrictionFlag && localStorage.getItem("ageRestrictionValidation") == null ? false : true) : true,
              showAgeRestrictionModal: process.env.REACT_APP_AGE_RESTRICTION == "true" ? (ageRestrictionFlag && localStorage.getItem("ageRestrictionValidation") == null ? true : false) : false,
              selectedItem: {
                ...this.state.fnbData[this.state.activeCategoryIndex].fnbtabs_items[index],
                selectedQty: 1,
                modifierGroups: this.state.fnbData[this.state.activeCategoryIndex].fnbtabs_items[index].modifierGroups.map((modGrp) => {
                  return {
                    ...modGrp,
                    modifier_items: modGrp.modifier_items.map((modItem) => {
                      return {...modItem, selectedQty: 0};
                    }),
                  };
                }),
                smartModifiers: this.state.fnbData[this.state.activeCategoryIndex].fnbtabs_items[index].smartModifiers.map((smartMod) => {
                  return {
                    ...smartMod,
                    remove: false,
                    addAsSide: false,
                  };
                }),
                fnbs_alternateitems: this.state.fnbData[this.state.activeCategoryIndex].fnbtabs_items[index].fnbs_alternateitems.map((alternateItem) => {
                  return {
                    ...alternateItem,
                    selectedQty: 0,
                  };
                }),
                ageRestrictionStatus: this.state.fnbData[this.state.activeCategoryIndex].ageRestriction,
                tabId: tabId,
              },
            },
            () => {
              var getMonthElem = document.getElementById("month");
              if (getMonthElem) {
                getMonthElem.focus();
              }
            }
          );
        }
      }
    );
  };

  showItemsAddedToCartMsg = (itemUniqueIdentifier, itemName) => {
    var addIndex = this.state.itemsAddedToCart.findIndex((x) => x.id === itemUniqueIdentifier);
    var addedObject,
      tempDirectItemsAddedToCart = [];

    if (addIndex == -1) {
      addedObject = {id: itemUniqueIdentifier, name: itemName, qty: 1};
      tempDirectItemsAddedToCart = [...this.state.itemsAddedToCart, {...addedObject}];
    } else {
      tempDirectItemsAddedToCart = JSON.parse(JSON.stringify(this.state.itemsAddedToCart));
      tempDirectItemsAddedToCart[addIndex].qty += 1;
      tempDirectItemsAddedToCart[addIndex].id = tempDirectItemsAddedToCart[addIndex].id + "-" + tempDirectItemsAddedToCart[addIndex].qty;
    }

    this.setState(
      {
        itemsAddedToCart: tempDirectItemsAddedToCart,
      },
      () => {
        var self = this;
        setTimeout(() => {
          var removeIndex = this.state.itemsAddedToCart.findIndex((x) => x.id === itemUniqueIdentifier);
          var replaceDirectItemsAddedToCart = JSON.parse(JSON.stringify(this.state.itemsAddedToCart));
          replaceDirectItemsAddedToCart.splice(removeIndex, 1);
          self.setState({
            itemsAddedToCart: replaceDirectItemsAddedToCart,
          });
        }, 2500);
      }
    );
  };

  closeModifierPopup = () => {
    this.setState({
      showModifierModal: false,
    });
  };

  closeBtnAgeRestrictionPopup = () => {
    this.setState({
      showAgeRestrictionModal: false,
    });
  };

  closeAgeRestrictionPopup = (clearSelectedItem, ageVerified) => {
    this.setState(
      {
        showAgeRestrictionModal: false,
        selectedItem: clearSelectedItem == true ? {} : {...this.state.selectedItem},
        ageVerified: !ageVerified,
      },
      () => {
        localStorage.setItem("ageRestrictionValidation", "true");
        var {activeCategoryIndex, fnbActiveItemIndex, cart} = this.state;
        if (
          this.state.fnbData[activeCategoryIndex].fnbtabs_items[fnbActiveItemIndex].modifierGroups.length == 0 &&
          this.state.fnbData[activeCategoryIndex].fnbtabs_items[fnbActiveItemIndex].smartModifiers.length == 0 &&
          this.state.fnbData[activeCategoryIndex].fnbtabs_items[fnbActiveItemIndex].fnbs_alternateitems.length == 0 &&
          !clearSelectedItem
        ) {
          var itemUniqueIdentifier = this.state.fnbData[activeCategoryIndex].fnbtabs_items[fnbActiveItemIndex].id;
          this.setState(
            {
              cart: [
                ...cart,
                {
                  ...this.state.fnbData[activeCategoryIndex].fnbtabs_items[fnbActiveItemIndex],
                  selectedQty: 1,
                  ageRestrictionStatus: this.state.fnbData[activeCategoryIndex].ageRestriction,
                  itemUniqueIdentifier,
                },
              ],
            },
            () => {
              this.populateFnbDataForPurchaseFooter(true);
            }
          );
        } else {
          this.setState({
            showModifierModal: clearSelectedItem ? false : true,
          });
        }
      }
    );
  };

  populateItemImage = () => {
    if (this.state.showModifierModal && this.state.fnbActiveItemIndex != null) {
      return <img src={this.state.selectedItem.fnbs_images[0]?.imageurl} className="modifierPopupItemImage" />;
    }
  };

  populateModifierPrice = (modifierItem) => {
    if (modifierItem.valuebeforetax != 0) {
      if (modifierItem.strikeValueBeforeTax == null) {
        return <span className="modifierItemPrice">${modifierItem.valuebeforetax.toFixed(2)}</span>;
      } else {
        return (
          <div>
            <span className="modifierItemPrice">${modifierItem.strikeValueBeforeTax.toFixed(2)}</span>
            <span className="modifierItemPrice strikePrice">${modifierItem.valuebeforetax.toFixed(2)}</span>
          </div>
        );
      }
    }
  };

  populateModifierItem = (modifierGroup, modifierGroupIndex, modifierItem, modifierItemIndex) => {
    var type = "",
      value;
    if (modifierGroup.maxQty >= 0 && modifierGroup.minQty >= 0 && !(modifierGroup.maxQty == 1 && modifierGroup.minQty == 1)) {
      type = "incDec";
    } else if (modifierGroup.maxQty == 0 && modifierGroup.minQty == 0 && modifierItem.valuebeforetax == 0) {
      type = "checkbox";
    } else if (modifierGroup.maxQty == 1 && modifierGroup.minQty == 1) {
      type = "radio";
    } else if (modifierGroup == "" && modifierGroupIndex == "") {
      type = "smartMod";
    } else if (modifierGroup == "alternateItem") {
      type = "alternateItem";
    }

    switch (type) {
      case "checkbox":
        value = (
          <div className={`form-check options-chk-blk ${modifierItem.active == false ? "disabled" : ""}`}>
            <input
              className="form-check-input modiferItemName"
              type="checkbox"
              name={modifierGroup.tabid}
              id={modifierGroup.tabid + modifierItemIndex}
              onClick={() => this.incDecModItem(modifierGroupIndex, modifierItemIndex, "", "checkbox")}
            />
            <label className="form-check-label" for={modifierGroup.tabid + modifierItemIndex}>
              <span className="modifierItemName">{modifierItem.itemName}</span>
              {this.populateModifierPrice(modifierItem)}
            </label>
          </div>
        );
        break;
      case "radio":
        value = (
          <div className={`form-check form-check-radio-btn ${modifierItem.active == false ? "disabled" : ""}`}>
            <input
              className="form-check-input modiferItemName"
              type="radio"
              name={modifierGroup.tabid}
              id={modifierGroup.tabid + modifierItemIndex}
              onClick={() => this.incDecModItem(modifierGroupIndex, modifierItemIndex, "", "radio")}
            />
            <label className="form-check-label" for={modifierGroup.tabid + modifierItemIndex}>
              <span className="modifierItemName">{modifierItem.itemName}</span>
              {this.populateModifierPrice(modifierItem)}
            </label>
          </div>
        );
        break;
      case "incDec":
        value = (
          <div className={`form-check incDec ${modifierItem.active == false ? "disabled" : ""}`}>
            <div className="incDecButtonDiv">
              <button
                type="button"
                className={`btn btn-outline-secondary decButton ${modifierItem.selectedQty == 0 ? "d-none" : "borderTopRadiusZero"}`}
                onClick={() => this.incDecModItem(modifierGroupIndex, modifierItemIndex, "subtract", "incDec")}
              >
                <img src={decrementIcon} className="dec-img" alt="Decrement" />
              </button>
              <span className="selectedQty">{modifierItem.selectedQty == 0 ? "" : `${modifierItem.selectedQty}`}</span>
              <button
                type="button"
                className={`btn btn-outline-secondary incButton ${this.checkIfModItemReachedMaxQty(modifierGroup) && modifierGroup.maxQty != 0 ? "disabled" : ""} ${
                  modifierItem.selectedQty == 0 ? "" : "borderBottomRadiusZero"
                }`}
                onClick={() => this.incDecModItem(modifierGroupIndex, modifierItemIndex, "add", "incDec")}
              >
                <img src={incrementIcon} className="inc-img" alt="Increment" />
              </button>
            </div>
            <label className="form-check-label" for={modifierGroup.tabid}>
              <span className="modifierItemName">{modifierItem.itemName}</span>
              {this.populateModifierPrice(modifierItem)}
            </label>
          </div>
        );
        break;
      case "smartMod":
        value = (
          <div className={`form-check incDec smartMod ${modifierItem.active == false ? "disabled" : ""}`}>
            <div className="incDecButtonDiv smartMod">
              <label className="form-check-label" for={modifierItem.id}>
                <span className="modifierItemName smartMod">
                  <span className="hypen">-</span>
                  {modifierItem.itemName}
                </span>
              </label>
              <div className="remove-btn">
                <button
                  type="button"
                  className={`btn btn-outline-secondary incButton smartModBtn ${modifierItem.addAsSide ? "active" : ""}`}
                  onClick={() => this.smartModClick(modifierItemIndex, "addAsSide")}
                >
                  &nbsp;
                </button>
              </div>
              <div className="add-btn">
                <button
                  type="button"
                  className={`btn btn-outline-secondary decButton smartModBtn ${modifierItem.remove ? "active" : ""}`}
                  onClick={() => this.smartModClick(modifierItemIndex, "remove")}
                >
                  &nbsp;
                </button>
              </div>
            </div>
          </div>
        );
        break;
      case "alternateItem":
        value = (
          <div className={`form-check form-check-radio-btn ${modifierItem.active == false ? "disabled" : ""}`}>
            <input
              className="form-check-input modiferItemName"
              type="radio"
              name={"alternateItemName"}
              id={`alternateItemName-${modifierItemIndex}}`}
              onClick={() => this.incDecModItem(modifierGroup, modifierItemIndex, "", "alternateItem")}
            />
            <label className="form-check-label" for={modifierItem.fnbid + modifierItemIndex}>
              <span className="modifierItemName">{modifierItem.itemName}</span>
              {this.populateModifierPrice(modifierItem)}
            </label>
          </div>
        );
        break;
      default:
        value = <></>;
        break;
    }
    return value;
  };

  checkIfModItemReachedMaxQty = (modifierGroup) => {
    var maxQty = modifierGroup.maxQty,
      totalSelectQty = 0,
      returnBoolean = false;
    modifierGroup.modifier_items.map((eachModifierItem) => {
      totalSelectQty += eachModifierItem.selectedQty;
    });
    if (maxQty == totalSelectQty || totalSelectQty >> maxQty) {
      returnBoolean = true;
    }
    return returnBoolean;
  };

  incDecModItem = (modifierGroupIndex, modifierItemIndex, operator, type) => {
    var getSelectedItemsData = JSON.parse(JSON.stringify(this.state.selectedItem));
    if (type == "radio") {
      getSelectedItemsData = {
        ...getSelectedItemsData,
        modifierGroups: getSelectedItemsData.modifierGroups.map((modGroup, modGroupIndex) => {
          if (modGroupIndex == modifierGroupIndex) {
            return {
              ...modGroup,
              modifier_items: modGroup.modifier_items.map((modItem) => {
                return {...modItem, selectedQty: 0};
              }),
            };
          } else {
            return {...modGroup};
          }
        }),
      };
    }

    if (type == "radio" || type == "checkbox") {
      if (getSelectedItemsData.modifierGroups[modifierGroupIndex].modifier_items[modifierItemIndex].selectedQty == 0) {
        operator = "add";
      } else {
        operator = "subtract";
      }
    }

    if (operator == "add") {
      getSelectedItemsData.modifierGroups[modifierGroupIndex].modifier_items[modifierItemIndex].selectedQty += 1;
    } else if (operator == "subtract" && getSelectedItemsData.modifierGroups[modifierGroupIndex].modifier_items[modifierItemIndex].selectedQty != 0) {
      getSelectedItemsData.modifierGroups[modifierGroupIndex].modifier_items[modifierItemIndex].selectedQty -= 1;
    }

    if (type == "alternateItem") {
      getSelectedItemsData.fnbs_alternateitems = getSelectedItemsData.fnbs_alternateitems.map((alternateitem, alternateItemIndex) => {
        return {...alternateitem, selectedQty: modifierItemIndex == alternateItemIndex ? 1 : 0};
      });
    }
    this.setState({
      selectedItem: getSelectedItemsData,
    });
  };

  smartModClick = (modifierItemIndex, type) => {
    var getSelectedItemsData = JSON.parse(JSON.stringify(this.state.selectedItem));
    if (type == "addAsSide") {
      getSelectedItemsData.smartModifiers[modifierItemIndex]["remove"] = false;
    }
    if (type == "remove") {
      getSelectedItemsData.smartModifiers[modifierItemIndex]["addAsSide"] = false;
    }
    getSelectedItemsData.smartModifiers[modifierItemIndex][type] = !getSelectedItemsData.smartModifiers[modifierItemIndex][type];

    this.setState({
      selectedItem: getSelectedItemsData,
    });
  };

  incDecItem = (operator, itemIndex) => {
    if (operator == "add") {
      if (this.checkAlcoholItemsLimit(this.state.alcoholItemsInCartCount, itemIndex)) return;
    }

    var itemData = [];
    if (itemIndex.length == 0) {
      itemData = JSON.parse(JSON.stringify(this.state.selectedItem));
      if (operator == "add") {
        itemData.selectedQty += 1;
      } else if (operator == "subtract" && itemData.selectedQty != 1) {
        itemData.selectedQty -= 1;
      }
      this.setState(
        {
          selectedItem: itemData,
        },
        () => {
          this.populateFnbDataForPurchaseFooter(true);
        }
      );
    } else {
      itemData = JSON.parse(JSON.stringify(this.state.cart));
      if (operator == "add") {
        itemData[itemIndex].selectedQty += 1;
      } else if (operator == "subtract" && itemData.selectedQty != 1) {
        itemData[itemIndex].selectedQty -= 1;
      }
      if (itemData[itemIndex].selectedQty == 0) {
        this.removeItemFromCart(itemIndex);
      } else {
        this.setState(
          {
            cart: itemData,
          },
          () => {
            this.populateFnbDataForPurchaseFooter(true);
          }
        );
      }
    }
  };

  calculateItemTotal = () => {
    const {selectedItem} = this.state;
    let itemPricePerUnit = 0,
      modifiersPrice = 0,
      selectedQuantity = 0;
    const itemQty = selectedItem.selectedQty;

    if (selectedItem.fnbs_alternateitems.length == 0) {
      selectedQuantity = selectedItem.selectedQty;
      itemPricePerUnit = selectedItem.strikeValueBeforeTax == null ? selectedItem.valuebeforetax : selectedItem.strikeValueBeforeTax;
      selectedItem.modifierGroups.map((modGroup) => {
        modGroup.modifier_items.map((modItem) => {
          var modItemPricePerUnit = modItem.strikeValueBeforeTax == null ? modItem.valuebeforetax : modItem.strikeValueBeforeTax;
          modifiersPrice += modItem.selectedQty * modItemPricePerUnit;
        });
      });
    } else {
      var selectedAlternateItemIndex = selectedItem.fnbs_alternateitems.findIndex((x) => x.selectedQty == 1);
      if (selectedAlternateItemIndex != -1) {
        selectedQuantity = selectedItem.fnbs_alternateitems[selectedAlternateItemIndex].selectedQty;
        itemPricePerUnit =
          selectedItem.fnbs_alternateitems[selectedAlternateItemIndex].strikeValueBeforeTax == null
            ? selectedItem.fnbs_alternateitems[selectedAlternateItemIndex].valuebeforetax
            : selectedItem.fnbs_alternateitems[selectedAlternateItemIndex].strikeValueBeforeTax;
      }
    }
    return {price: (itemQty * (itemPricePerUnit + modifiersPrice)).toFixed(2), itemQty: selectedQuantity};
  };

  checkIfBtnIsDisabled = () => {
    var selectedItem = this.state.selectedItem,
      modGrpQtyArray = [],
      disabledFlag = false;

    selectedItem.modifierGroups.map((modGroup) => {
      var totalModItemSelectedQty = 0;
      modGroup.modifier_items.map((modItem) => {
        totalModItemSelectedQty += modItem.selectedQty;
      });
      modGrpQtyArray.push({minQty: modGroup.minQty, totalModItemSelectedQty});
    });

    modGrpQtyArray.map((eachModGroupQty) => {
      if (eachModGroupQty.minQty != 0 && eachModGroupQty.totalModItemSelectedQty < eachModGroupQty.minQty) {
        console.log("inside if");
        disabledFlag = true;
      }
    });

    return disabledFlag;
  };

  proceedBtnClick = () => {
    if (this.checkAlcoholItemsLimit(this.state.alcoholItemsInCartCount)) return;
    var selectedItem = JSON.parse(JSON.stringify(this.state.selectedItem));
    var itemUniqueIdentifier = selectedItem.id;
    var itemName = selectedItem.itemName;
    if (selectedItem.modifierGroups.length != 0) {
      selectedItem.modifierGroups.map((modGroup) => {
        var modifiersArray = [];
        modGroup.modifier_items.map((modItem) => {
          modifiersArray.push(modItem.modifierid + modItem.selectedQty);
        });
        itemUniqueIdentifier = itemUniqueIdentifier + modifiersArray.sort().join("");
      });
    }

    if (selectedItem.smartModifiers.length != 0) {
      var smartModifiersArray = [];
      selectedItem.smartModifiers.map((smartModifier) => {
        console.log(smartModifier.id);
        var smartModifierType = "";
        if (smartModifier.addAsSide) {
          smartModifierType += "addAsSide";
        }
        if (smartModifier.remove) {
          smartModifierType += "remove";
        }
        smartModifiersArray.push(smartModifier.id + smartModifierType);
      });
      itemUniqueIdentifier = itemUniqueIdentifier + smartModifiersArray.sort().join("");
    }

    if (selectedItem.fnbs_alternateitems.length != 0) {
      selectedItem.fnbs_alternateitems.map((alternateItem) => {
        if (alternateItem.selectedQty != 0) {
          itemUniqueIdentifier = alternateItem.id;
          itemName = selectedItem.itemName + " - " + alternateItem.itemName;
        }
      });
    }

    var existingItemIndex = this.state.cart.findIndex((x) => x.itemUniqueIdentifier == itemUniqueIdentifier);
    var cart = [];

    if (existingItemIndex == -1) {
      cart = [...this.state.cart, {...selectedItem, itemUniqueIdentifier: itemUniqueIdentifier}];
    } else {
      var existingCartData = JSON.parse(JSON.stringify(this.state.cart));
      existingCartData[existingItemIndex].selectedQty = existingCartData[existingItemIndex].selectedQty + selectedItem.selectedQty;
      cart = existingCartData;
    }

    if (selectedItem.ageRestrictionStatus == 1 && this.state.alcoholItemsInCartCount + selectedItem.selectedQty > this.state.alcoholItemsLimitPerOrder) {
      this.showOrderLimitAlert();
      return;
    } else {
      this.setState(
        {
          cart: cart,
          selectedItem: {},
          showModifierModal: false,
        },
        () => {
          this.showItemsAddedToCartMsg(itemUniqueIdentifier, itemName);
          this.populateFnbDataForPurchaseFooter(true);
        }
      );
    }
  };

  populateFnbDataForPurchaseFooter = (updateOrderFunctionisAvailable) => {
    this.updateAlcoholItemsInCartCount(this.state.cart);
    var fnbDataForTicketSummary = [];
    this.state.cart.map((fnbItem) => {
      if (fnbItem.fnbs_alternateitems.length == 0) {
        var modifiers = [];
        fnbItem.modifierGroups.map((modGroup) => {
          modGroup.modifier_items.map((modItem) => {
            if (modItem.selectedQty >= 1) {
              modifiers.push({
                description: modItem.itemName,
                unitprice: modItem.strikeValueBeforeTax == null ? modItem.valuebeforetax : modItem.strikeValueBeforeTax,
                qty: modItem.selectedQty,
              });
            }
          });
        });
        var smartModifiers = fnbItem.smartModifiers
          .filter((smartModifier) => {
            return smartModifier.addAsSide == true || smartModifier.remove == true;
          })
          .map((smartModifier) => {
            if (smartModifier.addAsSide) {
              return {
                description: smartModifier.itemName + " (Add as side)",
                unitprice: smartModifier.strikeValueBeforeTax == null ? smartModifier.valuebeforetax : smartModifier.strikeValueBeforeTax,
                qty: 1,
              };
            }
            if (smartModifier.remove) {
              return {
                description: smartModifier.itemName + " (Remove)",
                unitprice: smartModifier.strikeValueBeforeTax == null ? smartModifier.valuebeforetax : smartModifier.strikeValueBeforeTax,
                qty: 1,
              };
            }
          });
        var fnbObject = {
          description: fnbItem.itemName,
          unitprice: fnbItem.strikeValueBeforeTax == null ? fnbItem.valuebeforetax : fnbItem.strikeValueBeforeTax,
          qty: fnbItem.selectedQty,
          modifiers: modifiers,
          smartModifiers: smartModifiers,
          originalPrice: fnbItem.strikeValueBeforeTax == null ? null : fnbItem.valuebeforetax,
        };
        fnbDataForTicketSummary.push(fnbObject);
      } else {
        var selectedItem = fnbItem;
        var selectedAlternateItemIndex = selectedItem.fnbs_alternateitems.findIndex((x) => x.selectedQty == 1);
        if (selectedAlternateItemIndex != -1) {
          var fnbObject = {
            description: selectedItem.fnbs_alternateitems[selectedAlternateItemIndex].itemName,
            unitprice:
              selectedItem.fnbs_alternateitems[selectedAlternateItemIndex].strikeValueBeforeTax == null
                ? selectedItem.fnbs_alternateitems[selectedAlternateItemIndex].valuebeforetax
                : selectedItem.fnbs_alternateitems[selectedAlternateItemIndex].strikeValueBeforeTax,
            qty: selectedItem.selectedQty,
            modifiers: [],
            smartModifiers: [],
            originalPrice:
              selectedItem.fnbs_alternateitems[selectedAlternateItemIndex].strikeValueBeforeTax == null ? null : selectedItem.fnbs_alternateitems[selectedAlternateItemIndex].valuebeforetax,
          };
          fnbDataForTicketSummary.push(fnbObject);
        }
      }
    });
    this.setState(
      {
        fnbDataForTicketSummary,
      },
      () => {
        if (updateOrderFunctionisAvailable) {
          this.checkIfUpdateOrderFunctionisAvailable();
        }
      }
    );
  };

  removeItemFromCart = (fnbIndex) => {
    var cart = JSON.parse(JSON.stringify(this.state.cart));
    cart.splice(fnbIndex, 1);
    this.setState(
      {
        cart,
      },
      () => {
        this.populateFnbDataForPurchaseFooter();
      }
    );
  };

  updateOrderWithFnbData = async (redirectPath) => {
    // console.log(this.state.cart, "cart data");
    const orderDetails = JSON.parse(localStorage.getItem("orderId"));
    let orderId = orderDetails?.id;
    const expiry = orderId ? orderDetails?.expiry : new Date().getTime() + 720 * 1000;

    this.setState({
      isLoading: true,
    });

    let firstName = API.getValueFromCookie("firstname");
    let lastName = API.getValueFromCookie("lastname");
    let fullName = (firstName ? firstName : "") + " " + (lastName ? lastName : "");
    let isFnbOrder = this.state.orderType === "fnb";

    let fnbOrderData = craeteFnBPayloadForOrdersEndpoint(this.state.cart);
    let orderData = this.state.orderData;
    let orderObject = new Object();
    orderObject.sessionid = isFnbOrder ? (this.state.sessionId ? this.state.sessionId : "") : orderData[0].session.id;
    orderObject.fullname = fullName ? fullName : "";
    orderObject.firstname = firstName ? firstName : "";
    orderObject.lastname = lastName ? lastName : "";
    orderObject.email = "";
    orderObject.phonenumber = "";
    orderObject.tickettypes = isFnbOrder ? [] : orderData[0].orderinfo.tickets;
    orderObject.seats = isFnbOrder ? [] : orderData[0].orderinfo.seats;
    orderObject.fnb = fnbOrderData?.length > 0 ? fnbOrderData : [];
    orderObject.id = isFnbOrder ? (orderId ? orderId : "") : orderData[0].id;
    if (isFnbOrder) {
      orderObject.cinemaid = this.state.cinemaId;
      orderObject.deliverySeat = "";
      orderObject.additionalInfo = {
        disableOfferItems: true,
      };
    }
    console.log(orderObject, "payload");
    let method;
    let orderUrl;
    if (isFnbOrder) {
      method = orderId ? "PUT" : "POST";
      orderUrl = "/order/v2/orders/concession";
      // method = "POST"
    } else {
      method = "PUT";
      orderUrl = "/order/v2/orders";
    }

    const getOrderData = await API.callEndpoint(method, "Bearer", orderUrl, orderObject)
      .then((response) => {
        console.log(response.data, "response data order update");
        const cartData = {
          cart: this.state.cart,
          expiry: expiry,
        };
        localStorage.setItem("cartData", JSON.stringify(cartData));
        let orderIdObject = new Object();
        orderIdObject.id = response.data[0].id;
        orderIdObject.expiry = expiry;
        localStorage.setItem("orderId", JSON.stringify(orderIdObject));
        if (redirectPath) {
          window.location.href = redirectPath;
        } else {
          window.location.href = `/order-review?orderid=${response.data[0].id}`;
        }
        return response.data;
      })
      .catch((err) => {
        console.log(err, "test err");
        var self = this;
        var errMsg = err.error.toLowerCase();
        console.log("errMsg lowerCase >>>>>", errMsg);
        showAlert({
          html: `<p>${err.error}</p>`,
          iconHtml: `<img src=${infoIcon}>`,
          showCancelButton: false,
          cancelButtonText: "CANCEL",
          showConfirmButton: true,
          confirmButtonText: errMsg.includes("expire") || errMsg.includes("no order found") ? "OK" : "Try again",
          allowOutsideClick: false,
          allowEscapeKey: false,
          iconClasses: "borderless",
          popupClasses: "common-error-alert",
          onConfirm: () => {
            console.log(err, "error");
            var getMovieName = localStorage.getItem("selectedmoviename");
            var redirectURL = `/showtime?title=${getMovieName}`;
            if (this.state.orderType === "ticketing") {
              orderExpiredRedirect(errMsg, redirectURL);
            }
          },
          onCancel: () => {},
        });
        self.setState({
          isLoading: false,
        });
      });
  };

  checkAlcoholItemsLimit = (alcoholItemsInCartCountTemp, cartItemIndex = null) => {
    // if (this.state.fnbData[this.state.activeCategoryIndex].ageRestriction != 1) return false;
    if (cartItemIndex === null) {
      if (this.state.fnbData[this.state.activeCategoryIndex].ageRestriction != 1) return false;
    } else {
      if (this.state.cart[cartItemIndex].ageRestrictionStatus != 1) return false;
    }
    if (alcoholItemsInCartCountTemp < this.state.alcoholItemsLimitPerOrder) {
      return false;
    } else {
      this.showOrderLimitAlert();
      return true;
    }
  };

  updateAlcoholItemsInCartCount = (cart) => {
    let alcoholItemsInCartCountTemp = 0;
    cart.map((fnbItem) => {
      if (fnbItem.ageRestrictionStatus == 1) {
        alcoholItemsInCartCountTemp += fnbItem.selectedQty;
      }
    });
    if (alcoholItemsInCartCountTemp <= this.state.alcoholItemsLimitPerOrder) {
      this.setState({
        alcoholItemsInCartCount: alcoholItemsInCartCountTemp,
      });
    } else {
      this.showOrderLimitAlert();
    }
  };

  showOrderLimitAlert = () => {
    showAlert({
      html: `<p>Sorry<span>You can only add ${this.state.alcoholItemsLimitPerOrder} alcohol items to the cart</span></p>`,
      iconHtml: `<img src=${infoIcon}>`,
      showCancelButton: false,
      cancelButtonText: "CANCEL",
      showConfirmButton: true,
      confirmButtonText: "OK",
      allowOutsideClick: false,
      allowEscapeKey: false,
      iconClasses: "borderless",
      popupClasses: "common-error-alert",
      onConfirm: () => {},
      onCancel: () => {},
    });
  };

  checkIfUpdateOrderFunctionisAvailable = () => {
    if (this.props.updateOrder != false) {
      this.props.updateOrder(this.state.cart);
      this.setState({
        cart: [],
      });
    }
  };

  backBtnClick = () => {
    var getSessionIdFromLocalstorage = localStorage.getItem("sesstionid");
    if (this.state.orderType === "ticketing") {
      this.updateOrderWithFnbData(`/seatlayout?sessionid=${getSessionIdFromLocalstorage}`);
    } else if (this.state.orderType === "fnb") {
      let orderId = JSON.parse(localStorage.getItem("orderId"));
      if (orderId) {
        if (orderId.expiry < Date.now()) {
          showAlert({
            html: `<p>Your order has expired</p>`,
            iconHtml: `<img src=${infoIcon}>`,
            showCancelButton: false,
            cancelButtonText: "CANCEL",
            showConfirmButton: true,
            confirmButtonText: "OK",
            allowOutsideClick: false,
            allowEscapeKey: false,
            iconClasses: "borderless",
            popupClasses: "common-error-alert",
            onConfirm: () => {
              this.redirectToLoginPage();
            },
            onCancel: () => {},
          });
        } else {
          showAlert({
            html: `<p>Going back will cancel your current order, and you will need to start again. <span>Do you wish to change?</span></p>`,
            iconHtml: `<img src=${infoIcon}>`,
            showCancelButton: true,
            cancelButtonText: "CANCEL",
            showConfirmButton: true,
            confirmButtonText: "Yes",
            allowOutsideClick: false,
            allowEscapeKey: false,
            iconClasses: "borderless",
            popupClasses: "common-error-alert",
            reverseButtons: true,
            onConfirm: async () => {
              await this.cancelOrder(orderId.id);
              this.setState({
                cartData: [],
                orderId: null,
              });
              localStorage.removeItem("orderId");
              localStorage.removeItem("cartData");
              localStorage.removeItem("bookingid");
              this.redirectToLoginPage();
            },
            onCancel: () => {},
          });
        }
      } else {
        this.redirectToLoginPage();
      }
    }
    // window.location.href = `/seatlayout?sessionid=${getSessionIdFromLocalstorage}`;
  };

  redirectToLoginPage = () => {
    document.cookie = "accessToken=;max-age=0";
    document.cookie = "refreshToken=;max-age=0";
    document.cookie = "firstname=;max-age=0";
    document.cookie = "lastname=;max-age=0";
    localStorage.removeItem("cartData");
    localStorage.removeItem("orderId");
    window.location.href = `/login`;
  };

  cancelOrder = async (orderId) => {
    this.setState({
      isLoading: true,
    });
    await API.callEndpoint("DELETE", "Bearer", `/order/v2/orders/${orderId}`)
      .then((res) => console.log("order cancelled"))
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({isLoading: false});
      });
  };

  checkNextBtnDisable = () => {
    let orderType = localStorage.getItem("orderType");
    if (orderType === "ticketing") {
      return !(this.state.ticketsDataForTicketSummary.length > 0);
    } else {
      return this.state.cart.length == 0;
    }
  };

  populateDirectItemsAddedToCart = () => {
    var itemsAddedToCart = "",
      returnArray = [];
    this.state.itemsAddedToCart.map((directItem) => {
      var qty = directItem.qty == 1 ? "" : " x " + directItem.qty;
      itemsAddedToCart = <p className="itemAddedToCartMsg">{`${directItem.name + qty} added to cart`}</p>;
      returnArray.push(itemsAddedToCart);
    });
    return returnArray;
  };

  render() {
    let {fnbData, activeCategoryIndex, fnbDataForTicketSummary, ticketsDataForTicketSummary} = this.state;
    let {showFooter, showCategoriesSelection} = this.props;
    return (
      <>
        <Loader loader={this.state.isLoading} />

        {showCategoriesSelection ? (
          fnbData && fnbData.length > 0 ? (
            <FnbCategoriesHeader fnbData={fnbData} activeCategoryIndex={activeCategoryIndex} handleActiveCategory={this.handleActiveCategory} />
          ) : (
            <div className="d-flex justify-content-center mt-4 mb-3" style={{gap: "0 15px"}}>
              {Array.from({length: 6}).map((item, index) => {
                return <Skeleton height={60} width={160} style={{borderRadius: "6px"}} />;
              })}
            </div>
          )
        ) : (
          <></>
        )}

        <div className={`fnb-items-container ${this.props.horizontal ? "horizontal" : ""}`}>
          {showCategoriesSelection ? (
            <>
              {fnbData.map((category, categoryIndex) => {
                return (
                  <div key={categoryIndex} className={`fnb-items-list ${activeCategoryIndex === categoryIndex ? "" : "d-none"}`}>
                    {category.fnbtabs_items.map((item, index) => {
                      return <FnbItemCard key={index} tabId={category.id} item={item} itemIndex={index} addToCartButtonClick={this.addToCartButtonClick} />;
                    })}
                  </div>
                );
              })}
            </>
          ) : (
            <div key={0} className={`fnb-items-list`}>
              {fnbData.map((category) => {
                return category.fnbtabs_items.map((item, index) => {
                  return <FnbItemCard key={index} tabId={category.id} item={item} itemIndex={index} addToCartButtonClick={this.addToCartButtonClick} sort={item.sort} />;
                });
              })}
            </div>
          )}

          {this.state.dataLoading && (
            <div className="fnb-items-list">
              {Array.from({length: 10}).map((item, index) => {
                return (
                  <div className="fnb-item-card-loader" style={{width: "33.33%", padding: "25px 10px"}}>
                    <Skeleton height={200} width={"100%"} />
                    <Skeleton height={30} width={"100%"} style={{marginTop: 20, marginBottom: 30}} />
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <Skeleton height={20} width={140} />
                        <Skeleton height={20} width={140} />
                      </div>
                      <Skeleton height={50} width={120} />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <Modifiers
          showModifierModal={this.state.showModifierModal}
          fnbActiveItemIndex={this.state.fnbActiveItemIndex}
          populateItemImage={this.populateItemImage}
          selectedItem={this.state.selectedItem}
          populateModifierItem={this.populateModifierItem}
          incDecItem={this.incDecItem}
          calculateItemTotal={this.calculateItemTotal}
          checkIfBtnIsDisabled={this.checkIfBtnIsDisabled}
          proceedBtnClick={this.proceedBtnClick}
          closeModifierModal={this.closeModifierPopup}
          windowWidth={this.state.windowWidth}
        ></Modifiers>
        <AgeRestriction
          windowWidth={this.state.windowWidth}
          showAgeRestrictionModal={this.state.showAgeRestrictionModal}
          closeAgeRestrictionPopup={this.closeAgeRestrictionPopup}
          closeBtnAgeRestrictionPopup={this.closeBtnAgeRestrictionPopup}
        ></AgeRestriction>
        {showFooter ? (
          <PurchaseFooter
            fnbDataForTicketSummary={fnbDataForTicketSummary}
            ticketsDataForTicketSummary={ticketsDataForTicketSummary}
            incDecItem={this.incDecItem}
            updateOrderWithFnbData={this.updateOrderWithFnbData}
            nextBtnText={this.state.orderType === "ticketing" ? (fnbDataForTicketSummary.length == 0 ? "SKIP" : "CONTINUE") : "CONTINUE"}
            nextBtnClick={this.updateOrderWithFnbData}
            nextBtnDisable={this.checkNextBtnDisable()}
            backBtnClick={this.backBtnClick}
          />
        ) : (
          <></>
        )}
        <Offcanvas show={this.state.itemsAddedToCart.length == 0 ? false : true} backdrop={false} placement={"bottom"} className="addedToCartDrawer">
          <Offcanvas.Body id="directItemsAddedToCartOffcanvasBody">
            <BsCartCheckFill className="icon" />
            {this.populateDirectItemsAddedToCart()}
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}

export default FNBComponent;
