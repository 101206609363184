'use client'

import React from 'react';
import { Tooltip } from "react-tooltip";
import "./index.scss";

const EmptySeatTooltip = (props) => {
    return (
        <Tooltip openOnClick="true" id={props.id} className="empty-seat-tooltip" isOpen={props.isOpen} >
            <div className="content">
                <h5>Select empty seat to proceed</h5>
                <p>You cannot leave a single seat open between selected seats </p>
            </div>
        </Tooltip>
    )
}

export default EmptySeatTooltip