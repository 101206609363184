import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { AiOutlineClose } from "react-icons/ai";
import "./index.scss";
import Content from "./content";

export default class Modifiers extends Component {
  content = () => {
    const { populateItemImage, selectedItem, populateModifierItem, incDecItem, calculateItemTotal, checkIfBtnIsDisabled, proceedBtnClick, closeModifierModal } = this.props;
    return (
      <>
        <span className="modal-close-button">
          <span>
            <AiOutlineClose
              className="modifierWindowCloseButton"
              onClick={() => {
                closeModifierModal();
              }}
            />
          </span>
        </span>
        <Content
          populateItemImage={populateItemImage}
          selectedItem={selectedItem}
          populateModifierItem={populateModifierItem}
          incDecItem={incDecItem}
          calculateItemTotal={calculateItemTotal}
          checkIfBtnIsDisabled={checkIfBtnIsDisabled}
          proceedBtnClick={proceedBtnClick}
          closeModifierModal={closeModifierModal}
        />
      </>
    );
  };
  render() {
    const { showModifierModal, fnbActiveItemIndex, closeModifierModal } = this.props;
    return this.props.windowWidth < 768 ? (
      <Offcanvas show={showModifierModal} onHide={closeModifierModal} placement={"bottom"} className="modifiers-offcanvas">
        <Offcanvas.Body>{this.content()}</Offcanvas.Body>
      </Offcanvas>
    ) : (
      <Modal show={showModifierModal} dialogClassName="modal-60w 123 modifierPopup" centered>
        {showModifierModal && fnbActiveItemIndex != null ? <Modal.Body>{this.content()}</Modal.Body> : <></>}
      </Modal>
    );
  }
}
