import React, { Component } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import MovieInfo from "../../components/MovieInfo/MovieInfo";
import * as API from "../../configuration/apiconfig";
import "./index.scss";
import Loader from "../loader";
import Header from "../../components/Header";
import { formatFilmShowDate, formatShowTime } from "../../utils/DateTimeFormat";
import BookingSummary from "../../components/BookingSummary";
import PurchaseFooter from "../../components/Footer";
import FNBComponent from "../../components/FnB";
import { showAlert } from "../../components/showAlert";
import infoIcon from "../../assets/images/info-icon.svg";
import { orderExpiredRedirect } from "../../utils/AlertHandling";
import axios from "axios";
import { craeteFnBPayloadForOrdersEndpoint } from "../../utils/PopulateFnbObject.js";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: "",
      orderDetails: [],
      movieInfoDetails: {},
      ticketsDataForTicketSummary: [],
      fnbDataForTicketSummary: [],
      isLoading: true,
      orderType: localStorage.getItem("orderType"),
      fnbData: [],
      fnbSuggetiveData: [],
      fnbDataToBePopulated: [],
    };
  }

  componentDidMount() {
    window.winvar = this;
    this.getOrderData();
  }

  AbortTransactionResponse = (response) => {
    // This is dot net function
    console.log(response, 'Swipe card abort response--order review page');
  }

  GiftCardSwipeResponse = (response) => {
    // This is dot net function
    console.log(response, 'GiftCardSwipeResponse--order review page');
  }

  getOrderData = () => {
    const searchParam = new URLSearchParams(window.location.search);
    const orderId = searchParam.get("orderid");
    API.callEndpoint("GET", "Bearer", `/order/v2/orders?id=${orderId}`)
      .then((response) => {
        this.setState(
          {
            orderId: orderId,
            orderDetails: response.data,
          },
          () => {
            if (this.state.orderType === "ticketing") {
              this.setMovieInfoDetails();
              this.setTicketsDataForTicketSummary();
            }
            this.setFnbDataForTicketSummary();

            if (this.state.orderType === "ticketing" && process.env.REACT_APP_LAST_CHANCE_SUGGESTIVE_SELLING == "true") {
              this.suggestiveSellingScriptOnload();
            } else {
              this.setState({
                isLoading: false,
              });
            }
          }
        );
      })
      .catch((err) => {
        var errMsg = err.error.toLowerCase();
        showAlert({
          html: `<p>${err.error}</p>`,
          iconHtml: `<img src=${infoIcon}>`,
          showCancelButton: false,
          cancelButtonText: "CANCEL",
          showConfirmButton: true,
          confirmButtonText: "OK",
          allowOutsideClick: false,
          allowEscapeKey: false,
          iconClasses: "borderless",
          popupClasses: "order-error-popup",
          onConfirm: () => {
            var getMovieName = localStorage.getItem("selectedmoviename");
            var redirectURL = `/showtime?title=${getMovieName}`;
            // // // // // orderExpiredRedirect(errMsg, '/login');
            orderExpiredRedirect(errMsg, redirectURL);
          },
          onCancel: () => {},
        });
      });
  };

  getFnbData = () => {
    API.callEndpoint("GET", "Bearer", `/cms/v2/fnbtabs?cinemaid=${this.state.orderDetails[0].cinema.id}`)
      .then((res) => {
        this.setState(
          {
            fnbData: res.data,
            fnbDataToBePopulated: [],
          },
          () => {
            this.getFnbSuggestiveItems();
          }
        );
      })
      .catch((err) => {
        showAlert({
          html: `<p>Sorry<span>${err.error}</span></p>`,
          iconHtml: `<img src=${infoIcon}>`,
          showCancelButton: false,
          cancelButtonText: "CANCEL",
          showConfirmButton: true,
          confirmButtonText: "Try again",
          allowOutsideClick: false,
          allowEscapeKey: false,
          iconClasses: "borderless",
          popupClasses: "common-error-alert",
          onConfirm: () => {},
          onCancel: () => {},
        });
      });
  };

  compare(a, b) {
    if (a.sort < b.sort) {
      return -1;
    }
    if (a.sort > b.sort) {
      return 1;
    }
    return 0;
  }

  getFnbSuggestiveItems = () => {
    API.callEndpoint("GET", "Bearer", `/cms/v2/suggestions/fnbs?sessionid=${this.state.orderDetails[0].session.id}`).then((response) => {
      this.setState(
        {
          fnbSuggetiveData: response.data,
        },
        () => {
          window.init(this.state.fnbSuggetiveData, this.state.fnbData);
          var self = this;
          setTimeout(() => {
            let cartData = JSON.parse(localStorage.getItem("cartData"));
            let tempCartData = cartData.cart.map((eachItem) => {
              return { ...eachItem, quantity: eachItem.selectedQty };
            });
            var responseData = window.getSuggestiveSell(tempCartData, this.state.fnbSuggetiveData, "PRE - CHECKOUT", this.state.fnbData, "");
            if (responseData.suggestion.length != 0) {
              var suggestiveSellItemIds = [],
                allSuggestiveSellItems = [];
              responseData.suggestion[0].items.map((suggestiveSellitem) => {
                suggestiveSellItemIds.push(suggestiveSellitem.fnbId);
              });

              this.state.fnbData.map((eachCategory) => {
                var suggestiveSellItems = [];
                eachCategory.fnbtabs_items.map((eachItem) => {
                  suggestiveSellItemIds.map((suggestiveSellItemId, suggestiveSellItemIdIndex) => {
                    if (suggestiveSellItemId == String(eachItem.fnbid)) {
                      suggestiveSellItems.push({ ...eachItem, sort: suggestiveSellItemIdIndex + 1 });
                    }
                  });
                });
                let tempEachCategory = { ...eachCategory, fnbtabs_items: [...suggestiveSellItems] };
                if (suggestiveSellItems.length != 0) {
                  allSuggestiveSellItems.push(tempEachCategory);
                }
              });
              this.setState(
                {
                  fnbDataToBePopulated: allSuggestiveSellItems,
                },
                () => {
                  this.setState({
                    isLoading: false,
                  });
                }
              );
            } else {
              this.setState({
                isLoading: false,
              });
            }
          }, 500);
        }
      );
    });
  };

  setMovieInfoDetails = () => {
    var orderDetails = this.state.orderDetails[0];
    var images = orderDetails.film.films_images;
    var experiences = orderDetails.session.sessions_experiences.map((item) => item.name).join(", ");
    var genres = [];
    orderDetails.film.films_genres.map((eachGenre) => {
      genres.push(eachGenre.description);
    });

    var rating = orderDetails.film.rating;
    var runtime = orderDetails.film.runtime;
    var screenName = orderDetails.session.cinemas_screens[0].name;
    var title = orderDetails.film.films_attributes[0].title;
    var subtitle = orderDetails.film.films_attributes[0].subtitle;
    var timing = formatFilmShowDate(orderDetails.session.showdate);
    var showTime = formatShowTime(orderDetails.session.showtime);
    this.setState({
      movieInfoDetails: {
        images,
        experiences,
        title,
        genres,
        rating,
        runtime,
        screenName,
        subtitle,
        timing,
        showTime,
      },
    });
  };

  setTicketsDataForTicketSummary = () => {
    let tempOrderData = [];
    this.state.orderDetails[0].order_grouping.map((eachTicket) => {
      if (eachTicket.type.toLowerCase() == "ticket") {
        let eachTaxValue = eachTicket.taxValue / eachTicket.quantity;
        let tempTicketObject = {
          description: eachTicket.description,
          unitprice: eachTicket.unitprice - eachTaxValue,
          qty: eachTicket.quantity,
        };
        tempOrderData.push(tempTicketObject);
      }
    });
    this.setState({
      ticketsDataForTicketSummary: tempOrderData,
    });
  };

  setFnbDataForTicketSummary = () => {
    var fnbDataForTicketSummary = [];
    this.state.orderDetails[0].order_grouping.map((eachItem) => {
      if (eachItem.type.toLowerCase() == "fnb") {
        var tempItemObject = {
          description: eachItem.description,
          qty: eachItem.quantity,
          unitprice: eachItem.unitprice - eachItem.taxValue / eachItem.quantity,
          modifiers: [],
          smartModifiers: [],
        };

        eachItem.additionalInfo.modifiers.map((eachModifier) => {
          var tempModifierObject = {
            description: eachModifier.description,
            qty: eachModifier.quantity,
            unitprice: eachModifier.pricebeforetax / eachModifier.quantity,
          };
          tempItemObject.modifiers.push(tempModifierObject);
          tempItemObject.unitprice = tempItemObject.unitprice - eachModifier.pricebeforetax;
        });
        fnbDataForTicketSummary.push(tempItemObject);
      }
    });
    this.setState({
      fnbDataForTicketSummary,
    });
  };

  nextBtnClick = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        window.location.href = `/payment?id=${this.state.orderId}`;
      }
    );
  };

  updateOrder = (cart) => {
    this.setState({ isLoading: true }, () => {
      const orderDetails = JSON.parse(localStorage.getItem("orderId"));
      let orderId = orderDetails?.id;
      const expiry = orderId ? orderDetails?.expiry : new Date().getTime() + 720 * 1000;
      let firstName = API.getValueFromCookie("firstname");
      let lastName = API.getValueFromCookie("lastname");
      let fullName = (firstName ? firstName : "") + " " + (lastName ? lastName : "");
      let orderObject = new Object();
      orderObject.sessionid = this.state.orderDetails[0].session.id;
      orderObject.fullname = fullName ? fullName : "";
      orderObject.firstname = firstName ? firstName : "";
      orderObject.lastname = lastName ? lastName : "";
      orderObject.email = "";
      orderObject.phonenumber = "";
      orderObject.tickettypes = this.state.orderDetails[0].orderinfo.tickets;
      orderObject.id = this.state.orderId;
      orderObject.fnb = [...craeteFnBPayloadForOrdersEndpoint(cart)];
      orderObject.seats = this.state.orderDetails[0].orderinfo.seats;

      API.callEndpoint("PUT", "Bearer", "/order/v2/orders", orderObject)
        .then((response) => {
          const cartData = {
            cart: [...cart],
            expiry: expiry,
          };
          localStorage.setItem("cartData", JSON.stringify(cartData));
          let orderIdObject = new Object();
          orderIdObject.id = response.data[0].id;
          orderIdObject.expiry = expiry;
          localStorage.setItem("orderId", JSON.stringify(orderIdObject));
          this.getOrderData();
        })
        .catch((err) => {
          var self = this;
          var errMsg = err.error.toLowerCase();
          showAlert({
            html: `<p>${err.error}</p>`,
            iconHtml: `<img src=${infoIcon}>`,
            showCancelButton: false,
            cancelButtonText: "CANCEL",
            showConfirmButton: true,
            confirmButtonText: errMsg.includes("expire") || errMsg.includes("no order found") ? "OK" : "Try again",
            allowOutsideClick: false,
            allowEscapeKey: false,
            iconClasses: "borderless",
            popupClasses: "common-error-alert",
            onConfirm: () => {
              var getMovieName = localStorage.getItem("selectedmoviename");
              var redirectURL = `/showtime?title=${getMovieName}`;
              if (this.state.orderType === "ticketing") {
                orderExpiredRedirect(errMsg, redirectURL);
              }
            },
            onCancel: () => {},
          });
          self.setState({
            isLoading: false,
          });
        });
    });
  };

  backBtnClick = () => {
    var getCinemaIdFromLocalstorage = localStorage.getItem("cinemaid");
    var getSessionIdFromLocalstorage = localStorage.getItem("sesstionid");
    var getOrderIdFromLocalstorage = JSON.parse(localStorage.getItem("orderId"));
    var getBookingIdFromLocalstorage = localStorage.getItem("bookingid");
    window.location.href = `/fnb?cinemaid=${getCinemaIdFromLocalstorage}&sessionid=${getSessionIdFromLocalstorage}&orderid=${getOrderIdFromLocalstorage.id}${
      getBookingIdFromLocalstorage ? `&bookingid=${getBookingIdFromLocalstorage}` : ""
    }`;
  };

  suggestiveSellingScriptOnload = () => {
    axios
      .get("https://cors-anywhere-lve1.onrender.com/" + process.env.REACT_APP_SUGGESTIVE_SELLING_SCRIPT)
      .then((response) => {
        const s = document.createElement("script");
        s.type = "text/javascript";
        s.innerHTML = response.data;
        document.getElementsByTagName("head")[0].appendChild(s);
      })
      .then((response) => {
        this.getFnbData();
      });
  };

  render() {
    var { ticketsDataForTicketSummary, fnbDataForTicketSummary } = this.state;

    return (
      <>
        <Loader loader={this.state.isLoading} />
        <Header logo={true} homeIcon={false} timer={true} />

        <section className="orderReview-container">
          <div className="reviewOrderDiv">
            <p>Review your Order!</p>
          </div>
          {this.state.orderDetails.length > 0 ? (
            <>
              {this.state.orderType === "ticketing" && (
                <MovieInfo filmsData={this.state.movieInfoDetails} selectedSeats={this.state.orderDetails[0].orders_items} isSeatLayoutPage={true} hideMovieBg={true}></MovieInfo>
              )}

              {process.env.REACT_APP_LAST_CHANCE_SUGGESTIVE_SELLING != "false" && this.state.fnbDataToBePopulated.length != 0 ? (
                <div className="suggestiveSelling">
                  <p>Forgetting Something?</p>
                  <FNBComponent
                    showHeader={false}
                    showFooter={false}
                    showCategoriesSelection={false}
                    updateOrder={this.updateOrder}
                    horizontal={true}
                    fnbData={this.state.fnbDataToBePopulated}
                  ></FNBComponent>
                </div>
              ) : (
                <></>
              )}

              <div className="bookingSummary">
                <p className="bookingSummaryHeading">Booking Summary</p>
                <BookingSummary
                  ticketsDataForTicketSummary={this.state.ticketsDataForTicketSummary}
                  fnbDataForTicketSummary={this.state.fnbDataForTicketSummary}
                  incDecItem={false}
                  nextBtnClick={this.nextBtnClick}
                  nextBtnText={""}
                  bookingFee={this.state.orderDetails[0].bookingfeebeforetax}
                  tax={this.state.orderDetails[0].taxValue}
                ></BookingSummary>
              </div>
            </>
          ) : (
            <></>
          )}

          <PurchaseFooter
            fnbDataForTicketSummary={[]}
            ticketsDataForTicketSummary={[]}
            removeItemFromCart={false}
            incDecItem={false}
            updateOrderWithFnbData={false}
            nextBtnClick={this.nextBtnClick}
            nextBtnText="CONTINUE"
            nextBtnDisable={false}
            backBtnClick={this.backBtnClick}
          ></PurchaseFooter>
        </section>
      </>
    );
  }
}

export default index;
