import React, { Component } from 'react';
import CustomBanner from '../../components/CustomBanner';
import Slider from 'react-slick';
import './index.scss';
import FnbCta from '../../assets/images/fnb-cta.svg';
import * as API from '../../configuration/apiconfig';
import { formatFilmShowDate, formatShowTime } from '../../utils/DateTimeFormat';


const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: "true",
    centerPadding: "20px"
}

const RecentBookingCard = ({ booking, goToFnbPage }) => {
    const getSeats = (orderGrouping) => {
        let seatString = ""
        let seats = orderGrouping.filter((order) => order.type.toUpperCase() === "TICKET")
        seatString = seats.length + " " + "Seat(s) - " + seats.map((seat) => seat.seats).join(", ")
        return seatString
    }

    const getGenres = (genres, index) => {
        let genreString = ""
        genres.map((genre) => {
            return genre.description;
        })
        genreString = genres.map((genre) => genre.description).join("/");
        console.log(genreString, "Genre String");
        return genreString
    }

    let bookingDetails = {
        filmName: booking.film.films_attributes[0]?.title,
        filmImage: booking.film.films_images.filter((img) => img.code === "x250y350")[0]?.imageurl,
        filmStatus: "Starts in 2hrs 5min",
        filmRuntime: booking.film.runtime,
        bookingId: booking.bookingid,
        rating: booking.film.rating,
        genre: getGenres(booking.film.films_genres),
        language: booking.film.films_attributes[0]?.language,
        showDate: formatFilmShowDate(booking.session.showdate),
        experience: booking.session.sessions_experiences[0].name,
        screen: booking.session.cinemas_screens[0].name,
        showTime: formatShowTime(booking.session.showtime),
        seats: getSeats(booking.order_grouping)
    }

    return (
        <div className="recent-booking-card">
            <img src={bookingDetails.filmImage} alt="bad boys" className="booking-image" />
            <div className="booking-details">
                <div className="film-status">
                    Starts in <span>2hrs 5min</span>
                </div>
                <p className="booking-id">
                    Booking ID: <span>{bookingDetails.bookingId}</span>
                </p>
                <p className="film-name">{bookingDetails.filmName}</p>
                <p className="film-details">
                    {
                        bookingDetails.rating && <span>{bookingDetails.rating}</span>
                    }
                    {
                        bookingDetails.genre?.length > 0 && <span>{bookingDetails.genre}</span>
                    }
                    {
                        bookingDetails.language && <span>Language - {bookingDetails.language}</span>
                    }
                    {
                        bookingDetails.filmRuntime && <span>{bookingDetails.filmRuntime} mins</span>
                    }
                    {
                        bookingDetails.showDate && <span>{bookingDetails.showDate}</span>
                    }
                    {
                        bookingDetails.experience && <span>{bookingDetails.experience}</span>
                    }
                    {
                        bookingDetails.screen && <span>{bookingDetails.screen}</span>
                    }
                    {
                        bookingDetails.showTime && <span>{bookingDetails.showTime}</span>
                    }
                    {
                        bookingDetails.seats && <span>{bookingDetails.seats}</span>
                    }
                </p>
                <button
                    className="add-food-btn btn btnPrimary"
                    onClick={goToFnbPage}
                >Add food
                </button>
            </div>
        </div>
    )
}
class RecentBookings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recentBookings: []
        }
    }

    componentDidMount() {
        this.getOrderData();
    }

    goToFnbPage = (booking) => {
        let cinemaId = booking?.cinema?.id || localStorage.getItem("cinemaid");
        let sessionId = booking?.session?.id;
        let orderId = booking?.id
        localStorage.setItem("sesstionid", sessionId);
        localStorage.setItem("bookingid", booking.bookingid);
        if (sessionId && orderId) {
            window.location.href = `fnb?cinemaid=${cinemaId}&sessionid=${sessionId}&orderid=${orderId}&bookingid=${booking.bookingid}`;
        } else {
            window.location.href = `fnb?cinemaid=${cinemaId}`;
        }
    }

    getOrderData = () => {
        API.callEndpoint("GET", "Bearer", "/user/v2/userinfo/bookinghistory")
            .then((response) => {
                // console.log(response.data.data[0].upcoming, "Get order data");
                this.setState({ recentBookings: response.data.data[0].upcoming });
            });
    }

    render() {
        let { recentBookings } = this.state;
        return (
            <div className='recent-bookings-page'>
                <CustomBanner title={"Welcome Movie Buff!"} subtitle={"Let's get some Food & Drinks!"} onBackBtnClick={() => console.log("testing")} renderAsHtml={false} />
                <h4 className="title">
                    Would you like to ‘Add Food’ to your <br />
                    existing ticket booking?
                </h4>
                <div className="recent-bookings-slider">
                    <h6>Upcoming Bookings</h6>
                    <Slider {...settings}>
                        {
                            recentBookings && recentBookings.map((booking, index) => {
                                return (
                                    <RecentBookingCard booking={booking} goToFnbPage={() => this.goToFnbPage(booking)} />
                                )
                            })
                        }
                    </Slider>
                </div>

                <div className="buy-food-cta">
                    <h4 className="title">Skip the Queue!</h4>
                    <div className="content">
                        <img src={FnbCta} alt="" className="cta-image" />
                        <div>
                            <p className="description">
                                You can skip the queue at the food counter, when you pre-order your food and drinks.
                            </p>
                            <button className="btn btnPrimary" onClick={this.goToFnbPage}>Buy Food</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RecentBookings;
