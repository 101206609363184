import Swal from 'sweetalert2'

export const showAlert = ({ title, text, html, showCancelButton, cancelButtonText, showConfirmButton, confirmButtonText, onConfirm, onCancel, popupClasses, confirmBtnClasses, cancelBtnClasses, iconClasses, icon, iconColor, iconHtml, allowOutsideClick, allowEscapeKey, reverseButtons }) => {
    Swal.fire({
        title: title,
        html: html,
        text: text,
        showCancelButton: showCancelButton,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        showConfirmButton: showConfirmButton,
        allowOutsideClick: allowOutsideClick,
        icon: icon,
        iconColor: iconColor,
        iconHtml: iconHtml,
        showCloseButton: false,
        allowEscapeKey: allowEscapeKey,
        reverseButtons: reverseButtons ? reverseButtons : false,
        customClass: {
            popup: `common-alert-popup ${popupClasses}`,
            confirmButton: `btn btnPrimary alert-btn ${confirmBtnClasses}`,
            cancelButton: `btn btnSecondary alert-btn ${cancelBtnClasses}`,
            icon: `${iconClasses}`
        }
    }).then((result) => {
        if (result.isConfirmed) {
            onConfirm()
        } else if (result.isDenied) {
            onCancel()
        }
    })
}
