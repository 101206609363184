import React from 'react'
import './index.scss'
import lanmarkImg from "../../assets/images/landmark-extra-icon.svg"
import barCode from "../../assets/images/bar-code-icon.svg"
import { Link } from "react-router-dom";

const CustomBanner = ({ titleLogo, title, subtitle, onBackBtnClick, renderAsHtml, rewardsRedeemText }) => {
    return (
        <section className="custom-banner">
            <div className="header">
                {
                    onBackBtnClick ?
                        <button onClick={onBackBtnClick}>BACK</button> :
                        <Link to={-1}>BACK</Link>
                }
            </div>
            <div className="content">
                {titleLogo ? <img src={titleLogo} className='img-fluid' /> : ""}
                {title ? <h1>{title}</h1> : ""}
                {/* <span>{subtitle}</span> */}
                {renderAsHtml ? <span dangerouslySetInnerHTML={{__html: subtitle}}></span> : <span>{subtitle}</span> }
                {rewardsRedeemText ? <p className='rewardsRedeemText'>{rewardsRedeemText}</p> : ''}
                
            </div>
        </section>
    )
}

export default CustomBanner