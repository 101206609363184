import React, { Component } from "react";
import "./index.scss";
import { ReactComponent as DownArrow } from "../../assets/images/triangle-down-arrow.svg";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { FaPlus, FaMinus } from "react-icons/fa";
import Offcanvas from "react-bootstrap/Offcanvas";
import BookingSummary from "../BookingSummary";

class PurchaseFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      ticketSummarySectionHeight: 0,
      openTicketSummary: false,
      maxHeightForTicketSummary: "auto",
    };
    this.ticketSummarySectionRef = React.createRef();
    this.purchaseFooterRef = React.createRef();
  }

  calculateTotal = () => {
    var totalTicketPricing = 0,
      totalFnbPricing = 0;
    this.props.ticketsDataForTicketSummary.length > 0 &&
      this.props.ticketsDataForTicketSummary.map((eachTicket) => {
        totalTicketPricing += eachTicket.qty * eachTicket.unitprice.toFixed(2);
      });

    this.props.fnbDataForTicketSummary.length > 0 &&
      this.props.fnbDataForTicketSummary.map((eachFnb) => {
        var unitprice = eachFnb.unitprice;
        eachFnb.modifiers.length > 0 &&
          eachFnb.modifiers.map((modifier) => {
            var totalModifiePrice = 0;
            totalModifiePrice += modifier.unitprice * modifier.qty;
            unitprice += totalModifiePrice;
          });
        totalTicketPricing += eachFnb.qty * unitprice.toFixed(2);
      });
    return (totalTicketPricing + totalFnbPricing).toFixed(2);
  };

  populateTicketsForTicketSummary = () => {
    var returnTicketsDiv = [];
    this.props.ticketsDataForTicketSummary.length > 0 &&
      this.props.ticketsDataForTicketSummary.map((eachTicket) => {
        returnTicketsDiv.push(
          <div className="eachTicketDiv">
            {/* <p className="eachTicketParagraph">
              <div className="eachTicketDescQtyPrice">
                <span className="name">{eachTicket.description}</span>
                <span className="eachQty">
                  {eachTicket.qty} x {eachTicket.unitprice.toFixed(2)}
                </span>
              </div>
            </p> */}
            <span className="name">{eachTicket.description}</span>
            <span className="eachQty">
              {eachTicket.qty} x {eachTicket.unitprice.toFixed(2)}
            </span>
            <p className="price">{parseFloat(eachTicket.unitprice * eachTicket.qty).toFixed(2)}</p>
          </div>
        );
      });

    return returnTicketsDiv;
  };

  populateFnBForTicketSummary = () => {
    var returnFnBDiv = [];
    this.props.fnbDataForTicketSummary.length > 0 &&
      this.props.fnbDataForTicketSummary.map((eachFnb, eachFnbIndex) => {
        var unitprice = eachFnb.unitprice;
        var totalModifiePrice = 0;
        var modifers = "";
        var smartModifiers = "";
        eachFnb.modifiers.length > 0 &&
          eachFnb.modifiers.map((modifier) => {
            totalModifiePrice += modifier.unitprice * modifier.qty;
            modifers += modifier.description + " x " + modifier.qty + ", ";
          });
        unitprice += totalModifiePrice;
        eachFnb.smartModifiers.length > 0 &&
          eachFnb.smartModifiers.map((smartModifer) => {
            smartModifiers += smartModifer.description + ", ";
          });

        returnFnBDiv.push(
          <div className="eachTicketDiv">
            {/* <p className="eachTicketParagraph">
              <div className="closeIcon" onClick={() => this.props.removeItemFromCart(eachFnbIndex)}>
                <IoMdCloseCircle />
              </div>
              <div className="eachTicketDescQtyPrice">
                <span className="name">{eachFnb.description}</span>
                {modifers.length == 0 && smartModifiers.length == 0 ? (
                  <></>
                ) : (
                  <span className="modifiers">
                    {`${modifers.length != 0 ? modifers.slice(0, -2) : ""}`}
                    {`${modifers.length != 0 && smartModifiers.length != 0 ? ", " : ""}`} {`${smartModifiers.length != 0 ? smartModifiers.slice(0, -2) : ""}`}
                  </span>
                )}
              </div>
            </p> */}
            <div className="name">
              <span>{eachFnb.description}</span>
              {modifers.length == 0 && smartModifiers.length == 0 ? (
                <></>
              ) : (
                <span className="modifiers">
                  {`${modifers.length != 0 ? modifers.slice(0, -2) : ""}`}
                  {`${modifers.length != 0 && smartModifiers.length != 0 ? ", " : ""}`} {`${smartModifiers.length != 0 ? smartModifiers.slice(0, -2) : ""}`}
                </span>
              )}
            </div>

            <span className="eachQty">
              <div className="incDecDivForItem">
                <button type="button" class="btn btn-outline-secondary decButton" onClick={() => this.props.incDecItem("subtract", eachFnbIndex)}>
                  <FaMinus></FaMinus>
                </button>
                <button type="button" class="btn btn-outline-secondary itemQty">
                  {eachFnb.qty}
                </button>
                <button type="button" class="btn btn-outline-secondary incButton" onClick={() => this.props.incDecItem("add", eachFnbIndex)}>
                  <FaPlus></FaPlus>
                </button>
              </div>
              {/* x {unitprice.toFixed(2)} */}
            </span>
            <p>{parseFloat(unitprice * eachFnb.qty).toFixed(2)}</p>
          </div>
        );
      });
    return returnFnBDiv;
  };

  nextBtnClick = () => {
    this.props.nextBtnClick();
    this.setState({
      openTicketSummary: false,
    });
  };

  componentDidUpdate = () => {
    const { fnbDataForTicketSummary, ticketsDataForTicketSummary } = this.props;
    if (this.state.openTicketSummary && fnbDataForTicketSummary.length == 0 && ticketsDataForTicketSummary.length == 0) {
      this.setState({
        openTicketSummary: false,
      });
    }
  };
  render() {
    const { fnbDataForTicketSummary, ticketsDataForTicketSummary, incDecItem, nextBtnText, nextBtnDisable, backBtnClick } = this.props;
    return (
      <React.Fragment>
        <section className="container-fluid sticky-bottom purchaseFooterDiv">
          <div className="purchase-footer" id="purchase-footer" ref={this.purchaseFooterRef}>
            <div className="back-btn">
              <KeyboardArrowLeft />
              <a href="javascript:;" title="BACK" onClick={() => backBtnClick()}>
                BACK
              </a>
            </div>
            <section className="purchase-summary">
              <div className="ticket-summary" onClick={() => this.setState({ openTicketSummary: !this.state.openTicketSummary })}>
                <div className="purchase-grand-total">
                  {fnbDataForTicketSummary.length > 0 || ticketsDataForTicketSummary.length > 0 ? (
                    <p>
                      Total
                      <span>${this.calculateTotal()}</span>
                      <DownArrow className={this.state.openTicketSummary ? "open" : "close"} />
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </section>
            <section className={`purchase-next ${nextBtnDisable ? "disabled" : ""}`}>
              <button onClick={() => this.nextBtnClick()}>{nextBtnText}</button>
            </section>
          </div>
        </section>
        <div className="summary-bg">
          <div className={this.state.openTicketSummary ? "open" : "close"}>&nbsp;</div>
        </div>
        <Offcanvas
          show={this.state.openTicketSummary}
          placement={"bottom"}
          onHide={() =>
            this.setState({
              openTicketSummary: false,
            })
          }
          className="purchaseFooter"
        >
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <div id="ticketSummarySection" className="bookingSummary" ref={this.ticketSummarySectionRef} style={{ maxHeight: this.state.maxHeightForTicketSummary }}>
              <p className="bookingSummaryHeading">Booking Summary</p>
              <BookingSummary
                ticketsDataForTicketSummary={ticketsDataForTicketSummary}
                fnbDataForTicketSummary={fnbDataForTicketSummary}
                incDecItem={incDecItem}
                nextBtnClick={this.nextBtnClick}
                nextBtnText={nextBtnText}
                bookingFee={false}
                tax={false}
              ></BookingSummary>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </React.Fragment>
    );
  }
}

export default PurchaseFooter;
