import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import IdleStayModal from '../components/IdleStayModal';

var getLoginPage = window.location.pathname === '/login';
export default function IdleTimer({idleTimerSet}) {
  const [state, setState] = useState('Active')
  const [count, setCount] = useState(0)
  const [remaining, setRemaining] = useState(null)
  const [timerRun, setTimerRun] = useState(false)
  const [idleStayModalSH, setIdleStayModalSH] = useState(false);
  
  const onIdle = () => {
    setState('Idle')
  }

  const onActive = () => {
    setState('Active')
  }

  const onAction = () => {
    setCount(count + 1)
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    // timeout: 30000,
    // timeout: process.env.REACT_APP_IDLETIMERCOUNTER,
    timeout: idleTimerSet,
    throttle: 500
  })

 
  useEffect(() => {
    // window.winvar = this;
    if(!timerRun){
      var IdleInterval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }, 500)
      // console.log('Idle timer:', remaining);
      var orderDetails = JSON.parse(localStorage.getItem("orderId"));
      if(remaining == 0){
        if(getLoginPage){
          console.log('Login---callDisableBarcodeScan function call');
          window.winvar = this;
          window.callbackObj.callDisableBarcodeScan();
        }
        window.location.href = `/?cinemaid=${localStorage.getItem("cinemaid")}`;
      }else if(remaining && remaining <= 10 && orderDetails && orderDetails.id != null){
        if(window.location.pathname != '/confirmation'){
          setIdleStayModalSH(true);
          setRemaining(null);
          setTimerRun(true);
          clearInterval(IdleInterval);
        }
      }
    }
  })

  const stayBtnClick = () => {
    setIdleStayModalSH(false);
    setTimerRun(false);
  }

  return (
    <IdleStayModal 
      idleStayModalSH={idleStayModalSH} 
      stayBtnClick={stayBtnClick}
    />
  )

  // return (
  //   <>
  //     <h1>React Idle Timer</h1>
  //     <h2>useIdleTimer</h2>
  //     <br />
  //     <p>Current State: {state}</p>
  //     <p>Action Events: {count}</p>
  //     <p>{remaining} seconds remaining</p>
  //   </>
  // )
}
