import React, { Component } from "react";
import "./index.scss";
import CustomBanner from "../../components/CustomBanner";
import LoginComponent from "../../components/Login";
import invertedLogo from "../../assets/images/invertedLogo.webp";
class index extends Component {
  
  componentDidMount = () => {
    localStorage.getItem("ageRestrictionValidation") != null && localStorage.removeItem("ageRestrictionValidation");
  }

  loginBack = () => {
    localStorage.removeItem("selectedSeats");
    localStorage.removeItem("orderId");
    localStorage.removeItem("cartData");
    localStorage.removeItem("sessionExpiredRedirectUrl");
    document.cookie = "accessToken=;max-age=0";
    document.cookie = "refreshToken=;max-age=0";
    document.cookie = "firstname=;max-age=0";
    document.cookie = "lastname=;max-age=0";
    if (localStorage.getItem("orderType") === "ticketing") {
      var getMovieName = localStorage.getItem("selectedmoviename");
      window.location.href = `/showtime?title=${getMovieName}`;
    } else {
      let cinemaId = localStorage.getItem("cinemaid");
      window.location.href = `/?cinemaid=${cinemaId}`;
    }
  };

  render() {
    return (
      <>
        <section className="login-page">
          <section className="login-wrapper">
            <CustomBanner 
              titleLogo={invertedLogo}
              // title={"Sign in to see your Exclusives!"} 
              title={""} 
              subtitle={"<b class='pc2Text'>SIGN IN</b> for Offers, Concession Perks, <b>FREE</b> Birthday Popcorn and more!"} 
              onBackBtnClick={() => this.loginBack()} 
              renderAsHtml={true} 
              rewardsRedeemText="To Redeem Extras Points Please See a Cast Member at Concessions"
            />
            <section className="content">
              <h2>
                Are you a registered
                {/* <span>Landmark Extras Loyalty Member?</span> */}
                <span>Landmark EXTRAS Loyalty Member?</span>
              </h2>
              <LoginComponent 
                timerIncrement={this.props.timerIncrement}
                timerDecrement={this.props.timerDecrement}
              ></LoginComponent>
            </section>
          </section>
        </section>
      </>
    );
  }
}

export default index;
