export const filterPackImageBasedOnImageCode = (response, imageCode) => {
  var splashScreenImages = [];
  response.data[0].packs_items.map((item) => {
    var filteredSplashScreenImages = item.packs_items_images.filter((image) => {
      return image.code == imageCode;
    });
    splashScreenImages.push(filteredSplashScreenImages.length >> 0 && filteredSplashScreenImages[0].imageurl);
  });
  return splashScreenImages;
};

export const filterImageBasedOnImageCode = (response, imageCode) => {
  var filteredImagesArray = [];
  var filteredImages = response.filter((image) => {
    return image.code == imageCode;
  });
  filteredImages.map((image) => {
    filteredImagesArray.push(image.imageurl);
  });
  return filteredImagesArray;
};
