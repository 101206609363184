import React, {Component} from "react";
import "./content.scss";

import incrementIcon from "../Images/increment-icon-gray.svg";
import decrementIcon from "../Images/decrement-icon-gray.svg";

import Accordion from "react-bootstrap/Accordion";
import {PiBowlFoodFill} from "react-icons/pi";
import {RiCloseCircleFill} from "react-icons/ri";

export default class Content extends Component {
  render() {
    const {populateItemImage, selectedItem, populateModifierItem, incDecItem, calculateItemTotal, checkIfBtnIsDisabled, proceedBtnClick, closeModifierModal} = this.props;
    return (
      <React.Fragment>
        <div className="imageContainer">{populateItemImage()}</div>
        <div className="fnbItemDetailsContainer">
          <div className="mod-blk">
            <p className="fnbItemName">{selectedItem.itemName}</p>
            {selectedItem.itemDetails && <p className="fnbItemDescription">{selectedItem.itemDetails}</p>}

            {selectedItem.fnbs_alternateitems.length > 0 ? (
              <div className="modifierGroupDiv select-option-blk">
                <p className="modiferGroupName">Select an option</p>
                <div key={`inline-alternateItem-div`} className="select-option-items d-flex" style={{flexWrap: "wrap"}}>
                  {selectedItem.fnbs_alternateitems.map((alternateItem, alternateItemIndex) => {
                    return populateModifierItem("alternateItem", "", alternateItem, alternateItemIndex);
                  })}
                </div>
              </div>
            ) : (
              <></>
            )}

            {selectedItem.modifierGroups.length > 0 && (
              <Accordion className="modifierGroupsAccordionWrapper">
                {selectedItem.modifierGroups.map((modifierGroup, modifierGroupIndex) => {
                  return (
                    <Accordion.Item className="modifierGroupDiv required-optional-blk" eventKey={`modifierGroup_${modifierGroup.id}`}>
                      <Accordion.Header className="modiferGroupName">
                        {modifierGroup.tabName} {modifierGroup.minQty > 0 ? "(Required)" : "(Optional)"}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div key={`inline-${modifierGroup.tabid}`} className="required-optional-items d-flex" style={{flexWrap: "wrap"}}>
                          {modifierGroup.modifier_items.map((modifierItem, modifierItemIndex) => {
                            return populateModifierItem(modifierGroup, modifierGroupIndex, modifierItem, modifierItemIndex);
                          })}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            )}

            {selectedItem.smartModifiers.length == 0 ? (
              <></>
            ) : (
              <Accordion className="modifierGroupsAccordionWrapper smartModifiersWrap">
                <Accordion.Item className="modifierGroupDiv add-remove-blk" eventKey={`smartModifiers_0`}>
                  <Accordion.Header className="modiferGroupName">
                    Optional&nbsp;
                    <span>
                      (<PiBowlFoodFill className="addAsSideIcon" /> Add as side, <RiCloseCircleFill className="removeIcon" /> Remove)
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="rve-add-blk">
                      <h3 className="modiferGroupName">&nbsp;</h3>
                      <p className="modiferGroupName">Remove</p>
                      <p className="modiferGroupName">Add as Side</p>
                    </div>
                    <div key={`inline-smartMods-div`} className="add-remove-items d-flex" style={{flexWrap: "wrap"}}>
                      {selectedItem.smartModifiers.map((smartModifierItem, smartModifierItemIndex) => {
                        return populateModifierItem("", "", smartModifierItem, smartModifierItemIndex);
                      })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}
          </div>

          <div className={`modifierPopupFooter ${calculateItemTotal().itemQty == 0 ? "visibilityNone" : ""}`}>
            {process.env.REACT_APP_FNB_MODIFIER_QUANTITY_ENABLE == "true" && (
              <div className="incDecDivForItem">
                <div className="quty">
                  <p>Quantity</p>
                </div>
                <div className="inc-btn-blk">
                  <button type="button" class="btn btn-outline-secondary decButton" onClick={() => incDecItem("subtract", "")}>
                    <img src={decrementIcon} className="dec-img" alt="Decrement" />
                  </button>
                  <button type="button" class="btn btn-outline-secondary itemQty">
                    {selectedItem.selectedQty}
                  </button>
                  <button type="button" class="btn btn-outline-secondary incButton" onClick={() => incDecItem("add", "")}>
                    <img src={incrementIcon} className="inc-img" alt="Increment" />
                  </button>
                </div>
              </div>
            )}
            <div className="submitButtonPriceDiv">
              <button type="button" class={`btn btn-outline-secondary proceedButton ${checkIfBtnIsDisabled() ? "disabled" : ""}`} onClick={() => proceedBtnClick()}>
                Add to Cart <p className="totalItemPrice">${calculateItemTotal().price}</p>
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
