import React, { Component } from "react";
import CalenderIcon from "../../assets/images/Calendar.svg";
import ScreenIcon from "../../assets/images/Screen.svg";
import SeatIcon from "../../assets/images/seat-icons/Seat.svg";
import SuccessIcon from "../../assets/images/success.svg";
import FailureIcon from "../../assets/images/failure.svg";
import "./MovieInfo.scss";
import { calculateHoursAndMinutes } from "../../utils/DateTimeFormat";
import IconCalendar from "../../assets/images/icons/iconCalendar.svg";
import IconClock1 from "../../assets/images/icons/iconClock1.svg";
import IconScreen from "../../assets/images/icons/iconScreen.svg";

class MovieInfo extends Component {
  render() {
    const { filmsData, orderStatus, selectedSeats, hideMovieBg, isConfirmationPageBanner } = this.props;
    // console.log(selectedSeats, "filmsData");
    // if (!filmsData || Object.keys(filmsData).length === 0) {
    //   return null; // Render nothing if filmsData is not available
    // }

    const backgroundImg = filmsData.images?.find((x) => x.code === "x1080y580")?.imageurl;
    const posterImg = filmsData.images?.find((x) => x.code === "x250y350")?.imageurl;
    const isConfirmationPage = window.location.pathname === "/confirmation";
    const isShowtimePage = window.location.pathname === "/showtime";
    const isSeatLayoutPage = window.location.pathname === "/seatlayout" || window.location.pathname === "/order-review" || window.location.pathname === "/payment";
    const isSeatsDetails = window.location.pathname === "/order-review";
    const setScreenDetails = window.location.pathname === "/confirmation";
    
    const getSeatsQuantity = (selectedSeats) => {
      if (window.location.pathname === "/seatlayout") {
        return selectedSeats.length > 1 ? `${selectedSeats.length} Seats` : `${selectedSeats.length} Seat`;
      } else {
        selectedSeats = selectedSeats.filter((seat) => seat.type === "TICKET");
        let seatsQuantity = 0;
        selectedSeats.forEach((seat) => {
          seatsQuantity += seat.quantity;
        });
        seatsQuantity = seatsQuantity > 1 ? `${seatsQuantity} Seats` : `${seatsQuantity} Seat`;
        return seatsQuantity;
      }
    }

    return (
      // <section className={`banner-wrap ${hideMovieBg ? "noAfter" : ""} ${isConfirmationPageBanner ? "is-confirmation-page-banner" : ""}`} style={{ background: hideMovieBg ? "white" : `url(${backgroundImg}) no-repeat`, backgroundSize: "cover" }}>
      <section className={`banner-wrap ${hideMovieBg ? "noAfter" : ""} ${isConfirmationPageBanner ? "is-confirmation-page-banner" : ""}`} style={{ background: hideMovieBg ? "white" : "#38352d", backgroundSize: "cover" }}>
        <div className="movie-detail-wrap">
          {posterImg && (
            <div className="poster-wrap">
              <img src={posterImg} alt={filmsData.title} className="poster-img" />
            </div>
          )}
          
          {isConfirmationPage && isConfirmationPageBanner ? (
            orderStatus === "success" ? (
              <aside className="movie-status">
                <img src={SuccessIcon} alt="Success Icon" />
                <h1>
                  YOUR BOOKING WAS
                  <span className="successful">
                    SUCCESSFUL
                    <span className="exclamation">!</span>
                  </span>
                </h1>
              </aside>
            ) : (
              <aside className="movie-status">
                <img src={FailureIcon} alt="Failure Icon" />
                <h1>
                  OOPS!
                  <span>BOOKING FAILED</span>
                </h1>
              </aside>
            )
          ) : (
            <aside className="movie-detail">
              <h1 className="movie-title">{filmsData.title}</h1>
              <div className="movie-desc">
                <span className="ratingSpan">{filmsData.rating}</span>
                {filmsData.genres?.length > 0 && (
                  <span>
                    {filmsData.genres.map((actionData, index) => (
                      <React.Fragment key={index}>
                        {actionData}
                        {filmsData.genres.length - 1 !== index && "/"}
                      </React.Fragment>
                    ))}
                  </span>
                )}
                {filmsData.language && <span>Language - {filmsData.language}</span>}
                {filmsData.runtime && <span>{calculateHoursAndMinutes(filmsData.runtime)}</span>}
                {filmsData.subtitle && <span>Subtitle - {filmsData.subtitle}</span>}
              </div>

              {isShowtimePage ? (
                <div className="movie-info-banner">
                  {filmsData.cast && (
                    <p className="movie-cast pt-0">
                      <span className="font-bold">Starring</span>
                      {filmsData.cast}
                    </p>
                  )}
                  {filmsData.synopsis && (
                    <p className="movie-synopsis">
                      <span className="font-bold">Synopsis</span>
                      {filmsData.synopsis}
                    </p>
                  )}
                </div>
              ) : (isSeatLayoutPage || !isConfirmationPageBanner) && (
                <div
                  // // // // // className="movie-info row-row-cols-3"
                  className="movieSupportInfo"
                >
                  {
                    filmsData.timing &&
                    <span className="calender-blk">
                      <img src={IconCalendar} alt="" />
                      {filmsData.timing}
                    </span>
                  }
                  {/* <span className="xperience-blk">
                    <img src={ScreenIcon} alt="Screen" />
                    {filmsData.experiences}
                  </span> */}
                  {
                    filmsData.experiences &&
                    <span className="screen-blk">
                      <img src={IconScreen} alt="" />
                      {/* {filmsData.screenName} */}
                      {filmsData.experiences}
                    </span>
                  }
                  {
                    setScreenDetails && filmsData.screenName &&
                    <span className="screen-name">
                      <img src={IconScreen} alt="" />
                      {filmsData.screenName}
                    </span>
                  }
                  {
                    filmsData.showTime &&
                    <span className="movieShowtime">
                      <img src={IconClock1} alt="" />
                      {filmsData.showTime}
                    </span>
                  }
                  {selectedSeats.length > 0 && (
                    <span className="seat-blk">
                      <img src={SeatIcon} alt="Seat" />
                      {getSeatsQuantity(selectedSeats)}
                      {/* {selectedSeats.length} {selectedSeats.length > 1 ? "Seats" : "Seat"} */}
                      {/* {selectedSeats.map((seat, index) => (
                        <React.Fragment key={index}>
                          {seat.name}
                          {index < selectedSeats.length - 1 && ", "}
                        </React.Fragment>
                      ))} */}
                      {
                        isSeatsDetails &&
                        <span className="ps-0">&nbsp;-&nbsp;
                          {selectedSeats.map((item, index) => (
                            item.type.toLowerCase() === "ticket" && 
                            <>
                              {item.seats}
                              {index < selectedSeats.length - 1 ? ", " : ""}
                            </>
                          ))}
                        </span>
                      }
                    </span>
                  )}
                </div>
              )}
            </aside>
          )}
        </div>
      </section>
    );
  }
}

export default MovieInfo;
