import React, { Component } from "react";
import "./index.scss";
import CustomSlider from "../CustomSlider";

export default class FnbCategoriesHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fnbData: props.fnbData,
    };
  }

  scrollTo(el) {
    console.log(el);
    const elRight = el.offsetLeft + el.offsetWidth;
    const elLeft = el.offsetLeft;

    const elParentRight = el.parentNode.offsetLeft + el.parentNode.offsetWidth;
    const elParentLeft = el.parentNode.offsetLeft;

    // check if right side of the element is not in view
    if (elRight > elParentRight + el.parentNode.scrollLeft) {
      el.parentNode.scrollLeft = elRight - elParentRight + 10;
    }

    // check if left side of the element is not in view
    else if (elLeft < elParentLeft + el.parentNode.scrollLeft) {
      el.parentNode.scrollLeft = elLeft - elParentLeft - 10;
    }
  }
  render() {
    const { activeCategoryIndex, handleActiveCategory } = this.props;
    return (
      <div className="fnb-categories-header" id="fnb-categories-header">
        <div className="category-btn-list">
          <CustomSlider>
            {this.state.fnbData.map((category, index) => {
              return (
                <button
                  key={index}
                  className={`category-btn ${activeCategoryIndex === index ? "is-active" : ""}`}
                  onClick={(e) => {
                    handleActiveCategory(index);
                    this.scrollTo(e.target);
                  }}
                >
                  {category.tabName}
                </button>
              );
            })}
          </CustomSlider>
        </div>
      </div>
    );
  }
}
